@use 'sass:math';


/**
  * 1. Float above the visual radio and match its dimension, so that when users try to click it
  *    they actually click this input.
  */

.euiRadio {
  position: relative;

  // Set a top offset for the real input and faux input to align better with the text
  $topOffset: math.div(($euiSizeL - $euiRadioSize), 2) - 1px;

  .euiRadio__input {
    @include size($euiRadioSize);
    top: $topOffset;
    cursor: pointer;
    position: absolute; /* 1 */
    opacity: 0; /* 1 */
    z-index: 1; /* 1 */

    ~ .euiRadio__label {
      display: inline-block;
      padding-left: ($euiRadioSize * 1.5);
      line-height: $euiSizeL;
      font-size: $euiFontSizeS;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }

    + .euiRadio__circle {
      @include euiCustomControl($type: 'round', $size: $euiRadioSize);

      display: inline-block;
      position: absolute;
      left: 0;
      top: $topOffset;
    }

    &:checked {
      + .euiRadio__circle {
        @include euiCustomControlSelected($type: 'dot');
      }
    }

    &[disabled] {
      //sass-lint:disable no-important
      cursor: not-allowed !important;

      ~ .euiRadio__label {
        color: $euiFormControlDisabledColor;
        cursor: not-allowed !important;
      }

      + .euiRadio__circle {
        @include euiCustomControlDisabled;
      }
    }

    &:checked[disabled] {
      + .euiRadio__circle {
        @include euiCustomControlDisabled($type: 'dot');
      }
    }

    &:focus {
      + .euiRadio__circle {
        @include euiCustomControlFocused;
      }
    }
  }

  &.euiRadio--inList,
  &.euiRadio--noLabel {
    min-height: $euiRadioSize;
    min-width: $euiRadioSize;

    .euiRadio__input,
    .euiRadio__circle {
      top: 0;
    }

    .euiRadio__input {
      margin: 0;
    }
  }
}
