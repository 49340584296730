/**
 * 1. Using specificity to override panel shadow
 * 2. Prevent really long input from overflowing the container.
 */
.euiComboBoxOptionsList {
  // Remove transforms from popover panel
  transform: none !important; // sass-lint:disable-line no-important
  top: 0;

  .euiFilterSelectItem__content {
    // sass-lint:disable no-important
    margin-block: 0 !important;
  }
}

.euiComboBoxOptionsList__empty {
  @include euiTextBreakWord; /* 2 */
  padding: $euiSizeS;
  text-align: center;
  word-wrap: break-word;
}

.euiComboBoxOptionsList__rowWrap {
  padding: 0;
  max-height: 200px; // Also used/set in the JS file
  overflow: hidden;

  > div { // Targets the element for FixedSizeList that doesn't have a selector
    @include euiScrollBar;
  }
}
