$euiContextMenuWidth: $euiSize * 16;

.euiContextMenu {
  width: $euiContextMenuWidth;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  transition: height $euiAnimSpeedFast $euiAnimSlightResistance;
  border-radius: $euiBorderRadius;

  .euiContextMenu__content {
    padding: $euiSizeS;
  }
}

/**
  * 1. When there are multiple ContextMenuPanels, the ContextMenu will absolutely
  *    position them. ContextMenuPanel will break the layout of a Popover if it's
  *    absolutely positioned by default.
  */
.euiContextMenu__panel {
  position: absolute; /* 1 */
}

.euiContextMenu__icon {
  margin-right: $euiSizeS;
}
