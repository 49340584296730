@import 'breakpoints';


/// Create a collection of utility classes.
/// @access public
/// @example
///   @include wonderscore-atomic()
@mixin wonderscore-atomic() {

  .u-display-none {
    display: none;
  }

  .u-display-block {
    display: block;
  }

  // responsive line breaks
  br {
    &.u-hide--tablet-down {
      @include for-phone-only {
        display: none !important;
      }
    }

    &.u-hide--desktop-down {
      @include for-desktop-down {
        display: none !important;
      }
    }

    &.u-hide--large-desktop-down {
      @include for-large-desktop-down {
        display: none !important;
      }
    }
  }

  // hide visibly, but allow detection by screenreaders
  .u-show-for-sr {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }

}
