.euiDatePopoverButton--start {
  text-align: center;
}

.euiDatePopoverButton--end {
  text-align: center;
  border-top-right-radius: $euiFormControlBorderRadius;
  border-bottom-right-radius: $euiFormControlBorderRadius;

  &.euiDatePopoverButton--compressed {
    border-top-right-radius: $euiFormControlCompressedBorderRadius;
    border-bottom-right-radius: $euiFormControlCompressedBorderRadius;
  }
}
