/**
 * 1. Ensure the descenders don't get cut off
 * 2. Makes sure the height is correct when there's no selection
 */

.euiSuperSelectControl {
  @include euiFormControlStyle;

  display: block; /* 2 */
  text-align: left;
  line-height: $euiFormControlHeight; /* 1 */
  padding-top: 0; /* 1 */
  padding-bottom: 0; /* 1 */
  // Truncate the text
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &-isInvalid {
    @include euiFormControlInvalidStyle;
  }

  &--compressed {
    line-height: $euiFormControlCompressedHeight; /* 1 */
    padding-top: 0; /* 1 */
    padding-bottom: 0; /* 1 */
  }

  &.euiSuperSelect--isOpen__button { // since this is a button, we also want the visual indicator of active when options are shown
    @include euiFormControlFocusStyle;
  }
}
