.euiEmptyPrompt {
  text-align: center;
  margin: auto;

  @include euiBreakpoint('l', 'xl') {
    max-width: max-content; // the width becomes as wide as necessary to contain all of its contents
  }

  .euiEmptyPrompt__icon {
    // Consumers should use an EuiImage (recommended) with the horizontal layout
    // But they can use for example an img or other react node
    > * {
      flex-shrink: 1;
      max-width: convertToRem(360px);
    }
  }

  // Footer background colors
  @each $modifier, $color in $euiPanelBackgroundColorModifiers {
    @if ($modifier == 'transparent') {
      &.euiPanel--#{$modifier} {
        .euiEmptyPrompt__footer {
          background-color: $euiPageBackgroundColor;
        }

        &:not(.euiPanel--hasBorder) .euiEmptyPrompt__footer {
          border-radius: $euiBorderRadius;
        }

        &.euiPanel--hasBorder .euiEmptyPrompt__footer {
          border-radius: 0 0 $euiBorderRadius $euiBorderRadius;
        }
      }
    } @else if ($modifier == 'plain') {
      &.euiPanel--#{$modifier} .euiEmptyPrompt__footer {
        background-color: $euiPageBackgroundColor;
        border-radius: 0 0 $euiBorderRadius $euiBorderRadius;
      }
    } @else if ($modifier == 'subdued') {
      &.euiPanel--#{$modifier} .euiEmptyPrompt__footer {
        border-top: $euiBorderThin;
        border-radius: 0 0 $euiBorderRadius $euiBorderRadius;
      }
    } @else {
      &.euiPanel--#{$modifier} .euiEmptyPrompt__footer {
        border-top: 1px solid lightOrDarkTheme(darken($color, 10%), lighten($color, 6%));
        border-radius: 0 0 $euiBorderRadius $euiBorderRadius;
      }
    }
  }
}

$euiEmptyPromptContentMaxWidth: 36em;

.euiEmptyPrompt--vertical {
  .euiEmptyPrompt__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .euiEmptyPrompt__contentInner {
    max-width: $euiEmptyPromptContentMaxWidth;
    margin: auto;
  }

  .euiEmptyPrompt__icon {
    margin-bottom: $euiSize;
  }
}

.euiEmptyPrompt--horizontal {
  @include euiBreakpoint('l', 'xl') {
    justify-content: flex-start;
    text-align: left;
  }

  .euiEmptyPrompt__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include euiBreakpoint('l', 'xl') {
      flex-direction: row-reverse;
    }
  }

  .euiEmptyPrompt__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    @include euiBreakpoint('l', 'xl') {
      min-width: 40%;
      max-width: 50%;
    }
  }

  .euiEmptyPrompt__content {
    max-width: $euiEmptyPromptContentMaxWidth;

    @include euiBreakpoint('l', 'xl') {
      padding: $euiSizeL 0;
    }
  }

  .euiEmptyPrompt__actions {
    @include euiBreakpoint('l', 'xl') {
      justify-content: flex-start;
    }
  }
}

// Padding
@each $modifier, $amount in $euiPanelPaddingModifiers {
  .euiEmptyPrompt--#{$modifier} {
    .euiEmptyPrompt__main,
    .euiEmptyPrompt__footer {
      padding: $amount;
    }

    &.euiEmptyPrompt--horizontal {
      .euiEmptyPrompt__main {
        gap: $amount; // sass-lint:disable-line no-misspelled-properties
      }
    }
  }
}
