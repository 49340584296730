@use 'sass:math';


.euiRangeHighlight {
  z-index: 1;
  pointer-events: none;

  &__progress {
    background-color: $euiRangeHighlightColor;
    border-color: $euiRangeHighlightColor;

    &--hasFocus {
      background-color: $euiColorPrimary;
    }
  }

  &--compressed {
    top: calc(50% - #{math.div($euiRangeTrackCompressedHeight, 2)});

    .euiRangeHighlight__progress {
      height: $euiRangeHighlightCompressedHeight;
    }

    &.euiRangeHighlight--hasTicks {
      top: math.div(($euiRangeThumbHeight - $euiRangeTrackCompressedHeight), 2);
    }
  }

  &:not(.euiRangeHighlight--compressed) {
    &.euiRangeHighlight--hasTicks {
      top: math.div(($euiRangeThumbHeight - $euiRangeTrackHeight), 2);
    }
  }
}
