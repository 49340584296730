.euiDatePopoverButton {
  @include euiSuperDatePickerText;
  background-size: 100%; // For the bottom "border" via background-image

  &--compressed {
    line-height: $euiFormControlLayoutGroupInputCompressedHeight;
    height: $euiFormControlLayoutGroupInputCompressedHeight;
  }

  &:focus,
  &-isSelected {
    background-image: euiFormControlGradient();
  }

  &-needsUpdating {
    $backgroundColor: tintOrShade($euiColorSuccess, 90%, 70%);
    $textColor: makeHighContrastColor($euiColorSuccess, $backgroundColor);

    background-color: $backgroundColor;
    color: $textColor;

    &:focus,
    &.euiDatePopoverButton-isSelected {
      background-image: euiFormControlGradient($euiColorSuccess);
    }
  }

  &-isInvalid {
    @include euiFormControlInvalidStyle;
    background-color: transparent;
    color: $euiColorDangerText;

    &:focus,
    &.euiDatePopoverButton-isSelected {
      background-image: euiFormControlGradient($euiColorDanger);
    }
  }

  &:disabled {
    background-color: $euiFormBackgroundDisabledColor;
    color: $euiColorDarkShade;
    cursor: default;
  }
}

.euiDatePopoverButton--start {
  text-align: right;
}

.euiDatePopoverButton--end {
  text-align: left;
}
