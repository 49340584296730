/**
 * 1. We don't want any of the animations that come inherited from the mixin.
 *    These should act like normal links instead.
 * 2. Change the easing, quickness to not bounce so lighter backgrounds don't flash
 */
.euiButtonEmpty {
  @include euiButton;
  border-radius: $euiBorderRadius;

  // sass-lint:disable-block no-important
  transform: none !important; /* 1 */
  animation: none !important; /* 1 */
  transition-timing-function: ease-in; /* 2 */
  transition-duration: $euiAnimSpeedFast; /* 2 */

  .euiButtonEmpty__content {
    padding: 0 $euiSizeS;
  }

  .euiButtonEmpty__text {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.euiButtonEmpty--small {
    height: $euiButtonHeightSmall;
    border-radius: $euiBorderRadius * .667;
  }

  &.euiButtonEmpty--xSmall {
    height: $euiButtonHeightXSmall;
    border-radius: $euiBorderRadius * .667;
    font-size: $euiFontSizeXS;
  }

  &:disabled {
    @include euiButtonContentDisabled;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}


.euiButtonEmpty--flushLeft,
.euiButtonEmpty--flushRight,
.euiButtonEmpty--flushBoth {
  .euiButtonEmpty__content {
    padding-left: 0;
    padding-right: 0;
  }
}

.euiButtonEmpty--flushLeft {
  margin-right: $euiSizeS;
}

.euiButtonEmpty--flushRight {
  margin-left: $euiSizeS;
}
