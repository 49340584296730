.euiButtonGroup {
  display: inline-block;
  max-width: 100%;
  position: relative; // Ensures the EuiScreenReaderOnly component is positioned relative to this component
}

.euiButtonGroup--fullWidth {
  display: block;

  .euiButtonGroup__buttons {
    width: 100%;

    .euiButtonGroupButton {
      flex: 1;
    }
  }
}

.euiButtonGroup__buttons {
  border-radius: $euiBorderRadius + 1px; // Simply for the box-shadow
  max-width: 100%;
  display: flex;
  overflow: hidden;
}

.euiButtonGroup--isDisabled .euiButtonGroup__buttons {
  box-shadow: none;
}

.euiButtonGroup--compressed {
  .euiButtonGroup__buttons {
    box-shadow: none !important; // sass-lint:disable-line no-important
    border-radius: $euiFormControlCompressedBorderRadius;
    background-color: $euiFormBackgroundColor;
    height: $euiFormControlCompressedHeight;
    border: 1px solid $euiFormBorderColor;
    overflow: visible;
  }
}
