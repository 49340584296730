@use 'sass:math';


// Elastic UI

@import '../utility/breakpoints';




// This extra import allows any variables that are created via functions to work when loaded into JS
@import '../../vendor/eui/src/global_styling/functions/index';

// Global styling
@import '../../vendor/eui/src/themes/amsterdam/global_styling/index';




// Components
@import '../../vendor/eui/src/components/basic_table/index';
@import '../../vendor/eui/src/components/button/index';
@import '../../vendor/eui/src/components/combo_box/index';
@import '../../vendor/eui/src/components/context_menu/index';
@import '../../vendor/eui/src/components/date_picker/index';
@import '../../vendor/eui/src/components/empty_prompt/index';
@import '../../vendor/eui/src/components/filter_group/index';
@import '../../vendor/eui/src/components/key_pad_menu/index';
@import '../../vendor/eui/src/components/modal/index';
@import '../../vendor/eui/src/components/panel/index';
@import '../../vendor/eui/src/components/search_bar/index';
@import '../../vendor/eui/src/components/selectable/index';
@import '../../vendor/eui/src/components/table/index';
@import '../../vendor/eui/src/components/tabs/index';

@import '../../vendor/eui/src/components/form/checkbox/index';
@import '../../vendor/eui/src/components/form/radio/radio';
@import '../../vendor/eui/src/components/form/form';
@import '../../vendor/eui/src/components/form/form_help_text';
@import '../../vendor/eui/src/components/form/form_label';
@import '../../vendor/eui/src/components/form/form_row';
@import '../../vendor/eui/src/components/form/field_text';
@import '../../vendor/eui/src/components/form/select';
@import '../../vendor/eui/src/components/form/super_select';
@import '../../vendor/eui/src/components/form/switch';
@import '../../vendor/eui/src/components/form/text_area/text_area';
@import '../../vendor/eui/src/components/form/form_error_text';
@import '../../vendor/eui/src/components/form/form_control_layout';
@import '../../vendor/eui/src/components/form/field_search/index';




// Component overrides
// Comes after the component import and overrides via cascade
//@import '../../vendor/eui/src/themes/amsterdam/overrides/index';

@import '../../vendor/eui/src/themes/amsterdam/overrides/code';
@import '../../vendor/eui/src/themes/amsterdam/overrides/color_stops';
@import '../../vendor/eui/src/themes/amsterdam/overrides/combo_box';
//@import '../../vendor/eui/src/themes/amsterdam/overrides/data_grid';
@import '../../vendor/eui/src/themes/amsterdam/overrides/date_picker';
@import '../../vendor/eui/src/themes/amsterdam/overrides/date_popover_button';
@import '../../vendor/eui/src/themes/amsterdam/overrides/description_list';
@import '../../vendor/eui/src/themes/amsterdam/overrides/filter_group';
@import '../../vendor/eui/src/themes/amsterdam/overrides/form_control_layout';
@import '../../vendor/eui/src/themes/amsterdam/overrides/form_control_layout_delimited';
@import '../../vendor/eui/src/themes/amsterdam/overrides/form_controls';
@import '../../vendor/eui/src/themes/amsterdam/overrides/header';
@import '../../vendor/eui/src/themes/amsterdam/overrides/hue';
@import '../../vendor/eui/src/themes/amsterdam/overrides/key_pad_menu';
@import '../../vendor/eui/src/themes/amsterdam/overrides/markdown_editor';
@import '../../vendor/eui/src/themes/amsterdam/overrides/modal';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_draggable';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_highlight';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_levels';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_slider';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_thumb';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_ticks';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_track';
@import '../../vendor/eui/src/themes/amsterdam/overrides/range_tooltip';
@import '../../vendor/eui/src/themes/amsterdam/overrides/side_nav';
//@import '../../vendor/eui/src/themes/amsterdam/overrides/steps';
@import '../../vendor/eui/src/themes/amsterdam/overrides/tabs';



// component overrides
// TODO: move to a better implementation / location

// TODO: remove when affordForDisplacement={false} merged into EUI upstream
body {
  padding-bottom: 0 !important;
}


.euiEmptyPrompt {
  display: flex;

  @include for-tablet-up {
    min-height: 610px;
  }
}


.euiBadge {
  padding: 0 4px;
  min-width: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  @include for-tablet-up {
    padding: 0 8px;
  }

  // style variants
  &--outline {
    &.euiBadge {
      font-size: 12px;
      margin-top: -1px;

      &--secondary {
        background: transparent !important;
        color: $euiColorSecondary !important;
        border: 1px solid $euiColorSecondary !important;
      }
    }
  }

  // color variants
  &:not(.euiBadge--outline) {
    &.euiBadge {
      &--secondary {
        background: $euiColorSecondary !important;
        color: $euiColorInk !important;
      }
    }
  }
}


.euiBottomBar {
  z-index: 999;
  background: $wonderscore-color-black-2;
}


.euiButtonGroup--medium .euiButtonGroupButton,
.euiButtonGroup--small .euiButtonGroupButton {
  border-color: $wonderscore-color-white-10;
}

.euiButtonGroupButton:not([class*=isDisabled]).euiButtonGroupButton-isSelected {
  background-color: $wonderscore-color-daintree;
  border-color: $wonderscore-color-white-10;

  .euiIcon {
    path {
      fill: $wonderscore-color-white;
    }
  }

  &:hover,
  &:focus {
    background-color: $wonderscore-color-deep-into-the-jungle;
  }
}


.euiButton {
  font-size: 14px !important;

  // variants
  &--outline {
    background: none !important;
    border: 1px solid !important;
  }

  &--small {
    height: 36px !important;
    line-height: 36px !important;
  }

  &--extraSmall {
    height: 28px !important;
    line-height: 28px !important;
  }

  &--tiny {
    font-size: 12px !important;
    height: 22px !important;
    line-height: 22px !important;
    min-width: auto !important;
  }

  &--action {
    font-size: 14px !important;
    min-width: 220px !important;
  }
}

.euiButtonEmpty {
  height: 40px;
  font-family: $font--axiforma;
  font-size: 14px;

  // sizes
  &--xSmall {
    font-size: 14px !important;
  }
}

.euiFilterGroup {
  .euiButtonEmpty {
    &__content {
      padding: 0 12px;
    }
  }
}

.euiFilterButton {
  height: 40px;
  font-size: 14px !important;

  &-hasActiveFilters {
    border: 1px solid $wonderscore-color-white-10;
    background: $wonderscore-color-daintree;
  }
}


// TODO
.euiGlobalToastList {
  width: 500px !important;
}
.euiToast {
  min-width: 400px;
  border-radius: 0;

  .euiText {
    font-size: 14px;
  }
}


.euiCheckableCard {
  align-items: center;

  .euiCheckableCard__label {
    align-items: center;
  }

  // variants
  &--compact {
    .euiCheckableCard__label {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      .euiBadge {
        display: flex;
        align-content: center;
      }
    }
  }
}


.euiContextMenuPanel {
  font-size: 14px;
}


.euiContextMenuItem {
  &__text {
    // variants
    &--separator-bottom {
      border-bottom: 1px solid $euiColorLightShade;
    }
  }

  // color variants
  &--danger {
    color: $euiColorDanger !important;

    &:hover,
    &:focus {
      color: darken($euiColorDanger, 10%) !important;
      text-decoration: underline;
    }

    &:focus {
      @include euiFocusBackground($euiColorDanger);
      @include euiFocusRing(null, 0);
    }
  }

  // alignment variants
  &--align-right {
    text-align: right;
  }

  // variants
  &--separator-top {
    border-top: 1px solid $euiColorLightShade;
  }

  &--separator-bottom {
    border-bottom: 1px solid $euiColorLightShade;
  }

  // sizes
  &--small {
    .euiContextMenuItem__text {
      font-size: 14px;
    }
  }
}


.euiFlexItem {
  position: relative;

  //// TODO: used?
  //&--column-2 {
  //  // TODO: media query?
  //  flex-basis: 50% !important;
  //  max-width: 50%;
  //
  //  margin: 0 !important;
  //  padding: 12px !important;
  //}

  // variants
  &--custom {
    &:last-child {
      align-items: flex-end;
      min-width: 215px;     // TODO: able to dynamically find this width?
    }
  }
}


.euiFlyout {
  border-left: 1px solid $euiColorLightShade;
  box-shadow:
    0 40px 64px $wonderscore-color-black-10,
    0 24px 32px $wonderscore-color-black-10,
    0 16px 16px $wonderscore-color-black-10,
    0 8px 8px $wonderscore-color-black-10,
    0 4px 4px $wonderscore-color-black-10,
    0 2px 2px $wonderscore-color-black-10 !important;

  .euiFlyoutHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 92px;
    background: $wonderscore-color-satin-deep-black;
    border-bottom: 1px solid $euiColorLightShade;
    padding-block-start: 0 !important;

    .euiTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    h2 {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
      padding-right: 22px;
    }

    h3 {
      font-weight: 400;
      text-align: right;

      div {
        display: flex;
        flex-direction: column;

        a,
        span {
          font-size: 13px;
          line-height: 1.8;
        }

        a {
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .euiFlyoutBody {
    &__overflowContent {
      height: 100%;
    }
  }

  .euiFlyoutFooter {
    padding-top: 12px;
    padding-bottom: 12px;
    background: $wonderscore-color-satin-deep-black;
    border-top: 1px solid $euiColorLightShade;
  }

  // variants
  &--custom {
    max-width: 440px !important;
    width: 50vw !important;
  }
}


.euiForm {
  display: flex;
  flex-direction: column;
  width: 100%;

  .euiForm__errors {
    margin-bottom: 40px;
    padding: 12px 16px;

    .euiCallOutHeader__title {
      font-size: 14px;
    }
  }
}


.euiFormFieldset {
  // variants
  &--flushHorizontal {
    padding-left: 0;
    padding-right: 0;
  }
}


.euiFormRow {
  &__labelWrapper {
    margin-bottom: 6px;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .euiFormHelpText {
      padding-top: 7px;
      font-weight: 400;
    }
  }

  &__fieldWrapper {
    &-inner {
      position: relative;
    }

    &-aside {
      display: flex;
      align-items: center;
      float: right;
      margin: -11px 0 0 0;
    }
  }

  // embedded EUI components
  .euiLink {
    font-size: 13px;
  }

  // variants
  &--label-full {
    .euiFormRow {
      &__label {
        width: 100%;
      }
    }
  }

  &--datepicker-aside {
    min-height: 54px;

    .react-datepicker-popper {
      transform: translate3d(0px, 28px, 0px) !important;
    }

    .euiFormControlLayout__childrenWrapper {
      position: static;
    }
  }

  &--fill-vertical {
    flex: 1;

    .euiFormRow {
      &__fieldWrapper {
        height: 100%;
      }
    }
  }
}


.euiFieldText {
  &::placeholder {
    color: $euiColorMediumShade;    // default: $euiColorDarkShade
    transition: color $wonderscore-animation-duration--short $wonderscore-animation-curve;
  }
}


[type=search][placeholder] {
  &::placeholder {
    color: $euiColorMediumShade;    // default: $euiColorDarkShade
    transition: color $wonderscore-animation-duration--short $wonderscore-animation-curve;
  }
}


.euiOverlayMask {
  inset-block-start: 0 !important;
  padding-block-end: 0 !important;

  // variants
  &--centered-vertically {
    padding-bottom: 0;
  }
}


.euiModal {
  border: 1px solid $wonderscore-color-white-10;

  &:not(.euiModal--confirmation) {
    min-height: 708px;
  }

  > div {
    height: 100%;
  }

  &__closeIcon {
    right: 7px;
    top: 7px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  // embedded EUI components
  .euiEmptyPrompt {
    @include for-tablet-up {
      min-height: 440px;
    }
  }

  // variants
  &--prominent {
    background: repeating-linear-gradient(
        45deg,
        rgba(255, 102, 102, 0.25),
        rgba(255, 102, 102, 0.25) 1px,
        rgba(255, 102, 102, 0.05) 1px,
        rgba(255, 102, 102, 0.05) 20px
    );
    border: 1px solid $euiBorderColor;

    > div {
      margin: 30px;
      background: $euiColorEmptyShade;
      border-radius: 4px;
    }

    @include for-tablet-up {
      .euiModalHeader,
      .euiModalBody,
      .euiModalFooter {
        width: 100%;
      }
    }
  }
}


.euiPopover {
  &__panel {
    border: 1px solid $wonderscore-color-white-10;
    box-shadow:
      0 6px 12px -1px $wonderscore-color-black-20,
      0 4px 4px -1px $wonderscore-color-black-20,
      0 2px 2px 0 $wonderscore-color-black-20;

    .euiPopover__panelArrow {
      // TODO:
      $euiPopoverArrowSize: 12px;

      position: absolute;
      width: 0;
      height: 0;

      &:before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
      }

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
      }

      &.euiPopover__panelArrow--left {
        &:before {
          right: -$euiPopoverArrowSize + 1;
          top: 50%;
          border-top: $euiPopoverArrowSize solid transparent;
          border-bottom: $euiPopoverArrowSize solid transparent;
          border-left: $euiPopoverArrowSize solid $euiBorderColor;
        }

        &:after {
          right: -$euiPopoverArrowSize + 2;
          top: 50%;
          border-top: $euiPopoverArrowSize solid transparent;
          border-bottom: $euiPopoverArrowSize solid transparent;
          border-left: $euiPopoverArrowSize solid $euiColorEmptyShade;
        }
      }
    }
  }

  // variants
  &--preview {
    .euiPopover {
      &__panel {
        &-inner {
          font-size: 0;

          &,
          img {
            max-height: 180px;
          }
        }
      }

      &__panelArrow {
        &--left {
          right: -2px;
          top: calc(50% - 12px);
        }
      }
    }
  }

  &--panel {
    .euiPopover {
      &__panel {
        &-inner {
          min-width: 300px;
          padding: 16px;

          &-list {
            &-item {
              margin-bottom: 14px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &__panelArrow {
        &--left {
          right: -2px;
          top: 58px;
        }
      }
    }

    .euiPopoverTitle {
      text-transform: none;
    }
  }
}


.euiPopoverTitle {
  border-bottom: 1px solid $euiColorLightShade;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}


.euiModalHeader {
  &__title {
    line-height: 30px;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0;

    strong {
      font-weight: 700;
    }
  }
}


.euiModalBody {
  &__inner {
    &--presentation-views,
    &--presentation-links,
    &--presentation-sessions,
    &--media-issues,
    &--media-presentations {
      width: 100%;
      height: 43.5vw;     // ratio based on the intended desktop height / width

      // embedded components
      .c-table {
        padding-bottom: 28px;

        .euiTableCellContent {
          padding: 14px 8px;
        }
      }

      @include for-medium-desktop-up {
        width: $wonderscore-presentation-modal-width;
        height: $wonderscore-presentation-modal-height;
      }
    }

    &--presentation-views {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--presentation-links {
      padding: 16px 0;

      // embedded components
      .c-table {
        .euiTableCellContent {
          padding: 8px;
        }
      }
    }

    &--presentation-sessions {
      padding: 16px 0;

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .session {
        &:last-child {
          padding-bottom: 20px;
        }
      }

      // embedded components
      .euiLoadingChart {
        transform: rotate(90deg);
      }

      .euiAccordion {
        &__triggerWrapper {
          margin-left: -10px;
          padding: 2px 0;
        }

        &__button {
          padding: 6px 0;

          &:hover,
          &:focus {
            text-decoration: none;

            .datetime {
              text-decoration: underline;
            }
          }
        }

        &__buttonContent {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .left {
            display: flex;
            align-items: center;

            .column {
              display: inline-flex;
              min-width: 74px;
            }
          }

          span {
            &.client {
              opacity: 0.5;
            }
          }

          // embedded components
          .euiNotificationBadge {
            margin-right: 16px;
            cursor: pointer;
          }
        }

        // states
        &-isOpen {
          .datetime {
            font-weight: 700;
          }

          .euiAccordion__childWrapper {
            > div > div {
              padding-bottom: 20px;
            }
          }
        }
      }

      .dots {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0 0 0 22px;
        padding: 10px 0;
        overflow-x: auto;
        overflow-y: hidden;

        &-dot {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: auto;
          min-width: 140px;
          padding: 0 10px;

          &:first-child {
            padding-left: 5px;
          }

          &:last-child {
            padding-right: 5px;
          }

          &-button {
            height: 96px;
            padding: 10px;
            border: none;
            cursor: pointer;

            &-img {
              height: 100%;
              object-fit: contain;
            }
          }

          &-text {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 16px 0 0 0;

            &-icon {
              margin-top: -1px;
              margin-right: 6px;
            }
          }

          // embedded components
          .c-tooltip-button {
            display: flex;
            align-items: center;
            justify-content: center;

            &__tooltip {
              margin-top: 1px;

              &-content-inner {
                max-width: 140px;
                white-space: normal;
              }
            }
          }

          // states
          &[data-num-events]:not([data-num-events="0"]) {
            .dots-dot-button {
              outline: 2px solid $euiColorAccent;
            }
          }
        }
      }
    }
  }
}


.euiNotificationBadge {
  height: 16px !important;
  min-width: 16px !important;
  vertical-align: middle;
  text-align: center;
  cursor: default;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 16px;

  &--clickable {
    cursor: pointer;
  }

  &--subdued {
    color: $wonderscore-color-white;
    background: #71737a;
  }

  &--secondary {
    background: $wonderscore-color-blue-martina;
  }
}


.euiProgress {
  border-radius: 0 !important;

  &__valueText {
    &-value {
      font-weight: 700;

      &--unique {
        color: $euiColorAccent;
      }

      &--total {
        color: $wonderscore-color-blue-martina;
      }
    }
  }

  // variants
  &--unique {
    &::-webkit-progress-value {
      background-color: $euiColorAccent;
    }

    &::-moz-progress-bar {
      background-color: $euiColorAccent;
    }
  }

  &--total {
    &::-webkit-progress-value {
      background-color: $wonderscore-color-blue-martina;
    }

    &::-moz-progress-bar {
      background-color: $wonderscore-color-blue-martina;
    }
  }
}


.euiSelectableMessage {
  // variants
  &--loading,
  &--error {
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }

  &--info {
    color: $wonderscore-color-white-50;
  }
}


.euiStat {
  .euiText {
    font-size: 14px;
    line-height: 21px;
  }

  .euiTitle {
    letter-spacing: -0.5px;
    font-size: 36px;
    font-weight: 300;
    line-height: 48px;
  }

  // variants
  &--custom-color-1 {
    .euiTitle,
    .euiIcon {
      color: $wonderscore-color-middy-purple;
    }
  }
}


.euiPaginationButton {
  font-size: 14px !important;
  text-decoration: none !important;
}


.euiSelectableListItem {
  font-size: 14px !important;
}


.euiFormLabel,
.euiFormHelpText {
  font-size: 13px !important;
}


.euiPanel {
  background-color: #1d1e24;
  //border: 1px solid $euiColorLightShade;    // TODO
  border-radius: 4px;
  flex-grow: 1;

  // variants
  &--plain {
  &.euiPanel--isClickable {
    transition: background $wonderscore-animation-duration--short $wonderscore-animation-curve;

    &:hover,
    &:focus {
      background: $wonderscore-color-jaguar;
    }
  }
  }
}


.euiSuperSelectControl {
  display: flex;
  align-items: center;
}


body .euiTab {
  padding: 4px 16px;

  .euiTab__content {
    font-weight: 400 !important;
  }

  &.euiTab-isSelected {
    box-shadow: inset 0 -2px $wonderscore-color-blue-martina !important;

    &,
    .euiTab__content {
      color: $wonderscore-color-blue-martina !important;
    }
  }
}


.euiTabs--small,
.euiTabs--condensed.euiTabs--small {
  .euiTab + .euiTab {
    margin-left: 16px !important;
  }

  .euiTab {
    .euiTab__content {
      font-weight: 600 !important;
      line-height: 36px;
    }
  }
}

.euiTab + .euiTab,
.euiTabs--condensed .euiTab + .euiTab {
  margin-left: 0;
}


.euiToolTip {
  font-size: 14px !important;
}


.euiTable {
  font-size: 13px !important;
  line-height: 1 !important;

  &--responsive {
    .euiTableRow {
      @media only screen and (max-width: 574px) {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      // states
      &:focus,
      &:hover {
        background-color: $wonderscore-color-jaguar;

        [data-loading="true"] & {
          background-color: unset;
        }
      }
    }
  }
}


.euiTableFooterCell .euiTableCellContent__text,
.euiTableHeaderCell .euiTableCellContent__text {
  font-size: 12px !important;
}


.euiButtonIcon {
  border-radius: 4px;
}


.euiTableRowCell {
  .euiButtonIcon {
    width: auto !important;
    height: auto !important;
    padding: 4px;
  }

  .euiHealth {
    font-size: 14px !important;

    .euiFlexItem {
      &:first-child {
        margin: 2px 2px 2px -3px;

        @include for-tablet-up {
          margin: 2px 7px 2px -3px;
        }
      }
    }
  }
}


.euiTableCellContent {
  &--center {
    justify-content: center;
  }
}


// TODO: resize handle is not styled in EUI dark mode - raise upstream issue?
::-webkit-resizer {
  display: none;

  // TODO: sample stylable properties
  //border: 2px solid black;
  //background: red;
  //box-shadow: 0 0 5px 5px blue;
  //outline: 2px solid yellow;
}
