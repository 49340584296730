@import '../../vendor/eui/src/global_styling/functions/math';
@import '../../vendor/eui/src/global_styling/functions/colors';

//@import '../../vendor/eui/src/themes/amsterdam/theme_dark';





// These are variable overwrites used only for this theme.
//@import '../../vendor/eui/src/themes/amsterdam/colors_dark';








@import '../core/settings';


////
/// Font families
////

$font--axiforma: 'Axiforma', 'Verdana', sans-serif;




////
/// Animations
////

$wonderscore-animation-duration--short: 0.25s;
$wonderscore-animation-duration--hover: 0.35s;
$wonderscore-animation-duration--medium: 0.5s;
$wonderscore-animation-duration--intermediate: 1s;
$wonderscore-animation-duration--long: 1.5s;
$wonderscore-animation-curve: cubic-bezier(0.22, 0.61, 0.35, 1);




////
/// Color Variables
/// - Names generated using https://colornamer.robertcooper.me/
////

$wonderscore-color-white: #ffffff;
$wonderscore-color-white--active: darken($wonderscore-color-white, 10%);
$wonderscore-color-white-90: rgba(255, 255, 255, 0.9);
$wonderscore-color-white-80: rgba(255, 255, 255, 0.8);
$wonderscore-color-white-70: rgba(255, 255, 255, 0.7);
$wonderscore-color-white-50: rgba(255, 255, 255, 0.5);
$wonderscore-color-white-40: rgba(255, 255, 255, 0.4);
$wonderscore-color-white-20: rgba(255, 255, 255, 0.2);
$wonderscore-color-white-10: rgba(255, 255, 255, 0.1);

$wonderscore-color-black: #000000;
$wonderscore-color-black-90: rgba(0, 0, 0, 0.9);
$wonderscore-color-black-80: rgba(0, 0, 0, 0.8);
$wonderscore-color-black-70: rgba(0, 0, 0, 0.7);
$wonderscore-color-black-60: rgba(0, 0, 0, 0.6);
$wonderscore-color-black-50: rgba(0, 0, 0, 0.5);
$wonderscore-color-black-20: rgba(0, 0, 0, 0.2);
$wonderscore-color-black-10: rgba(0, 0, 0, 0.1);
$wonderscore-color-black-2: #010207;
$wonderscore-color-black-3: #141414;
$wonderscore-color-charcoal: #191A1A;
$wonderscore-color-coal-mine: #52555e;        // TODO: from EUI?
$wonderscore-color-namara-grey: #858585;
$wonderscore-color-moon-lily: #e6e6e6;

$wonderscore-color-thimbleberry: #e6434d;
$wonderscore-color-thimbleberry--darker: #e22834;
$wonderscore-color-thimbleberry-50: rgba(230, 67, 77, 0.50);
$wonderscore-color-thimbleberry--inverted: #ff6584;

$wonderscore-color-quill-tip: #32325d;
$wonderscore-color-lavender-lustre: #8f9bc0;
$wonderscore-color-deutzia-white: #f6f9fc;

$wonderscore-color-transparent: transparent;




// TODO: replace with EUI variables / names

$wonderscore-color-jaguar: #282830;
$wonderscore-color-aswad-black: #16171c;    // TODO: EuiInput background color, doesn't seem to be exported?
$wonderscore-color-satin-deep-black: #1a1b20;
$wonderscore-color-deep-into-the-jungle: #004a49;
$wonderscore-color-customs-green: #083c3c;
$wonderscore-color-daintree: #052a2a;

$wonderscore-color-middy-purple: #a987d1;
$wonderscore-color-blue-martina: #19c9c8;
$wonderscore-color-blue-martina--active: darken($wonderscore-color-blue-martina, 10%);












// TODO

// Core
$euiColorPrimary--original: #1BA9F5;
$euiColorPrimary: $wonderscore-color-blue-martina;
$euiColorSecondary: #7DE2D1;
$euiColorAccent: #F990C0;
$euiColorHighlight: #2E2D25;

// These colors stay the same no matter the theme
$euiColorGhost: #FFF;
$euiColorInk: #000;

// Status
$euiColorSuccess: $euiColorSecondary;
$euiColorWarning: #FFCE7A;
$euiColorDanger: #F66;

// Grays
$euiColorEmptyShade: #1D1E24;
$euiColorEmptyShade--70: rgba($euiColorEmptyShade, 0.7);
$euiColorLightestShade: #25262E;
$euiColorLightShade: #343741;
$euiColorMediumShade: #535966;
$euiColorDarkShade: #98A2B3;
$euiColorDarkestShade: #D4DAE5;
$euiColorFullShade: #FFF;

// Variations from core
$euiTextColor: #DFE5EF;
$euiLinkColor: $euiColorPrimary;
$euiFocusBackgroundColor: #232635;
$euiShadowColor: #000;
$euiShadowColorLarge: #000;
$euiPageBackgroundColor: shade($euiColorLightestShade, 30%);
$euiTextSubduedColor: makeHighContrastColor($euiColorMediumShade);
$euiTitleColor: $euiTextColor;

// Contrasty text variants
$euiColorPrimaryText: makeHighContrastColor($euiColorPrimary);
$euiColorSecondaryText: makeHighContrastColor($euiColorSecondary);
$euiColorAccentText: makeHighContrastColor($euiColorAccent);
$euiColorWarningText: makeHighContrastColor($euiColorWarning);
$euiColorDangerText: makeHighContrastColor($euiColorDanger, $euiColorLightShade);
$euiColorSuccessText: $euiColorSecondaryText;







// EUI colors
//@import '../../vendor/eui/src/theme_dark';




// These are variable overwrites used only for this theme.
//@import '../../vendor/eui/src/themes/eui/eui_colors_dark';


// Global styling
//@import '../../vendor/eui/src/global_styling/index';



// Visualization colors

// Maps allow for easier JSON usage
// Use map_merge($euiColorVisColors, $yourMap) to change individual colors after importing ths file
// The `behindText` variant is a direct copy of the hex output by the JS euiPaletteColorBlindBehindText() function
$euiPaletteColorBlind: (
  euiColorVis0: (
    graphic: #54B399,
    behindText: #6DCCB1,
  ),
  euiColorVis1: (
    graphic: #6092C0,
    behindText: #79AAD9,
  ),
  euiColorVis2: (
    graphic: #D36086,
    behindText: #EE789D,
  ),
  euiColorVis3: (
    graphic: #9170B8,
    behindText: #A987D1,
  ),
  euiColorVis4: (
    graphic: #CA8EAE,
    behindText: #E4A6C7,
  ),
  euiColorVis5: (
    graphic: #D6BF57,
    behindText: #F1D86F,
  ),
  euiColorVis6: (
    graphic: #B9A888,
    behindText: #D2C0A0,
  ),
  euiColorVis7: (
    graphic: #DA8B45,
    behindText: #F5A35C,
  ),
  euiColorVis8: (
    graphic: #AA6556,
    behindText: #C47C6C,
  ),
  euiColorVis9: (
    graphic: #E7664C,
    behindText: #FF7E62,
  )
) !default;

$euiPaletteColorBlindKeys: map-keys($euiPaletteColorBlind);

$euiColorVis0: map-get(map-get($euiPaletteColorBlind, 'euiColorVis0'), 'graphic') !default;
$euiColorVis1: map-get(map-get($euiPaletteColorBlind, 'euiColorVis1'), 'graphic') !default;
$euiColorVis2: map-get(map-get($euiPaletteColorBlind, 'euiColorVis2'), 'graphic') !default;
$euiColorVis3: map-get(map-get($euiPaletteColorBlind, 'euiColorVis3'), 'graphic') !default;
$euiColorVis4: map-get(map-get($euiPaletteColorBlind, 'euiColorVis4'), 'graphic') !default;
$euiColorVis5: map-get(map-get($euiPaletteColorBlind, 'euiColorVis5'), 'graphic') !default;
$euiColorVis6: map-get(map-get($euiPaletteColorBlind, 'euiColorVis6'), 'graphic') !default;
$euiColorVis7: map-get(map-get($euiPaletteColorBlind, 'euiColorVis7'), 'graphic') !default;
$euiColorVis8: map-get(map-get($euiPaletteColorBlind, 'euiColorVis8'), 'graphic') !default;
$euiColorVis9: map-get(map-get($euiPaletteColorBlind, 'euiColorVis9'), 'graphic') !default;

// sass-lint:disable-block variable-name-format
$euiColorVis0_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis0'), 'behindText') !default;
$euiColorVis1_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis1'), 'behindText') !default;
$euiColorVis2_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis2'), 'behindText') !default;
$euiColorVis3_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis3'), 'behindText') !default;
$euiColorVis4_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis4'), 'behindText') !default;
$euiColorVis5_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis5'), 'behindText') !default;
$euiColorVis6_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis6'), 'behindText') !default;
$euiColorVis7_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis7'), 'behindText') !default;
$euiColorVis8_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis8'), 'behindText') !default;
$euiColorVis9_behindText: map-get(map-get($euiPaletteColorBlind, 'euiColorVis9'), 'behindText') !default;

// Charts
$euiColorChartLines: shade($euiColorLightestShade, 3%) !default;
$euiColorChartBand: $euiColorLightestShade !default;

// Code
$euiCodeBlockBackgroundColor: $euiColorLightestShade !default;
$euiCodeBlockColor: $euiTextColor !default;
$euiCodeBlockSelectedBackgroundColor: inherit !default;
$euiCodeBlockCommentColor: #998 !default;
$euiCodeBlockSelectorTagColor: inherit !default;
$euiCodeBlockStringColor: #DD0A73 !default;
$euiCodeBlockNumberColor: #00A69B !default;
$euiCodeBlockKeywordColor: #333 !default;
$euiCodeBlockFunctionTitleColor: inherit !default;
$euiCodeBlockTagColor: #0079A5 !default;
$euiCodeBlockNameColor: inherit !default;
$euiCodeBlockTypeColor: #0079A5 !default;
$euiCodeBlockAttributeColor: inherit !default;
$euiCodeBlockSymbolColor: #990073 !default;
$euiCodeBlockParamsColor: inherit !default;
$euiCodeBlockMetaColor: #999 !default;
$euiCodeBlockTitleColor: #900 !default;
$euiCodeBlockRegexpColor: #009926 !default;
$euiCodeBlockBuiltInColor: #0086B3 !default;
$euiCodeBlockSectionColor: #FFC66D !default;
$euiCodeBlockAdditionBackgroundColor: #DFD !default;
$euiCodeBlockAdditionColor: inherit !default;
$euiCodeBlockDeletionBackgroundColor: #FDD !default;
$euiCodeBlockDeletionColor: inherit !default;
$euiCodeBlockSelectorClassColor: inherit !default;
$euiCodeBlockSelectorIdColor: inherit !default;








////
/// Shared component styling
////

/// Custom page scrollbar width
/// @type Number
$wonderscore-scrollbar-width: 16px;


/// Focusable element outline width
/// @type Number
$wonderscore-branding__custom-focus-outline-width: 2px !default;

/// Focusable element outline style
/// @type String
$wonderscore-branding__custom-focus-outline-type: solid !default;

/// Focusable element outline offset
/// @type Number
$wonderscore-branding__custom-focus-outline-offset: 2px !default;




$euiBorderRadius: 0;




////
/// Branding Mixins
////

// custom scrollbars
@mixin wonderscore-branding__custom-focus-outline-rules($unset: false, $outlineColor: $euiColorLightShade, $outlineOffset: $wonderscore-branding__custom-focus-outline-offset, $important: !important) {
  @if $unset {
    outline: initial !important;
    outline-offset: initial !important;
  } @else {
    outline: $wonderscore-branding__custom-focus-outline-width $wonderscore-branding__custom-focus-outline-type $outlineColor $important;
    outline-offset: $outlineOffset $important;
  }
}

@mixin wonderscore-branding__custom-focus-outline($unset: false, $outlineColor: $euiColorLightShade, $outlineOffset: $wonderscore-branding__custom-focus-outline-offset, $important: !important) {
  &:focus {
    @include wonderscore-branding__custom-focus-outline-rules($unset, $outlineColor, $outlineOffset, $important);

    // optional additional styles to apply in the :focus state context
    @content;
  }
}


@mixin wonderscore-branding__text-selection-highlight {
  // use brand color for text selection highlight
  ::selection {
    background: $wonderscore-color-blue-martina;
    color: $wonderscore-color-white;
  }
}

@mixin wonderscore-branding__text-selection-highlight--alt {
  // use inverted brand color for text selection highlight
  ::selection {
    background: $wonderscore-color-white;
    color: $wonderscore-color-blue-martina;
  }
}
