.euiFormControlLayoutCustomIcon {
  pointer-events: none;
  font-size: 0; // ensures the icon stays vertically centered
}

.euiFormControlLayoutCustomIcon--clickable {
  @include size($euiSize);

  pointer-events: all;

  .euiFormControlLayoutCustomIcon__icon {
    vertical-align: baseline;
    transform: none;
  }

  &:focus {
    @include euiFocusRing;
  }

  &:disabled {
    cursor: not-allowed;
    color: $euiFormControlDisabledColor;
  }
}
