////
/// wonderscore Breakpoint Utility
/// @group Breakpoints
////

@import '../vendor/include-media/include-media';

@import '../core/settings';


/// Output CSS rules for small phones and up
/// @access public
/// @example
///   @include for-small-phone-up { ... }
@mixin for-small-phone-up {
  @include media('≥small-phone') { @content; }
}

/// Output CSS rules for phones only
/// @access public
/// @example
///   @include for-phone-only { ... }
@mixin for-phone-only {
  @include media('<phone') { @content; }
}

/// Output CSS rules for phones and up
/// @access public
/// @example
///   @include for-phone-up { ... }
@mixin for-phone-up {
  @include media('≥phone') { @content; }
}

/// Output CSS rules for tablet and up
/// @access public
/// @example
///   @include for-tablet-up { ... }
@mixin for-tablet-up {
  @include media('≥small') { @content; }
}

/// Output CSS rules for tablet only
/// @access public
/// @example
///   @include for-tablet-only { ... }
@mixin for-tablet-only {
  @include media('≥small', '<medium') { @content; }
}

/// Output CSS rules for mobile and up
/// @access public
/// @example
///   @include for-mobile-up { ... }
@mixin for-mobile-up {
  @include media('≥mobile') { @content; }
}

/// Output CSS rules for mobile only
/// @access public
/// @example
///   @include for-mobile-only { ... }
@mixin for-mobile-only {
  @include media('≥mobile', '<medium') { @content; }
}

/// Output CSS rules for desktop and up
/// @access public
/// @example
///   @include for-desktop-up { ... }
@mixin for-desktop-up {
  @include media('≥medium') { @content; }
}

/// Output CSS rules for desktop and down
/// @access public
/// @example
///   @include for-desktop-down { ... }
@mixin for-desktop-down {
  @include media('<medium') { @content; }
}

/// Output CSS rules for desktop only
/// @access public
/// @example
///   @include for-desktop-only { ... }
@mixin for-desktop-only {
  @include media('≥medium', '<large') { @content; }
}

/// Output CSS rules for large desktop and up
/// @access public
/// @example
///   @include for-large-desktop-up { ... }
@mixin for-large-desktop-up {
  @include media('≥large') { @content; }
}

/// Output CSS rules for large desktop and down
/// @access public
/// @example
///   @include for-large-desktop-down { ... }
@mixin for-large-desktop-down {
  @include media('<large') { @content; }
}

/// Output CSS rules for large desktop only
/// @access public
/// @example
///   @include for-large-desktop-only { ... }
@mixin for-large-desktop-only {
  @include media('≥large', '<extra-large') { @content; }
}

/// Output CSS rules for extra large desktop and up
/// @access public
/// @example
///   @include for-extra-large-desktop-up { ... }
@mixin for-extra-large-desktop-up {
  @include media('≥extra-large') { @content; }
}

/// Output CSS rules for extra large desktop and down
/// @access public
/// @example
///   @include for-extra-large-desktop-down { ... }
@mixin for-extra-large-desktop-down {
  @include media('<extra-large') { @content; }
}

/// Output CSS rules for ultra wide desktop and up
/// @access public
/// @example
///   @include for-ultra-wide-desktop-up { ... }
@mixin for-ultra-wide-desktop-up {
  @include media('≥ultra-wide') { @content; }
}

/// Output CSS rules for ultra wide desktop and down
/// @access public
/// @example
///   @include for-ultra-wide-desktop-down { ... }
@mixin for-ultra-wide-desktop-down {
  @include media('<ultra-wide') { @content; }
}



@mixin for-medium-desktop-up {
  @media (min-width: $wonderscore-horizontal-threshold--medium-desktop) {
    @content;
  }
}

@mixin for-medium-desktop-up--vertical {
  @media (min-height: $wonderscore-vertical-threshold--medium-desktop) {
    @content;
  }
}




// browser-specific media queries
@mixin for-ie-10-11 {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
