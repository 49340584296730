////
/// wonderscore Accessibility
/// @group Core
////

@import 'branding';


@mixin wonderscore-accessibility() {
  :-moz-focusring {
    @include wonderscore-branding__custom-focus-outline-rules;
  }


  // TODO: re-enable for non-EUI components

  //:not([class^="eui"]),
  //a[href]:not(.has-click),
  //button:not([disabled]):not(.has-click),
  //input:not([disabled]),
  //select:not([disabled]),
  //textarea:not([disabled]),
  //[tabindex]:not([tabindex="-1"]) {
  //  @include wonderscore-branding__custom-focus-outline($important: null);
  //}
}
