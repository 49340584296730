@use 'sass:math';


.euiFormControlLayoutDelimited {
  // Match just the regular drop shadow of inputs
  @include euiFormControlDefaultShadow;
  display: flex;
  align-items: stretch;
  padding: 1px; /* 1 */

  .euiFormControlLayoutDelimited__delimeter {
    background-color: $euiFormBackgroundColor;
  }

  > .euiFormControlLayout__childrenWrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  // Target when the euiFormControlLayout is compressed without specifying the full class name in case it ever changes
  &[class*='--compressed'] {
    @include euiFormControlDefaultShadow($borderOnly: true);
    border-radius: math.div($euiBorderRadius, 2);

    .euiFormControlLayoutDelimited__input {
      height: 100%;
      padding-left: $euiFormControlCompressedPadding;
      padding-right: $euiFormControlCompressedPadding;
    }

    .euiFormControlLayoutIcons {
      padding-left: $euiFormControlCompressedPadding;
      padding-right: $euiFormControlCompressedPadding;
    }
  }

  // Target when the euiFormControlLayout is fullWidth without specifying the full class name in case it ever changes
  &[class*='--fullWidth'] .euiFormControlLayout__childrenWrapper,
  &[class*='--fullWidth'] input {
    width: 100%;
    max-width: none;
  }

  // Target when the euiFormControlLayout is disabled without specifying the full class name in case it ever changes
  &[class*='-isDisabled'] {
    @include euiFormControlDisabledStyle;

    .euiFormControlLayoutDelimited__delimeter {
      background-color: $euiFormBackgroundDisabledColor;
    }
  }

  // Target when the euiFormControlLayout is readOnly without specifying the full class name in case it ever changes
  &[class*='--readOnly'] {
    @include euiFormControlReadOnlyStyle;

    input,
    .euiFormControlLayoutDelimited__delimeter {
      background-color: $euiFormBackgroundReadOnlyColor;
    }
  }

  .euiFormControlLayoutIcons {
    // Absolutely positioning the icons doesn't work because they
    // overlay only one of controls making the layout unbalanced
    position: static; // Overrider absolute
    padding-left: $euiFormControlPadding;
    padding-right: $euiFormControlPadding;

    &:not(.euiFormControlLayoutIcons--right) {
      order: -1;
    }
  }
}

.euiFormControlLayoutDelimited__input {
  // sass-lint:disable-block no-important
  box-shadow: none !important;
  border-radius: 0 !important;
  text-align: center;
  height: 100%;
  min-width: 0; // Fixes FF

  .euiFormControlLayoutDelimited[class*='--compressed'] & {
    max-width: none;
  }
}

.euiFormControlLayoutDelimited__delimeter {
  // sass-lint:disable-block no-important
  line-height: 1 !important; // Override EuiText line-height
  flex: 0 0 auto;
  padding-left: math.div($euiFormControlPadding, 2);
  padding-right: math.div($euiFormControlPadding, 2);
}
