.euiFormControlLayout--group {
  border-radius: $euiFormControlBorderRadius;
  background-color: $euiFormInputGroupLabelBackground;

  .euiFormControlLayout__prepend:first-child {
    @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'left', $internal: true);

    [class*='euiButton'] {
      @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'left', $internal: true);
    }
  }

  .euiFormControlLayout__append:last-child {
    @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'right', $internal: true);

    [class*='euiButton'] {
      @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'right', $internal: true);
    }
  }

  [class*='euiButton']:focus {
    @include euiFocusRing(null, 'inner');
  }

  .euiToolTipAnchor > .euiIcon {
    @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'right', $internal: true);
  }

  .euiToolTipAnchor:first-child [class*='euiButton'] {
    @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'left', $internal: true);
  }

  .euiToolTipAnchor:last-child {
    [class*='euiButton'],
    .euiText {
      @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'right', $internal: true);
    }
  }

  .euiFormControlLayout__childrenWrapper:nth-child(2) [class*='euiField'],
  .euiFormControlLayout__childrenWrapper:nth-child(3) [class*='euiField'] {
    border-radius: 0;
  }

  .euiFormControlLayout__childrenWrapper:first-child .euiSelect,
  .euiFormControlLayout__childrenWrapper:first-child .euiSuperSelectControl,
  .euiFormControlLayout__childrenWrapper:first-child [class*='euiField'] {
    @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'left');
  }

  .euiFormControlLayout__childrenWrapper:last-child .euiSelect,
  .euiFormControlLayout__childrenWrapper:last-child .euiSuperSelectControl,
  .euiFormControlLayout__childrenWrapper:last-child [class*='euiField'] {
    @include euiFormControlSideBorderRadius($euiFormControlBorderRadius, $side: 'right');
  }

  &.euiFormControlLayout--compressed {
    border-radius: $euiFormControlCompressedBorderRadius;
    background-color: $euiFormInputGroupLabelBackground;

    &.euiFormControlLayout--readOnly input {
      background-color: $euiFormBackgroundReadOnlyColor;
    }

    .euiFormControlLayout__prepend:first-child {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'left', $internal: true);

      [class*='euiButton'] {
        @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'left');
      }
    }

    .euiFormControlLayout__append:last-child {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'right', $internal: true);

      [class*='euiButton'] {
        @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'right', $internal: true);
      }
    }

    .euiToolTipAnchor > .euiIcon {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'right', $internal: true);
    }

    .euiToolTipAnchor:first-child [class*='euiButton'] {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'left', $internal: true);
    }

    .euiToolTipAnchor:last-child [class*='euiButton'],
    .euiToolTipAnchor:last-child .euiText {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'right', $internal: true);
    }

    .euiFormControlLayout__childrenWrapper:nth-child(2) [class*='euiField'],
    .euiFormControlLayout__childrenWrapper:nth-child(3) [class*='euiField'] {
      border-radius: 0;
    }

    .euiFormControlLayout__childrenWrapper:first-child .euiSelect,
    .euiFormControlLayout__childrenWrapper:first-child [class*='euiField'] {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'left', $internal: true);
    }

    .euiFormControlLayout__childrenWrapper:last-child .euiSelect,
    .euiFormControlLayout__childrenWrapper:last-child [class*='euiField'] {
      @include euiFormControlSideBorderRadius($euiFormControlCompressedBorderRadius, $side: 'right', $internal: true);
    }
  }
}
