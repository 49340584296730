@charset "UTF-8";

// Third-party
@import 'core/includes';

// Fonts
@import 'core/fonts';

// Utility
@import 'utility/atomic';
@import 'utility/breakpoints';



// These are variable overwrites used only for this theme.
@import '../vendor/eui/src/themes/amsterdam/colors_dark';




// Core
@import 'core/settings';
@import 'core/branding';
@import 'core/reset';
@import 'core/global';

// Elastic UI
@import 'core/elasticui';

// Objects
@import 'objects/page';




// Core
@include wonderscore-reset;
@include wonderscore-global;

// Utility
@include wonderscore-atomic;

// Objects
@include wonderscore-page;




// TODO:
.c-changelog {
  &__list {
    &-item {
      display: block;
      margin: 0 0 48px 0;

      &:last-child {
        margin-bottom: 0;
      }

      &-list {
        list-style: circle;
        margin: 20px 0 0 40px;

        &-item {
          display: list-item;
          margin: 10px 0;

          code {
            margin: 0 2px;
            padding: 2px 4px;
            border: 1px solid $wonderscore-color-namara-grey;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
