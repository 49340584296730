.euiFilterGroup {
  border: none;
  border-radius: $euiFormControlBorderRadius;
  background-color: $euiFormBackgroundColor;
  box-shadow: inset 0 0 0 1px $euiFormBorderColor;
}

.euiFilterGroup--compressed {
  border-radius: $euiFormControlCompressedBorderRadius;

  & .euiFilterButton {
    height: $euiFormControlCompressedHeight;
  }
}

.euiFilterButton {
  border-radius: 0;
  border: none;
  background-color: transparent;
  // Box shadow simulates bottom and left borders
  box-shadow: 0 1px 0 0 $euiFormBorderColor, -1px 0 0 0 $euiFormBorderColor;

  .euiFilterButton--withNext + & {
    // Remove just the left faux border
    box-shadow: 0 1px 0 0 $euiFormBorderColor;
  }
}
