@use 'sass:math';


// This file has lots of modifiers and is somewhat nesty by nature
// sass-lint:disable nesting-depth

.euiButtonGroupButton {
  @include euiButtonBase;
  @include euiFont;
  @include euiFontSize;

  // sass-lint:disable-block indentation
  transition: background-color $euiAnimSpeedNormal ease-in-out,
              color $euiAnimSpeedNormal ease-in-out;

  // Allow button to shrink and truncate
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 0;

  .euiButton__content {
    padding: 0 $euiSizeM;
  }

  &-isIconOnly .euiButton__content {
    padding: 0 $euiSizeS;
  }

  &.euiButtonGroupButton--small {
    height: $euiButtonHeightSmall;
    line-height: $euiButtonHeightSmall; // prevents descenders from getting cut off
  }

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:focus-within {
      text-decoration: underline;
    }
  }

  &:disabled {
    @include euiButtonContentDisabled;

    &.euiButtonGroupButton-isSelected {
      color: makeDisabledContrastColor($euiButtonColorDisabled, $euiButtonColorDisabled);
      background-color: $euiButtonColorDisabled;
      border-color: $euiButtonColorDisabled;
    }
  }
}

.euiButtonGroupButton__textShift {
  @include euiTextShift;
}

/**
 * Medium and Small sizing (regular button style)
 */

.euiButtonGroup--medium,
.euiButtonGroup--small {
  .euiButtonGroupButton {
    border-radius: 0 !important; // sass-lint:disable-line no-important
    font-weight: $euiButtonFontWeight;

    &:focus,
    &:focus-within {
      outline-style: solid;
      outline-color: lightOrDarkTheme($euiColorInk, $euiColorGhost);
      outline-offset: -2px;

      &:focus-visible {
        outline-style: auto; // For chrome only
      }
    }

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:not(:first-child):not(.euiButtonGroupButton-isSelected):not(:disabled) {
      box-shadow: -1px 0 0 0 transparentize($euiShadowColor, .95);
    }

    &:first-child {
      border-radius: $euiBorderRadius 0 0 $euiBorderRadius;
    }

    &:last-child {
      border-radius: 0 $euiBorderRadius $euiBorderRadius 0;
    }
  }

  .euiButtonGroupButton-isSelected {
    z-index: 0;
  }

  // Faux border for separation of selected buttons
  .euiButtonGroupButton-isSelected + .euiButtonGroupButton-isSelected {
    box-shadow: -1px 0 0 transparentize($euiColorEmptyShade, .9);
  }
}

.euiButtonGroup--small .euiButtonGroup__buttons {
  // Use a moderately smaller radius on small buttons
  // so that they don't appear completely rounded
  border-radius: $euiBorderRadius * math.div(2, 3);
}

/**
 * Compressed (form style)
 */

.euiButtonGroup--compressed {
  .euiButtonGroupButton {
    height: $euiFormControlCompressedHeight - 2px;
    line-height: $euiFormControlCompressedHeight - 2px; // prevents descenders from getting cut off
    font-size: $euiFontSizeS;
    // Add 1 to the border radius to account for the background-clip
    border-radius: $euiFormControlCompressedBorderRadius + 1;
    // Offset the background color from the border by 2px
    // by clipping background to before the padding starts
    padding: 2px;
    background-clip: content-box;

    .euiButton__content {
      padding-left: $euiSizeS;
      padding-right: $euiSizeS;
    }

    &.euiButtonGroupButton-isSelected {
      font-weight: $euiFontWeightSemiBold;
    }

    &:not(:disabled) {
      &:focus,
      &:focus-within {
        outline: $euiFocusRingSize solid currentColor;
      }
    }

    @each $name, $color in $euiButtonTypes {
      &[class*='-fill-#{$name}'] {
        // Complicated set of focus states depending on whether it's a button and can receive :focus,
        // or an input and uses focus-within, and browser support for :focus-visible,
        // and override nature of default theme
        &:not(:disabled):focus,
        &:not(:disabled):focus-within {
          outline-color: $color;

          &:focus-visible {
            outline-style: auto; // For chrome only
          }
        }

        &:not(:disabled):focus:not(:focus-visible) {
          outline: none;
        }
      }
    }
  }
}
