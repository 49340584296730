////
/// wonderscore Reset
/// @group Core
////

@import '../vendor/normalize-scss/normalize';
@import 'settings';
@import 'accessibility';


@mixin wonderscore-reset() {
  @include normalize;
  @include wonderscore-accessibility;

  html {
    box-sizing: border-box;
  }

  body {
    position: relative;
    min-height: 100vh;
  }

  main {
    flex-grow: 1;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: 400;
  }

  ol,
  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button {
    font-family: inherit;
  }

  abbr[title] {
    cursor: help;
  }
}
