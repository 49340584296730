.euiComboBox__input {
  max-width: 100%;

  // Ensure that no input states are visible on the hidden input
  input {
    // sass-lint:disable-block no-important
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
