.euiComboBoxOption {
  font-size: $euiFontSizeS;
  padding: $euiSizeXS $euiSizeS $euiSizeXS #{$euiSizeM + $euiSizeXS};
  width: 100%;
  text-align: left;
  border: $euiBorderThin;
  border-color: transparent;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }

  &.euiComboBoxOption-isFocused {
    cursor: pointer;
    color: $euiColorPrimaryText;
    background-color: $euiFocusBackgroundColor;
  }

  &.euiComboBoxOption-isDisabled {
    color: $euiColorMediumShade;
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
}

.euiComboBoxOption__contentWrapper {
  display: flex;

  .euiComboBoxOption__emptyStateText {
    flex: 1;
    text-align: left;
    margin-bottom: 0;
  }

  .euiComboBoxOption__enterBadge {
    align-self: center;
    margin-left: $euiSizeXS;
  }
}

.euiComboBoxOption__content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  text-align: left;
}
