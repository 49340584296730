.euiSuperSelect__listbox {
  @include euiScrollBar;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.euiSuperSelect__item {
  @include euiFontSizeS;
  @include euiInteractiveStates;
  padding: $euiSizeS;
}

.euiSuperSelect__item--hasDividers:not(:last-of-type) {
  border-bottom: $euiBorderThin;
}
