@use 'sass:math';


// sass-lint:disable-block function-name-format

.euiSuperDatePicker__flexWrapper {
  // Need to offset 8px because of negative margins added by small size gutter
  max-width: calc(100% + #{$euiSizeS});
  // Set a sensible min-width for when width is auto
  min-width: MIN(math.div($euiFormMaxWidth, 2) + $euiSuperDatePickerButtonWidth + $euiSizeS, 100%);
  width: $euiSuperDatePickerWidth + $euiSuperDatePickerButtonWidth + $euiSizeS;

  &.euiSuperDatePicker__flexWrapper--fullWidth {
    // Need to offset 8px because of negative margins added by small size gutter
    width: calc(100% + #{$euiSizeS});
  }

  &.euiSuperDatePicker__flexWrapper--isQuickSelectOnly {
    min-width: 0;
  }

  &.euiSuperDatePicker__flexWrapper--autoWidth {
    display: inline-flex;
    width: auto;
  }
}

.euiSuperDatePicker__flexWrapper--isAutoRefreshOnly {
  min-width: MIN(math.div($euiFormMaxWidth, 2), 100%);
  width: $euiFormMaxWidth;
}

.euiSuperDatePicker__flexWrapper--noUpdateButton {
  min-width: MIN(math.div($euiFormMaxWidth, 2), 100%);
  width: $euiSuperDatePickerWidth;
}


.euiSuperDatePicker {
  // sass-lint:disable-block no-important
  // Allow it to always grow to fit the container since the default form max width is too small
  max-width: 100% !important;

  > .euiFormControlLayout__childrenWrapper {
    flex: 1 1 100%;
    overflow: hidden;

    > .euiDatePickerRange {
      max-width: none;
      width: auto;
      border-radius: 0 $euiFormControlBorderRadius $euiFormControlBorderRadius 0;
    }

    &:not(:last-child) > .euiDatePickerRange {
      &,
      .euiDatePopoverButton--end,
      .euiSuperDatePicker__prettyFormat {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.euiSuperDatePicker__prettyFormat {
  @include euiSuperDatePickerText;
  display: flex;
  justify-content: space-between;
  text-align: left;

  &:not(:disabled):hover,
  &:focus {
    text-decoration: none;
  }

  &:disabled {
    background-color: $euiFormBackgroundDisabledColor;
    color: $euiColorDarkShade;
    cursor: not-allowed;
  }
}

@include euiBreakpoint('xs', 's') {
  .euiSuperDatePicker__flexWrapper {
    width: calc(100% + #{$euiSizeS});
  }
}
