.euiContextMenuPanel {
  width: 100%;
  visibility: visible;
  outline-offset: -$euiFocusRingSize;

  &:focus {
    outline: none; // Hide focus ring because of `tabindex=-1` on Safari
  }

  &.euiContextMenuPanel-txInLeft {
    pointer-events: none;
    animation: euiContextMenuPanelTxInLeft $euiAnimSpeedNormal $euiAnimSlightResistance;
  }

  &.euiContextMenuPanel-txOutLeft {
    pointer-events: none;
    animation: euiContextMenuPanelTxOutLeft $euiAnimSpeedNormal $euiAnimSlightResistance;
  }

  &.euiContextMenuPanel-txInRight {
    pointer-events: none;
    animation: euiContextMenuPanelTxInRight $euiAnimSpeedNormal $euiAnimSlightResistance;
  }

  &.euiContextMenuPanel-txOutRight {
    pointer-events: none;
    animation: euiContextMenuPanelTxOutRight $euiAnimSpeedNormal $euiAnimSlightResistance;
  }
}

.euiContextMenuPanel--next {
  transform: translateX($euiContextMenuWidth);
  visibility: hidden;
}

.euiContextMenuPanel--previous {
  transform: translateX(-$euiContextMenuWidth);
  visibility: hidden;
}

.euiContextMenuPanelTitle {
  @include euiTitle('xxs');
  padding: $euiSizeM;
  width: 100%;
  text-align: left;
  outline-offset: -$euiFocusRingSize;
  border-bottom: $euiBorderThin;

  &:enabled:hover,
  &:enabled:focus {
    text-decoration: underline;
  }

  &--small {
    padding: ($euiSizeS * .75) $euiSizeS;
  }
}

@keyframes euiContextMenuPanelTxInLeft {
  0% {
    transform: translateX($euiContextMenuWidth);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes euiContextMenuPanelTxOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-$euiContextMenuWidth);
  }
}

@keyframes euiContextMenuPanelTxInRight {
  0% {
    transform: translateX(-$euiContextMenuWidth);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes euiContextMenuPanelTxOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX($euiContextMenuWidth);
  }
}
