// Temporary style override for inserting search template into dark header
// These selectors aren't targetable via EuiFieldSearch
.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout {
  background-color: transparent;

  &--group,
  input {
    box-shadow: inset 0 0 0 1px transparentize($euiColorGhost, .7);
  }

  &:not(:focus-within) {
    // Increase contrast of filled text to be more than placeholder text
    color: $euiColorGhost;

    input {
      // Increase contrast of placeholder text
      @include euiPlaceholderPerBrowser {
        color: makeHighContrastColor($euiFormControlPlaceholderText, $euiHeaderDarkBackgroundColor, 8);
      }

      // Inherit color from form control layout
      color: inherit;
      background-color: transparent;
    }

    .euiFormControlLayout__append {
      background-color: transparent;
      color: inherit;
    }
  }
}
