@mixin euiPanel($selector) {
  @if variable-exists(selector) == false {
    @error 'A $selector must be provided to @mixin euiPanel().';
  } @else {
    #{$selector} {
      flex-grow: 1;

      &#{$selector}--flexGrowZero {
        flex-grow: 0;
      }

      &#{$selector}--hasShadow {
        @include euiBottomShadowMedium;
      }

      &#{$selector}--hasBorder {
        border: $euiBorderThin;
        box-shadow: none;
      }

      &#{$selector}--isClickable {
        // transition the shadow
        transition: all $euiAnimSpeedFast $euiAnimSlightResistance;

        &:enabled { // This is a good selector for buttons since it doesn't exist on divs
          // in case of button wrapper which inherently is inline-block and no width
          display: block;
          width: 100%;
          text-align: left;
        }

        &:hover,
        &:focus {
          @include euiBottomShadow;
          transform: translateY(-2px);
          cursor: pointer;
        }
      }

      // Border Radii
      @each $modifier, $amount in $euiPanelBorderRadiusModifiers {
        &#{$selector}--#{$modifier} {
          border-radius: $amount;
        }
      }

      // Background colors
      @each $modifier, $amount in $euiPanelBackgroundColorModifiers {
        &#{$selector}--#{$modifier} {
          background-color: $amount;
        }
      }
    }
  }
}
