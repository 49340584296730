.euiTextArea {
  @include euiFormControlStyle;
  line-height: $euiLineHeight; // give more spacing between multiple lines

  // Textareas have their own sizing
  &,
  &--compressed {
    height: auto;
  }
}

// resize modifiers
$textareaResizing: (
  vertical: 'resizeVertical',
  horizontal: 'resizeHorizontal',
  both: 'resizeBoth',
  none: 'resizeNone',
);

// Create button modifiers based upon the map.
@each $direction, $modifier in $textareaResizing {
  .euiTextArea--#{$modifier} {
    resize: #{$direction};
  }
}
