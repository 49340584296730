@use 'sass:math';


// This file has lots of modifiers and is somewhat nesty by nature
// sass-lint:disable nesting-depth

.euiButtonIcon {
  @include euiButton;

  border-radius: $euiBorderRadius;
  width: $euiButtonHeight;

  // Ensures center alignment of any sized icon inside buttons and anchors
  display: inline-flex;
  align-items: center;
  justify-content: space-around;

  & > svg {
    // prevents the element and its children from receiving any pointer events to fix not bubbling click event in Safari
    // https://stackoverflow.com/questions/24078524/svg-click-events-not-firing-bubbling-when-using-use-element
    pointer-events: none;
  }

  &:disabled {
    @include euiButtonContentDisabled;
  }
}

.euiButtonIcon--xSmall {
  height: $euiButtonHeightXSmall;
  width: $euiButtonHeightXSmall;
  border-radius: $euiBorderRadius * math.div(2, 3);
}

.euiButtonIcon--small {
  height: $euiButtonHeightSmall;
  width: $euiButtonHeightSmall;
  border-radius: $euiBorderRadius * math.div(2, 3);
}
