.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  @include datePickerArrow;
  cursor: pointer;
  position: absolute;
  top: $euiDatePickerGap * 2;
  height: $euiDatePickerButtonSize;
  width: $euiDatePickerButtonSize;
  z-index: 1;
  text-indent: -999em;
  overflow: hidden;
  background-repeat: no-repeat;
  border-radius: $euiBorderRadiusSmall;
  background-position: center;

  &:hover,
  &:focus {
    background-color: $euiFocusBackgroundColor;
  }
}

.react-datepicker__navigation--previous {
  left: $euiDatePickerGap * 2;
  transform: rotate(180deg);
  transform-origin: center;
}

.react-datepicker__navigation--next {
  right: $euiDatePickerGap * 2;
}
