////
/// wonderscore Global
/// @group Core
////

@import 'settings';
@import 'branding';
@import '../utility/breakpoints';


@mixin wonderscore-global(
  $scrollbar-thumb-color: $wonderscore-color-blue-martina,
  $important: null
) {
  @include wonderscore-branding__text-selection-highlight;

  html {
    font-size: 16px !important;
    background: $wonderscore-color-white;

    // custom scrollbars
    ::-webkit-scrollbar {
      background-color: $euiColorEmptyShade;
      width: $wonderscore-scrollbar-width;
    }

    ::-webkit-scrollbar-track {
      background-color: $euiColorEmptyShade;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb-color $important;
      border-radius: $wonderscore-scrollbar-width;
      border: 5px solid $euiColorEmptyShade;

      &:hover {
        background-color: $scrollbar-thumb-color $important;
      }
    }

    ::-webkit-scrollbar-button {
      display: none;
    }


    // TODO: implement better decoupled way of doing this?
    // page-specific scrollbars
    &.p-presentation-viewer {
      ::-webkit-scrollbar {
        background-color: $wonderscore-color-white;
      }

      ::-webkit-scrollbar-track {
        background-color: $wonderscore-color-white;
      }

      ::-webkit-scrollbar-thumb {
        background-color: $euiColorEmptyShade $important;
        border: 5px solid $wonderscore-color-white;

        &:hover {
          background-color: $euiColorEmptyShade $important;
        }
      }
    }


    // section-specific scrollbars
    &[data-scrollbar="footer"] {
      ::-webkit-scrollbar {
        background-color: $wonderscore-color-charcoal;
      }

      ::-webkit-scrollbar-thumb {
        background-color: $wonderscore-color-charcoal $important;

        &:hover {
          background-color: $wonderscore-color-charcoal $important;
        }
      }
    }
  }

  body {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    font-family: $font--axiforma;
    font-weight: 400;
    background-color: $euiColorEmptyShade;
    color: $wonderscore-color-moon-lily;
  }

  // page transitions
  .app {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100vh;

    // page transitions
    &-enter {
      .c-layout {
        opacity: 0;
      }

      &-active {
        .c-layout {
          opacity: 1;

          transition: opacity $wonderscore-animation-duration--short $wonderscore-animation-curve,
            transform $wonderscore-animation-duration--short $wonderscore-animation-curve;
        }
      }
    }

    &-exit {
      .c-layout {
        opacity: 1;
      }

      &-active {
        .c-layout {
          opacity: 0;

          transition: opacity $wonderscore-animation-duration--short $wonderscore-animation-curve,
            transform $wonderscore-animation-duration--short $wonderscore-animation-curve;
        }
      }
    }
  }

  // page-specific global styling
  html {
    &.p-presentation-viewer {
      body {
        background-color: $wonderscore-color-white;
      }
    }
  }
}


@mixin wonderscore-responsive-container() {
  padding-left: $wonderscore-container-padding;
  padding-right: $wonderscore-container-padding;

  @include for-desktop-up {
    width: 1000px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}
