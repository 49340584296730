// Animations as utility so they don't get duplicated in compiled CSS

@keyframes euiAnimFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes euiGrow {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes focusRingAnimate {
  0% {
    box-shadow: 0 0 0 $euiFocusRingAnimStartSize $euiFocusRingAnimStartColor;
  }

  100% {
    box-shadow: 0 0 0 $euiFocusRingSize $euiFocusRingColor;
  }
}

@keyframes focusRingAnimateLarge {
  0% {
    box-shadow: 0 0 0 $euiFocusRingAnimStartSizeLarge $euiFocusRingAnimStartColor;
  }

  100% {
    box-shadow: 0 0 0 $euiFocusRingSizeLarge $euiFocusRingColor;
  }
}

// Component specific

@keyframes euiButtonActive {
  50% {
    transform: translateY(1px);
  }
}
