@font-face {
  font-family: Axiforma;
  src: url("A-R.b1960054.eot");
  src: url("A-R.b1960054.eot#iefix") format("embedded-opentype"), url("A-R.dcdb338b.woff2") format("woff2"), url("A-R.cde0e50d.woff") format("woff"), url("A-R.722c3afc.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Axiforma;
  src: url("A-B.cf773340.eot");
  src: url("A-B.cf773340.eot#iefix") format("embedded-opentype"), url("A-B.9e08d428.woff2") format("woff2"), url("A-B.c4c8f9f2.woff") format("woff"), url("A-B.395938e5.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@keyframes euiAnimFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes euiGrow {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes focusRingAnimate {
  0% {
    box-shadow: 0 0 0 2px #19c9c800;
  }

  100% {
    box-shadow: 0 0 0 2px #19c9c8;
  }
}

@keyframes focusRingAnimateLarge {
  0% {
    box-shadow: 0 0 0 10px #19c9c800;
  }

  100% {
    box-shadow: 0 0 0 4px #19c9c8;
  }
}

@keyframes euiButtonActive {
  50% {
    transform: translateY(1px);
  }
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
}

.react-datepicker__day-name, .react-datepicker__day {
  text-align: center;
  color: #dfe5ef;
  border-radius: 4px;
  width: 32px;
  margin: 2px;
  font-weight: 500;
  line-height: 32px;
  display: inline-block;
}

.react-datepicker__day-name {
  color: #81858f;
}

.react-datepicker__day {
  cursor: pointer;
  transition: transform 90ms ease-in-out;
}

.react-datepicker__day:hover {
  color: #19c9c8;
  background-color: #19c9c833;
  text-decoration: underline;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .react-datepicker__day:hover {
    transform: scale(1.1);
  }
}

.react-datepicker__day--today {
  color: #19c9c8;
  font-weight: 700;
}

.react-datepicker__day--outside-month {
  color: #81858f;
}

.react-datepicker__day--highlighted, .react-datepicker__day--highlighted:hover {
  color: #7de2d1;
  background-color: #7de2d133;
}

.react-datepicker__day--in-range, .react-datepicker__day--in-range:hover {
  color: #19c9c8;
  background-color: #19c9c833;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected):not(:hover), .react-datepicker__day--in-range.react-datepicker__day--disabled:not(.react-datepicker__day--selected), .react-datepicker__day--in-range.react-datepicker__day--disabled:not(.react-datepicker__day--selected):hover {
  border-radius: 0;
  box-shadow: -2px 0 #19c9c833, 2px 0 #19c9c833;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected):not(:hover):first-of-type, .react-datepicker__day--in-range.react-datepicker__day--disabled:not(.react-datepicker__day--selected):first-of-type, .react-datepicker__day--in-range.react-datepicker__day--disabled:not(.react-datepicker__day--selected):hover:first-of-type {
  box-shadow: 2px 0 #19c9c833;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected):not(:hover):last-of-type, .react-datepicker__day--in-range.react-datepicker__day--disabled:not(.react-datepicker__day--selected):last-of-type, .react-datepicker__day--in-range.react-datepicker__day--disabled:not(.react-datepicker__day--selected):hover:last-of-type {
  box-shadow: -2px 0 #19c9c833;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover {
  color: #000;
  background-color: #19c9c8;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: #ff666680;
}

.react-datepicker__day--disabled, .react-datepicker__day--disabled:hover {
  color: #515761;
  cursor: not-allowed;
  background-color: #5157611a;
  text-decoration: none;
  transform: none;
}

.react-datepicker__day--disabled.react-datepicker__day--in-range:not(.react-datepicker__day--selected), .react-datepicker__day--disabled.react-datepicker__day--in-range:not(.react-datepicker__day--selected):hover, .react-datepicker__day--disabled:hover.react-datepicker__day--in-range:not(.react-datepicker__day--selected), .react-datepicker__day--disabled:hover.react-datepicker__day--in-range:not(.react-datepicker__day--selected):hover {
  color: #567174;
  background-color: #19c9c833;
}

.react-datepicker__day--disabled.react-datepicker__day--selected, .react-datepicker__day--disabled.react-datepicker__day--selected:hover {
  color: #f66;
  background-color: #f663;
}

.react-datepicker__header--time {
  display: none;
}

.react-datepicker__time-container {
  background-color: #1a1b20;
  border-left: #343741;
  border-radius: 0;
  flex-grow: 1;
  width: auto;
  margin-top: 40px;
  margin-left: 8px;
  display: flex;
}

.react-datepicker__time-container--focus .react-datepicker__time-list-item--preselected {
  text-decoration: underline;
}

@media only screen and (width <= 574px) {
  .react-datepicker__time-container {
    margin-top: 0;
  }
}

.react-datepicker__time, .react-datepicker__time-box {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.react-datepicker__time-list {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  gap: 4px;
  height: 100%;
  padding: 4px 12px;
  display: flex;
  overflow: hidden auto;
  height: 100px !important;
}

.react-datepicker__time-list::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.react-datepicker__time-list::-webkit-scrollbar-corner {
  background-color: #0000;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background-color: #0000;
}

.react-datepicker__time-list:focus {
  outline: none;
}

.react-datepicker__time-list[tabindex="0"]:focus:focus-visible {
  outline-style: auto;
}

.react-datepicker__time-list-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  outline-offset: -1px;
  border-radius: 4px;
  max-width: 100%;
  height: 24px;
  padding: 0 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition-duration: .15s;
  transition-timing-function: ease-in;
  display: inline-block;
  animation: none !important;
  transform: none !important;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .react-datepicker__time-list-item {
    transition: transform .25s ease-in-out, background-color .25s ease-in-out;
  }

  .react-datepicker__time-list-item:hover:not(:disabled) {
    transform: translateY(-1px);
  }

  .react-datepicker__time-list-item:focus {
    animation: .25s cubic-bezier(.34, 1.61, .7, 1) euiButtonActive;
  }

  .react-datepicker__time-list-item:active:not(:disabled) {
    transform: translateY(1px);
  }
}

.react-datepicker__time-list-item:hover:not(:disabled), .react-datepicker__time-list-item:focus {
  text-decoration: underline;
}

.react-datepicker__time-list-item--disabled, .react-datepicker__time-list-item--disabled:hover {
  cursor: not-allowed;
  color: #515761;
  background-color: #0000;
  text-decoration: none !important;
}

.react-datepicker__time-list-item--injected, .react-datepicker__time-list-item--injected:hover {
  color: #7de2d1;
  background-color: #7de2d133;
}

.react-datepicker__time-list-item--preselected, .react-datepicker__time-list-item--preselected:hover {
  background: #19c9c833;
}

.react-datepicker__time-list-item--selected, .react-datepicker__time-list-item--selected:hover {
  color: #000;
  background-color: #19c9c8;
}

.react-datepicker--time-only {
  padding: 0 !important;
}

.react-datepicker--time-only .react-datepicker__time-container {
  background-color: #0000;
  margin: 0;
}

.react-datepicker--time-only .react-datepicker__time-list {
  height: 204px !important;
}

.react-datepicker--time-only .react-datepicker__time-list-item {
  text-align: left;
  min-width: 112px;
  font-size: 14px;
}

.react-datepicker__header__dropdown {
  justify-content: center;
  gap: 4px;
  padding: 8px;
  display: flex;
  position: absolute;
  top: 0;
  left: 36px;
  right: 36px;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 4px;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 32px;
  padding: 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 500;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
    transition-property: box-shadow, background-image, background-size;
  }
}

.react-datepicker__year-read-view::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-read-view::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-year-read-view::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__year-read-view::-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-read-view::-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-year-read-view::-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__year-read-view:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-read-view:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-year-read-view:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__year-read-view:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-read-view:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__month-year-read-view:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.react-datepicker__year-read-view::placeholder, .react-datepicker__month-read-view::placeholder, .react-datepicker__month-year-read-view::placeholder {
  color: #81858f;
  opacity: 1;
}

@supports ((-moz-appearance: none)) {
  .react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
    transition-property: box-shadow, background-image, background-size;
  }
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
  text-decoration: underline;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8ZGVmcz4gICAgPHBhdGggaWQ9ImRvd25hcnJvd3doaXRlLWEiIGQ9Ik0xMy4wNjg4NTA4LDUuMTU3MjUwMzggTDguMzg0MjM5NzUsOS43NjgyNzQyOCBDOC4xNzA1NDQxNSw5Ljk3ODYxMzA4IDcuODI5OTkyMTQsOS45NzkxNDA5NSA3LjYxNTc2MDI1LDkuNzY4Mjc0MjggTDIuOTMxMTQ5MTUsNS4xNTcyNTAzOCBDMi43MTgxMzU5LDQuOTQ3NTgzMjEgMi4zNzI3NzMxOSw0Ljk0NzU4MzIxIDIuMTU5NzU5OTQsNS4xNTcyNTAzOCBDMS45NDY3NDY2OSw1LjM2NjkxNzU2IDEuOTQ2NzQ2NjksNS43MDY4NTUyMiAyLjE1OTc1OTk0LDUuOTE2NTIyNCBMNi44NDQzNzEwNCwxMC41Mjc1NDYzIEM3LjQ4NTE3NDI0LDExLjE1ODI4MzYgOC41MTY0NDk3OSwxMS4xNTY2ODUxIDkuMTU1NjI4OTYsMTAuNTI3NTQ2MyBMMTMuODQwMjQwMSw1LjkxNjUyMjQgQzE0LjA1MzI1MzMsNS43MDY4NTUyMiAxNC4wNTMyNTMzLDUuMzY2OTE3NTYgMTMuODQwMjQwMSw1LjE1NzI1MDM4IEMxMy42MjcyMjY4LDQuOTQ3NTgzMjEgMTMuMjgxODY0MSw0Ljk0NzU4MzIxIDEzLjA2ODg1MDgsNS4xNTcyNTAzOCBaIi8+ICA8L2RlZnM+ICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8dXNlIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2Rvd25hcnJvd3doaXRlLWEiLz4gIDwvZz48L3N2Zz4=");
  background-position: center;
  background-size: cover;
  width: 12px;
  height: 12px;
  display: inline-block;
  right: 8px;
}

.react-datepicker__year-read-view--selected-month, .react-datepicker__year-read-view--selected-year, .react-datepicker__month-read-view--selected-month, .react-datepicker__month-read-view--selected-year, .react-datepicker__month-year-read-view--selected-month, .react-datepicker__month-year-read-view--selected-year {
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.react-datepicker__year-dropdown-container, .react-datepicker__month-dropdown-container {
  flex-grow: 1;
  max-width: 400px;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"]), .react-datepicker__month-dropdown {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  z-index: 1;
  background-color: #1d1e24;
  border-radius: 0;
  width: 100%;
  min-width: 100px;
  height: auto;
  max-height: 250px;
  position: absolute;
  overflow: hidden auto;
  box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"])::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.react-datepicker__month-dropdown::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"])::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.react-datepicker__month-dropdown::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"])::-webkit-scrollbar-corner {
  background-color: #0000;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"])::-webkit-scrollbar-track {
  background-color: #0000;
}

.react-datepicker__month-dropdown::-webkit-scrollbar-corner {
  background-color: #0000;
}

.react-datepicker__month-dropdown::-webkit-scrollbar-track {
  background-color: #0000;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"]):focus, .react-datepicker__month-dropdown:focus {
  outline: none;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"])[tabindex="0"]:focus:focus-visible, .react-datepicker__month-dropdown[tabindex="0"]:focus:focus-visible {
  outline-style: auto;
}

.react-datepicker__year-dropdown-container > div:not([class*="read-view"]) .react-datepicker__year-dropdown {
  flex-direction: column-reverse;
  justify-content: flex-end;
  padding: 4px;
  display: flex;
}

.react-datepicker__month-dropdown-container {
  flex-grow: 2;
}

.react-datepicker__month-dropdown-container .react-datepicker__month-dropdown {
  min-width: 140px;
  padding: 4px;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  cursor: pointer;
  border-radius: 4px;
  height: 24px;
  margin: 4px 0;
  padding: 0 8px;
  font-size: 14px;
  line-height: 24px;
}

.react-datepicker__year-option:hover, .react-datepicker__year-option:focus, .react-datepicker__month-option:hover, .react-datepicker__month-option:focus, .react-datepicker__month-year-option:hover, .react-datepicker__month-year-option:focus {
  text-decoration: underline;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  display: none;
}

.react-datepicker__year-option--preselected, .react-datepicker__year-option--preselected:hover, .react-datepicker__month-option--preselected, .react-datepicker__month-option--preselected:hover {
  background: #19c9c833;
}

.react-datepicker__year-option--selected_year, .react-datepicker__year-option--selected_year:hover, .react-datepicker__month-option--selected_month, .react-datepicker__month-option--selected_month:hover {
  color: #000;
  background-color: #19c9c8;
}

.react-datepicker__navigation--next, .react-datepicker__navigation--previous {
  cursor: pointer;
  z-index: 1;
  text-indent: -999em;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTExLjY5MiA3SDMuNTU2QzMuMjQ5IDcgMyA3LjIyNCAzIDcuNXMuMjQ5LjUuNTU2LjVoOC4xMzZsLTQuMDk2IDQuMDk2YS41LjUgMCAwMC43MDcuNzA3bDQuMjQzLTQuMjQyYy4yNTgtLjI1OS40MDMtLjU4Ny40MzMtLjkyNWEuNDU0LjQ1NCAwIDAwMC0uMjcyIDEuNDk0IDEuNDk0IDAgMDAtLjQzMy0uOTI1TDguMzAzIDIuMTk3YS41LjUgMCAxMC0uNzA3LjcwN0wxMS42OTIgN3oiLz48L3N2Zz4=");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 8px;
  overflow: hidden;
}

.react-datepicker__navigation--next:hover, .react-datepicker__navigation--next:focus, .react-datepicker__navigation--previous:hover, .react-datepicker__navigation--previous:focus {
  background-color: #19c9c833;
}

.react-datepicker__navigation--previous {
  transform-origin: center;
  left: 8px;
  transform: rotate(180deg);
}

.react-datepicker__navigation--next {
  right: 8px;
}

.euiDatePicker {
  display: block;
}

.euiDatePicker .euiFormControlLayout {
  height: auto;
}

.euiDatePicker.euiDatePicker--inline .euiFormControlLayout {
  width: auto;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper, .euiDatePicker.euiDatePicker--shadow.euiDatePicker--inline .react-datepicker {
  background-color: #1d1e24;
  border: 1px solid #343741;
  border-radius: 0;
  box-shadow: 0 .9px 4px -1px #0003, 0 2.6px 8px -1px #00000026, 0 5.7px 12px -1px #00000020, 0 15px 15px -1px #0000001a;
}

.react-datepicker {
  color: #dfe5ef;
  border-radius: 0;
  justify-content: center;
  padding: 8px;
  font-size: 12px;
  display: flex;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper {
  z-index: 2000;
  animation: .15s ease-in euiAnimFadeIn;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^="top"] {
  border-radius: 0;
  box-shadow: 0 0 .8px #00000026, 0 0 2px #0000001a, 0 0 5px #0000001a, 0 0 17px #00000013;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^="right"] {
  margin-left: 0;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^="left"] {
  margin-right: 0;
}

.react-datepicker__current-month, .react-datepicker-time__header {
  display: none;
}

.react-datepicker__screenReaderOnly {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
}

.react-datepicker__focusTrap {
  justify-content: center;
  display: flex;
}

.react-datepicker__month-container {
  flex-grow: 1;
  margin-top: 36px;
}

.react-datepicker__header {
  flex-direction: column;
  display: flex;
}

.react-datepicker-time__header {
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.react-datepicker__month {
  text-align: center;
  border-radius: 0;
}

@media only screen and (width <= 574px) {
  .react-datepicker__focusTrap, .react-datepicker {
    flex-direction: column;
  }

  .react-datepicker__time-box {
    margin-top: 0;
  }
}

.euiBasicTable-loading {
  position: relative;
}

.euiBasicTable-loading tbody {
  overflow: hidden;
}

.euiBasicTable-loading tbody:before {
  content: "";
  background-color: #19c9c8;
  width: 100%;
  height: 2px;
  animation: 1s linear infinite euiBasicTableLoading;
  position: absolute;
}

@keyframes euiBasicTableLoading {
  from {
    width: 0;
    left: 0;
  }

  20% {
    width: 40%;
    left: 0;
  }

  80% {
    width: 40%;
    left: 60%;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

.euiButtonContent {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.euiButtonContent .euiButtonContent__icon, .euiButtonContent .euiButtonContent__spinner {
  flex-shrink: 0;
}

.euiButtonContent > * + * {
  margin-inline-start: 8px;
}

.euiButtonContent--iconRight {
  vertical-align: middle;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
}

.euiButtonContent--iconRight .euiButtonContent__icon, .euiButtonContent--iconRight .euiButtonContent__spinner {
  flex-shrink: 0;
}

.euiButtonContent--iconRight > * + * {
  margin-inline: 0 8px;
}

.euiButtonEmpty {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  outline-offset: -1px;
  border-radius: 0;
  max-width: 100%;
  height: 40px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.71429rem;
  text-decoration: none;
  transition-duration: .15s;
  transition-timing-function: ease-in;
  display: inline-block;
  animation: none !important;
  transform: none !important;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .euiButtonEmpty {
    transition: transform .25s ease-in-out, background-color .25s ease-in-out;
  }

  .euiButtonEmpty:hover:not(:disabled) {
    transform: translateY(-1px);
  }

  .euiButtonEmpty:focus {
    animation: .25s cubic-bezier(.34, 1.61, .7, 1) euiButtonActive;
  }

  .euiButtonEmpty:active:not(:disabled) {
    transform: translateY(1px);
  }
}

.euiButtonEmpty:hover:not(:disabled), .euiButtonEmpty:focus {
  text-decoration: underline;
}

.euiButtonEmpty .euiButtonEmpty__content {
  padding: 0 8px;
}

.euiButtonEmpty .euiButtonEmpty__text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.euiButtonEmpty.euiButtonEmpty--small {
  border-radius: 0;
  height: 32px;
}

.euiButtonEmpty.euiButtonEmpty--xSmall {
  border-radius: 0;
  height: 24px;
  font-size: 12px;
}

.euiButtonEmpty:disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.euiButtonEmpty:disabled:hover, .euiButtonEmpty:disabled:focus, .euiButtonEmpty:disabled:focus-within {
  text-decoration: none;
}

.euiButtonEmpty:disabled .euiButtonContent__spinner {
  border-color: #19c9c8 currentColor currentColor;
}

.euiButtonEmpty:disabled:hover, .euiButtonEmpty:disabled:focus {
  text-decoration: none;
}

.euiButtonEmpty--flushLeft .euiButtonEmpty__content, .euiButtonEmpty--flushRight .euiButtonEmpty__content, .euiButtonEmpty--flushBoth .euiButtonEmpty__content {
  padding-left: 0;
  padding-right: 0;
}

.euiButtonEmpty--flushLeft {
  margin-right: 8px;
}

.euiButtonEmpty--flushRight {
  margin-left: 8px;
}

.euiButtonIcon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  outline-offset: -1px;
  border-radius: 0;
  justify-content: space-around;
  align-items: center;
  width: 40px;
  max-width: 100%;
  height: 40px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.71429rem;
  text-decoration: none;
  display: inline-flex;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .euiButtonIcon {
    transition: transform .25s ease-in-out, background-color .25s ease-in-out;
  }

  .euiButtonIcon:hover:not(:disabled) {
    transform: translateY(-1px);
  }

  .euiButtonIcon:focus {
    animation: .25s cubic-bezier(.34, 1.61, .7, 1) euiButtonActive;
  }

  .euiButtonIcon:active:not(:disabled) {
    transform: translateY(1px);
  }
}

.euiButtonIcon:hover:not(:disabled), .euiButtonIcon:focus {
  text-decoration: underline;
}

.euiButtonIcon > svg {
  pointer-events: none;
}

.euiButtonIcon:disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.euiButtonIcon:disabled:hover, .euiButtonIcon:disabled:focus, .euiButtonIcon:disabled:focus-within {
  text-decoration: none;
}

.euiButtonIcon:disabled .euiButtonContent__spinner {
  border-color: #19c9c8 currentColor currentColor;
}

.euiButtonIcon--xSmall {
  border-radius: 0;
  width: 24px;
  height: 24px;
}

.euiButtonIcon--small {
  border-radius: 0;
  width: 32px;
  height: 32px;
}

.euiButtonGroup {
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.euiButtonGroup--fullWidth {
  display: block;
}

.euiButtonGroup--fullWidth .euiButtonGroup__buttons {
  width: 100%;
}

.euiButtonGroup--fullWidth .euiButtonGroup__buttons .euiButtonGroupButton {
  flex: 1;
}

.euiButtonGroup__buttons {
  border-radius: 1px;
  max-width: 100%;
  display: flex;
  overflow: hidden;
}

.euiButtonGroup--isDisabled .euiButtonGroup__buttons {
  box-shadow: none;
}

.euiButtonGroup--compressed .euiButtonGroup__buttons {
  background-color: #16171c;
  border: 1px solid #ffffff1a;
  border-radius: 4px;
  height: 32px;
  overflow: visible;
  box-shadow: none !important;
}

.euiButtonGroupButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
  max-width: 100%;
  height: 40px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.71429rem;
  transition: background-color .25s ease-in-out, color .25s ease-in-out;
  display: inline-block;
}

.euiButtonGroupButton .euiButton__content {
  padding: 0 12px;
}

.euiButtonGroupButton-isIconOnly .euiButton__content {
  padding: 0 8px;
}

.euiButtonGroupButton.euiButtonGroupButton--small {
  height: 32px;
  line-height: 32px;
}

.euiButtonGroupButton:not(:disabled):hover, .euiButtonGroupButton:not(:disabled):focus, .euiButtonGroupButton:not(:disabled):focus-within {
  text-decoration: underline;
}

.euiButtonGroupButton:disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.euiButtonGroupButton:disabled:hover, .euiButtonGroupButton:disabled:focus, .euiButtonGroupButton:disabled:focus-within {
  text-decoration: none;
}

.euiButtonGroupButton:disabled .euiButtonContent__spinner {
  border-color: #19c9c8 currentColor currentColor;
}

.euiButtonGroupButton:disabled.euiButtonGroupButton-isSelected {
  color: #858a91;
  background-color: #515761;
  border-color: #515761;
}

.euiButtonGroupButton__textShift:after {
  content: attr(data-text);
  visibility: hidden;
  height: 0;
  font-weight: 700;
  display: block;
  overflow: hidden;
}

.euiButtonGroup--medium .euiButtonGroupButton, .euiButtonGroup--small .euiButtonGroupButton {
  font-weight: 500;
  border-radius: 0 !important;
}

.euiButtonGroup--medium .euiButtonGroupButton:focus, .euiButtonGroup--medium .euiButtonGroupButton:focus-within, .euiButtonGroup--small .euiButtonGroupButton:focus, .euiButtonGroup--small .euiButtonGroupButton:focus-within {
  outline-offset: -2px;
  outline-style: solid;
  outline-color: #fff;
}

.euiButtonGroup--medium .euiButtonGroupButton:focus:focus-visible, .euiButtonGroup--medium .euiButtonGroupButton:focus-within:focus-visible, .euiButtonGroup--small .euiButtonGroupButton:focus:focus-visible, .euiButtonGroup--small .euiButtonGroupButton:focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--medium .euiButtonGroupButton:focus:not(:focus-visible), .euiButtonGroup--small .euiButtonGroupButton:focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--medium .euiButtonGroupButton:not(:first-child):not(.euiButtonGroupButton-isSelected):not(:disabled), .euiButtonGroup--small .euiButtonGroupButton:not(:first-child):not(.euiButtonGroupButton-isSelected):not(:disabled) {
  box-shadow: -1px 0 #0000000d;
}

.euiButtonGroup--medium .euiButtonGroupButton:first-child, .euiButtonGroup--small .euiButtonGroupButton:first-child, .euiButtonGroup--medium .euiButtonGroupButton:last-child, .euiButtonGroup--small .euiButtonGroupButton:last-child {
  border-radius: 0;
}

.euiButtonGroup--medium .euiButtonGroupButton-isSelected, .euiButtonGroup--small .euiButtonGroupButton-isSelected {
  z-index: 0;
}

.euiButtonGroup--medium .euiButtonGroupButton-isSelected + .euiButtonGroupButton-isSelected, .euiButtonGroup--small .euiButtonGroupButton-isSelected + .euiButtonGroupButton-isSelected {
  box-shadow: -1px 0 #1d1e241a;
}

.euiButtonGroup--small .euiButtonGroup__buttons {
  border-radius: 0;
}

.euiButtonGroup--compressed .euiButtonGroupButton {
  background-clip: content-box;
  border-radius: 5px;
  height: 30px;
  padding: 2px;
  font-size: 14px;
  line-height: 30px;
}

.euiButtonGroup--compressed .euiButtonGroupButton .euiButton__content {
  padding-left: 8px;
  padding-right: 8px;
}

.euiButtonGroup--compressed .euiButtonGroupButton.euiButtonGroupButton-isSelected {
  font-weight: 600;
}

.euiButtonGroup--compressed .euiButtonGroupButton:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton:not(:disabled):focus-within {
  outline: 2px solid;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-primary"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-primary"]:not(:disabled):focus-within {
  outline-color: #19c9c8;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-primary"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-primary"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-primary"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-accent"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-accent"]:not(:disabled):focus-within {
  outline-color: #f990c0;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-accent"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-accent"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-accent"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-success"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-success"]:not(:disabled):focus-within {
  outline-color: #7de2d1;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-success"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-success"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-success"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-warning"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-warning"]:not(:disabled):focus-within {
  outline-color: #ffce7a;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-warning"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-warning"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-warning"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-danger"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-danger"]:not(:disabled):focus-within {
  outline-color: #f66;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-danger"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-danger"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-danger"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-ghost"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-ghost"]:not(:disabled):focus-within {
  outline-color: #fff;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-ghost"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-ghost"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-ghost"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-text"]:not(:disabled):focus, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-text"]:not(:disabled):focus-within {
  outline-color: #98a2b3;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-text"]:not(:disabled):focus:focus-visible, .euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-text"]:not(:disabled):focus-within:focus-visible {
  outline-style: auto;
}

.euiButtonGroup--compressed .euiButtonGroupButton[class*="-fill-text"]:not(:disabled):focus:not(:focus-visible) {
  outline: none;
}

.euiComboBox {
  width: 100%;
  max-width: 400px;
  height: auto;
  position: relative;
}

.euiComboBox--fullWidth {
  max-width: 100%;
}

.euiComboBox--compressed {
  height: 32px;
}

.euiComboBox--inGroup {
  height: 100%;
}

.euiComboBox--compressed, .euiComboBox .euiFormControlLayout {
  height: auto;
}

.euiComboBox .euiComboBox__inputWrap {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 4px 40px 4px 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiComboBox .euiComboBox__inputWrap {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiComboBox .euiComboBox__inputWrap::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiComboBox .euiComboBox__inputWrap::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiComboBox .euiComboBox__inputWrap:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiComboBox .euiComboBox__inputWrap:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiComboBox .euiComboBox__inputWrap::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiComboBox .euiComboBox__inputWrap--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiComboBox .euiComboBox__inputWrap--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiComboBox .euiComboBox__inputWrap--inGroup {
  border-radius: 0;
  box-shadow: none !important;
}

.euiComboBox .euiComboBox__inputWrap--withIcon {
  padding-left: 40px;
}

.euiComboBox .euiComboBox__inputWrap--fullWidth {
  max-width: 100%;
}

.euiComboBox .euiComboBox__inputWrap--compressed {
  height: 32px;
}

.euiComboBox .euiComboBox__inputWrap--inGroup {
  height: 100%;
}

.euiComboBox .euiComboBox__inputWrap .euiComboBoxPill {
  max-width: calc(100% - 18px);
}

.euiComboBox .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap) {
  flex-wrap: wrap;
  align-content: flex-start;
  height: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
}

.euiComboBox .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap):hover {
  cursor: text;
}

.euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isClearable, .euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading, .euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading .euiComboBoxPlaceholder {
  padding-right: 62px;
}

.euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading.euiComboBox__inputWrap-isClearable {
  padding-right: 84px;
}

.euiComboBox .euiComboBox__input {
  height: 32px;
  overflow: hidden;
  display: inline-flex !important;
}

.euiComboBox .euiComboBox__input > input {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #dfe5ef;
  background: none;
  border: none;
  margin: 4px;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap, .euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap--compressed {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiComboBox.euiComboBox-isInvalid .euiComboBox__inputWrap {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  -webkit-text-fill-color: unset;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::placeholder {
  color: #535966;
  opacity: 1;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::placeholder {
  color: #535966;
  opacity: 1;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBoxPlaceholder, .euiComboBox.euiComboBox-isDisabled .euiComboBoxPill--plainText {
  color: #535966;
  -webkit-text-fill-color: #535966;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap):hover {
  cursor: not-allowed;
}

.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 32px;
  line-height: 32px;
}

.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isClearable, .euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading, .euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading .euiComboBoxPlaceholder {
  padding-right: 54px;
}

.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading.euiComboBox__inputWrap-isClearable {
  padding-right: 76px;
}

.euiComboBox .euiFormControlLayout__prepend, .euiComboBox .euiFormControlLayout__append {
  height: auto !important;
}

.euiComboBox__input {
  max-width: 100%;
}

.euiComboBox__input input {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.euiComboBoxPill {
  vertical-align: baseline;
  height: 22px;
  line-height: 22px;
}

.euiComboBoxPill, .euiComboBoxPill + .euiComboBoxPill {
  margin: 4px;
}

.euiComboBox--compressed .euiComboBoxPill, .euiComboBox--compressed .euiComboBoxPill + .euiComboBoxPill {
  margin: 5px 4px 0 0;
}

.euiComboBoxPill--plainText {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  vertical-align: middle;
  max-width: 100%;
  padding: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiComboBoxPlaceholder {
  pointer-events: none;
  color: #81858f;
  max-width: 100%;
  padding-left: 4px;
  padding-right: 40px;
  line-height: 32px;
  position: absolute;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  margin-bottom: 0 !important;
  overflow: hidden !important;
}

.euiComboBoxOptionsList {
  top: 0;
  transform: none !important;
}

.euiComboBoxOptionsList .euiFilterSelectItem__content {
  margin-block: 0 !important;
}

.euiComboBoxOptionsList__empty {
  word-break: break-word;
  text-align: center;
  word-wrap: break-word;
  padding: 8px;
  overflow-wrap: break-word !important;
}

.euiComboBoxOptionsList__rowWrap {
  max-height: 200px;
  padding: 0;
  overflow: hidden;
}

.euiComboBoxOptionsList__rowWrap > div {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiComboBoxOption {
  text-align: left;
  border: 1px solid #0000;
  align-items: center;
  width: 100%;
  padding: 4px 8px 4px 16px;
  font-size: 14px;
  display: flex;
}

.euiComboBoxOption:hover {
  text-decoration: underline;
}

.euiComboBoxOption.euiComboBoxOption-isFocused {
  cursor: pointer;
  color: #19c9c8;
  background-color: #19c9c833;
}

.euiComboBoxOption.euiComboBoxOption-isDisabled {
  color: #535966;
  cursor: not-allowed;
}

.euiComboBoxOption.euiComboBoxOption-isDisabled:hover {
  text-decoration: none;
}

.euiComboBoxOption__contentWrapper {
  display: flex;
}

.euiComboBoxOption__contentWrapper .euiComboBoxOption__emptyStateText {
  text-align: left;
  flex: 1;
  margin-bottom: 0;
}

.euiComboBoxOption__contentWrapper .euiComboBoxOption__enterBadge {
  align-self: center;
  margin-left: 4px;
}

.euiComboBoxOption__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  flex: 1;
  overflow: hidden;
}

.euiComboBoxTitle {
  color: #fff;
  width: 100%;
  padding: 11px 8px 4px;
  font-size: 12px;
  font-weight: 700;
}

.euiContextMenu {
  border-radius: 0;
  width: 256px;
  max-width: 100%;
  transition: height .15s cubic-bezier(.694, .0482, .335, 1);
  position: relative;
  overflow: hidden;
}

.euiContextMenu .euiContextMenu__content {
  padding: 8px;
}

.euiContextMenu__panel {
  position: absolute;
}

.euiContextMenu__icon {
  margin-right: 8px;
}

.euiContextMenuPanel {
  visibility: visible;
  outline-offset: -2px;
  width: 100%;
}

.euiContextMenuPanel:focus {
  outline: none;
}

.euiContextMenuPanel.euiContextMenuPanel-txInLeft {
  pointer-events: none;
  animation: .25s cubic-bezier(.694, .0482, .335, 1) euiContextMenuPanelTxInLeft;
}

.euiContextMenuPanel.euiContextMenuPanel-txOutLeft {
  pointer-events: none;
  animation: .25s cubic-bezier(.694, .0482, .335, 1) euiContextMenuPanelTxOutLeft;
}

.euiContextMenuPanel.euiContextMenuPanel-txInRight {
  pointer-events: none;
  animation: .25s cubic-bezier(.694, .0482, .335, 1) euiContextMenuPanelTxInRight;
}

.euiContextMenuPanel.euiContextMenuPanel-txOutRight {
  pointer-events: none;
  animation: .25s cubic-bezier(.694, .0482, .335, 1) euiContextMenuPanelTxOutRight;
}

.euiContextMenuPanel--next {
  visibility: hidden;
  transform: translateX(256px);
}

.euiContextMenuPanel--previous {
  visibility: hidden;
  transform: translateX(-256px);
}

.euiContextMenuPanelTitle {
  word-break: break-word;
  color: #dfe5ef;
  text-align: left;
  outline-offset: -2px;
  border-bottom: 1px solid #343741;
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.71429rem;
  overflow-wrap: break-word !important;
}

.euiContextMenuPanelTitle:enabled:hover, .euiContextMenuPanelTitle:enabled:focus {
  text-decoration: underline;
}

.euiContextMenuPanelTitle--small {
  padding: 6px 8px;
}

@keyframes euiContextMenuPanelTxInLeft {
  0% {
    transform: translateX(256px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes euiContextMenuPanelTxOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-256px);
  }
}

@keyframes euiContextMenuPanelTxInRight {
  0% {
    transform: translateX(-256px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes euiContextMenuPanelTxOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(256px);
  }
}

.euiContextMenuItem {
  text-align: left;
  color: #dfe5ef;
  outline-offset: -2px;
  width: 100%;
  padding: 12px;
  display: block;
}

.euiContextMenuItem:hover, .euiContextMenuItem:focus {
  text-decoration: underline;
}

.euiContextMenuItem:focus {
  background-color: #19c9c833;
}

.euiContextMenuItem.euiContextMenuItem-isDisabled {
  color: #515761;
  cursor: default;
}

.euiContextMenuItem.euiContextMenuItem-isDisabled:hover, .euiContextMenuItem.euiContextMenuItem-isDisabled:focus {
  text-decoration: none;
}

.euiContextMenuItem--small {
  padding: 6px 8px;
}

.euiContextMenuItem--small .euiContextMenuItem__text {
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiContextMenuItem__inner {
  display: flex;
}

.euiContextMenuItem__text {
  flex-grow: 1;
  overflow: hidden;
}

.euiContextMenuItem__arrow {
  align-self: flex-end;
}

.euiContextMenu__itemLayout {
  align-items: center;
  display: flex;
}

.euiContextMenu__itemLayout.euiContextMenu__itemLayout--bottom {
  align-items: flex-end;
}

.euiContextMenu__itemLayout.euiContextMenu__itemLayout--top {
  align-items: flex-start;
}

.euiContextMenu__itemLayout .euiContextMenu__icon {
  flex-shrink: 0;
}

.euiDatePickerRange {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 1px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

.euiDatePickerRange--fullWidth {
  max-width: 100%;
}

.euiDatePickerRange--compressed {
  height: 32px;
}

.euiDatePickerRange--inGroup {
  height: 100%;
}

@supports ((-moz-appearance: none)) {
  .euiDatePickerRange {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiDatePickerRange > * {
  flex-grow: 1;
}

.euiDatePickerRange .euiFieldText.euiDatePicker {
  text-align: center;
  height: 38px;
  box-shadow: none !important;
}

.euiDatePickerRange .euiDatePickerRange__start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.euiDatePickerRange .euiDatePickerRange__end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.euiDatePickerRange .react-datepicker-popper .euiFieldText.euiDatePicker {
  text-align: left;
}

.euiDatePickerRange--inGroup {
  box-shadow: none;
  padding: 0;
}

.euiDatePickerRange--isDisabled {
  background: #202128;
}

.euiDatePickerRange--readOnly {
  background: #1d1e24;
}

.euiDatePickerRange__delimeter {
  flex-grow: 0;
  align-self: stretch;
  align-items: center;
  height: auto;
  display: flex;
}

.euiDatePickerRange--isInvalid .euiDatePickerRange__delimeter {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiSuperDatePicker__absoluteDateFormRow {
  padding: 0 8px 8px;
}

.euiDatePopoverButton {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  word-break: break-all;
  background-size: 100%;
  width: 100%;
  height: 38px;
  padding: 0 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  transition: background .15s ease-in;
  display: block;
}

.euiDatePopoverButton::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiDatePopoverButton::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiDatePopoverButton:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiDatePopoverButton:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiDatePopoverButton::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiDatePopoverButton--compressed {
  height: 30px;
  line-height: 30px;
}

.euiDatePopoverButton:focus, .euiDatePopoverButton-isSelected {
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
}

.euiDatePopoverButton-needsUpdating {
  color: #7de2d1;
  background-color: #26443f;
}

.euiDatePopoverButton-needsUpdating:focus, .euiDatePopoverButton-needsUpdating.euiDatePopoverButton-isSelected {
  background-image: linear-gradient(to top, #7de2d1, #7de2d1 2px, #0000 2px 100%);
}

.euiDatePopoverButton-isInvalid {
  color: #ff7575;
  background-color: #0000;
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiDatePopoverButton-isInvalid:focus, .euiDatePopoverButton-isInvalid.euiDatePopoverButton-isSelected {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
}

.euiDatePopoverButton:disabled {
  color: #98a2b3;
  cursor: default;
  background-color: #202128;
}

.euiDatePopoverButton--end {
  text-align: left;
}

.euiDatePopoverContent {
  width: 400px;
  max-width: 100%;
}

.euiDatePopoverContent__padded {
  padding: 8px;
}

.euiDatePopoverContent__padded--large {
  padding: 16px;
}

@media only screen and (width <= 574px) {
  .euiDatePopoverContent {
    width: 284px;
  }
}

.euiQuickSelectPopover__content {
  width: 400px;
  max-width: 100%;
}

.euiQuickSelectPopover__section {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  max-height: 132px;
  padding: 8px 0 4px;
  overflow: hidden auto;
}

.euiQuickSelectPopover__section::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiQuickSelectPopover__section::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiQuickSelectPopover__section::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiQuickSelectPopover__section::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiQuickSelectPopover__buttonText {
  margin-right: 4px !important;
}

.euiQuickSelectPopover__anchor {
  height: 100%;
}

.euiQuickSelectPopover__sectionItem {
  font-size: 14px;
  line-height: 14px;
}

.euiQuickSelectPopover__sectionItem:not(:last-of-type) {
  margin-bottom: 8px;
}

.euiQuickSelect__applyButton {
  min-width: 0;
}

.euiRefreshInterval__startButton {
  min-width: 90px;
}

.euiSuperDatePicker__flexWrapper {
  width: 606px;
  min-width: min(326px, 100%);
  max-width: calc(100% + 8px);
}

.euiSuperDatePicker__flexWrapper.euiSuperDatePicker__flexWrapper--fullWidth {
  width: calc(100% + 8px);
}

.euiSuperDatePicker__flexWrapper.euiSuperDatePicker__flexWrapper--isQuickSelectOnly {
  min-width: 0;
}

.euiSuperDatePicker__flexWrapper.euiSuperDatePicker__flexWrapper--autoWidth {
  width: auto;
  display: inline-flex;
}

.euiSuperDatePicker__flexWrapper--isAutoRefreshOnly {
  width: 400px;
  min-width: min(200px, 100%);
}

.euiSuperDatePicker__flexWrapper--noUpdateButton {
  width: 480px;
  min-width: min(200px, 100%);
}

.euiSuperDatePicker {
  max-width: 100% !important;
}

.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper {
  flex: 100%;
  overflow: hidden;
}

.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper > .euiDatePickerRange {
  border-radius: 0;
  width: auto;
  max-width: none;
}

.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper:not(:last-child) > .euiDatePickerRange, .euiSuperDatePicker > .euiFormControlLayout__childrenWrapper:not(:last-child) > .euiDatePickerRange .euiDatePopoverButton--end, .euiSuperDatePicker > .euiFormControlLayout__childrenWrapper:not(:last-child) > .euiDatePickerRange .euiSuperDatePicker__prettyFormat {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.euiSuperDatePicker__prettyFormat {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  word-break: break-all;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  padding: 0 8px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  transition: background .15s ease-in;
  display: flex;
}

.euiSuperDatePicker__prettyFormat::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperDatePicker__prettyFormat::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperDatePicker__prettyFormat:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperDatePicker__prettyFormat:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperDatePicker__prettyFormat::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperDatePicker__prettyFormat:not(:disabled):hover, .euiSuperDatePicker__prettyFormat:focus {
  text-decoration: none;
}

.euiSuperDatePicker__prettyFormat:disabled {
  color: #98a2b3;
  cursor: not-allowed;
  background-color: #202128;
}

@media only screen and (width <= 574px) {
  .euiSuperDatePicker__flexWrapper {
    width: calc(100% + 8px);
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiSuperDatePicker__flexWrapper {
    width: calc(100% + 8px);
  }
}

.euiEmptyPrompt {
  text-align: center;
  margin: auto;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiEmptyPrompt {
    max-width: max-content;
  }
}

@media only screen and (width >= 1200px) {
  .euiEmptyPrompt {
    max-width: max-content;
  }
}

.euiEmptyPrompt .euiEmptyPrompt__icon > * {
  flex-shrink: 1;
  max-width: 25.7143rem;
}

.euiEmptyPrompt.euiPanel--transparent .euiEmptyPrompt__footer {
  background-color: #1a1b20;
}

.euiEmptyPrompt.euiPanel--transparent:not(.euiPanel--hasBorder) .euiEmptyPrompt__footer, .euiEmptyPrompt.euiPanel--transparent.euiPanel--hasBorder .euiEmptyPrompt__footer {
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--plain .euiEmptyPrompt__footer {
  background-color: #1a1b20;
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--subdued .euiEmptyPrompt__footer {
  border-top: 1px solid #343741;
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--accent .euiEmptyPrompt__footer {
  border-top: 1px solid #5e3649;
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--primary .euiEmptyPrompt__footer {
  border-top: 1px solid #0c5757;
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--success .euiEmptyPrompt__footer {
  border-top: 1px solid #315851;
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--warning .euiEmptyPrompt__footer {
  border-top: 1px solid #624f2f;
  border-radius: 0;
}

.euiEmptyPrompt.euiPanel--danger .euiEmptyPrompt__footer {
  border-top: 1px solid #632828;
  border-radius: 0;
}

.euiEmptyPrompt--vertical .euiEmptyPrompt__main {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.euiEmptyPrompt--vertical .euiEmptyPrompt__contentInner {
  max-width: 36em;
  margin: auto;
}

.euiEmptyPrompt--vertical .euiEmptyPrompt__icon {
  margin-bottom: 16px;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiEmptyPrompt--horizontal {
    text-align: left;
    justify-content: flex-start;
  }
}

@media only screen and (width >= 1200px) {
  .euiEmptyPrompt--horizontal {
    text-align: left;
    justify-content: flex-start;
  }
}

.euiEmptyPrompt--horizontal .euiEmptyPrompt__main {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__main {
    flex-direction: row-reverse;
  }
}

@media only screen and (width >= 1200px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__main {
    flex-direction: row-reverse;
  }
}

.euiEmptyPrompt--horizontal .euiEmptyPrompt__icon {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__icon {
    min-width: 40%;
    max-width: 50%;
  }
}

@media only screen and (width >= 1200px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__icon {
    min-width: 40%;
    max-width: 50%;
  }
}

.euiEmptyPrompt--horizontal .euiEmptyPrompt__content {
  max-width: 36em;
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__content {
    padding: 24px 0;
  }
}

@media only screen and (width >= 1200px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__content {
    padding: 24px 0;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__actions {
    justify-content: flex-start;
  }
}

@media only screen and (width >= 1200px) {
  .euiEmptyPrompt--horizontal .euiEmptyPrompt__actions {
    justify-content: flex-start;
  }
}

.euiEmptyPrompt--paddingSmall .euiEmptyPrompt__main, .euiEmptyPrompt--paddingSmall .euiEmptyPrompt__footer {
  padding: 8px;
}

.euiEmptyPrompt--paddingSmall.euiEmptyPrompt--horizontal .euiEmptyPrompt__main {
  gap: 8px;
}

.euiEmptyPrompt--paddingMedium .euiEmptyPrompt__main, .euiEmptyPrompt--paddingMedium .euiEmptyPrompt__footer {
  padding: 16px;
}

.euiEmptyPrompt--paddingMedium.euiEmptyPrompt--horizontal .euiEmptyPrompt__main {
  gap: 16px;
}

.euiEmptyPrompt--paddingLarge .euiEmptyPrompt__main, .euiEmptyPrompt--paddingLarge .euiEmptyPrompt__footer {
  padding: 24px;
}

.euiEmptyPrompt--paddingLarge.euiEmptyPrompt--horizontal .euiEmptyPrompt__main {
  gap: 24px;
}

.euiFilterGroup {
  border-right: 1px solid #ffffff1a;
  max-width: 100%;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 1px 2px -1px #0003, 0 3px 3px -2px #0003;
}

.euiFilterGroup > * {
  flex: auto;
  min-width: 48px;
}

.euiFilterGroup > .euiFilterButton--noGrow {
  flex-grow: 0;
}

.euiFilterGroup > .euiFilterButton-hasNotification {
  min-width: 96px;
}

.euiFilterGroup > .euiFilterButton--hasIcon {
  min-width: 128px;
}

.euiFilterGroup .euiPopover__anchor {
  display: block;
}

.euiFilterGroup .euiPopover__anchor .euiFilterButton {
  width: 100%;
}

.euiFilterGroup--fullWidth {
  display: flex;
}

.euiFilterGroup__popoverPanel {
  width: 288px;
}

@media only screen and (width <= 574px) {
  .euiFilterGroup {
    flex-wrap: wrap;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiFilterGroup {
    flex-wrap: wrap;
  }
}

@media only screen and (width <= 574px) {
  .euiFilterGroup {
    display: flex;
  }

  .euiFilterGroup .euiFilterButton {
    flex-grow: 1 !important;
  }
}

.euiFilterButton {
  background-color: #16171c;
  border: 1px solid #ffffff1a;
  border-right: none;
  width: auto;
  height: 40px;
  font-size: 14px;
}

.euiFilterButton:disabled {
  color: #515761;
  pointer-events: none;
}

.euiFilterButton:disabled .euiFilterButton__notification {
  opacity: .5;
}

.euiFilterButton:hover:not(:disabled), .euiFilterButton:focus:not(:disabled) {
  text-decoration: none;
}

.euiFilterButton:hover:not(:disabled) .euiFilterButton__textShift, .euiFilterButton:focus:not(:disabled) .euiFilterButton__textShift {
  text-decoration: underline;
}

.euiFilterButton-hasActiveFilters {
  font-weight: 700;
}

.euiFilterButton--hasIcon .euiButtonEmpty__content {
  justify-content: space-between;
}

.euiFilterButton--withNext + .euiFilterButton {
  border-left: none;
  margin-left: -4px;
}

.euiFilterButton-isSelected {
  background-color: #25262e;
}

.euiFilterButton__text-hasNotification {
  align-items: center;
  display: flex;
}

.euiFilterButton__notification {
  vertical-align: text-bottom;
  margin-left: 8px;
}

.euiFilterButton__textShift {
  min-width: 48px;
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiFilterButton__textShift:after {
  content: attr(data-text);
  visibility: hidden;
  height: 0;
  font-weight: 700;
  display: block;
  overflow: hidden;
}

.euiFilterSelectItem {
  text-align: left;
  color: #dfe5ef;
  outline-offset: -2px;
  border-color: #202128;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  padding: 4px 12px;
  font-size: 1rem;
  line-height: 1.71429rem;
  display: block;
}

.euiFilterSelectItem:hover {
  cursor: pointer;
  text-decoration: underline;
}

.euiFilterSelectItem:focus {
  cursor: pointer;
  background-color: #19c9c833;
  text-decoration: underline;
}

.euiFilterSelectItem:disabled {
  cursor: not-allowed;
  color: #515761;
  text-decoration: none;
}

.euiFilterSelectItem:focus, .euiFilterSelectItem-isFocused {
  color: #19c9c8;
  background-color: #19c9c833;
}

.euiFilterSelectItem__content {
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiFilterSelect__items {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  max-height: 480px;
  overflow-y: auto;
}

.euiFilterSelect__items::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiFilterSelect__items::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiFilterSelect__items::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiFilterSelect__items::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiFilterSelect__note {
  text-align: center;
  justify-content: space-around;
  align-items: center;
  height: 64px;
  display: flex;
}

.euiFilterSelect__noteContent {
  color: #98a2b3;
  font-size: 14px;
}

.euiKeyPadMenu {
  flex-flow: wrap;
  width: 300px;
  max-width: 100%;
  margin-bottom: -4px;
  margin-right: -4px;
  display: flex;
}

.euiKeyPadMenu legend {
  margin-bottom: 8px;
}

.euiKeyPadMenu > :not(legend) {
  margin-bottom: 4px;
  margin-right: 4px;
}

.euiKeyPadMenuItem {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  border: 1px solid #0000;
  border-radius: 0;
  width: 96px;
  height: 96px;
  padding: 4px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
  display: block;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .euiKeyPadMenuItem {
    transition: background-color .15s ease-in, border-color .15s ease-in, box-shadow .15s ease-in;
  }
}

.euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):hover, .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus, .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus-within {
  cursor: pointer;
  border-color: #343741;
  text-decoration: underline;
  box-shadow: 0 .8px .8px #0000001a, 0 2.3px 2px #00000013;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):hover .euiKeyPadMenuItem__icon, .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus .euiKeyPadMenuItem__icon, .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus-within .euiKeyPadMenuItem__icon {
    transform: translateY(0);
  }
}

.euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus {
  cursor: pointer;
  background-color: #19c9c833;
  text-decoration: underline;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isDisabled {
  cursor: not-allowed;
  color: #515761;
  text-decoration: none;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isDisabled .euiKeyPadMenuItem__icon {
  filter: grayscale();
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isDisabled .euiKeyPadMenuItem__icon svg * {
  fill: #515761;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled) {
  color: #dfe5ef;
  box-shadow: 0 .8px .8px #0000001a, 0 2.3px 2px #00000013;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected {
  border-color: #343741;
}

.euiKeyPadMenuItem:not(:hover):not(:focus):not(:focus-within):not(.euiKeyPadMenuItem-isSelected) .euiKeyPadMenuItem__checkableInput, .euiKeyPadMenuItem.euiKeyPadMenuItem-isDisabled:not(.euiKeyPadMenuItem-isSelected) .euiKeyPadMenuItem__checkableInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
}

.euiKeyPadMenuItem__inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.euiKeyPadMenuItem__inner .euiKeyPadMenuItem__checkableInput {
  transform-origin: 100% 0;
  transform: scale(.75);
}

.euiKeyPadMenuItem__inner .euiKeyPadMenuItem__checkableInput, .euiKeyPadMenuItem__inner .euiKeyPadMenuItem__betaBadge {
  z-index: 3;
  position: absolute;
  top: 4px;
  right: 4px;
}

.euiKeyPadMenuItem__icon {
  margin-bottom: 12px;
  transition: transform .25s cubic-bezier(.34, 1.61, .7, 1);
  transform: translateY(2px);
}

.euiKeyPadMenuItem__label {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.euiKeyPadMenuItem--checkable.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled), .euiKeyPadMenuItem--checkable.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):hover, .euiKeyPadMenuItem--checkable.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):focus, .euiKeyPadMenuItem--checkable.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):focus-within {
  color: #19c9c8;
  background-color: #19c9c833;
  border-color: #19c9c8;
}

.euiModal {
  z-index: 8000;
  background-color: #1d1e24;
  border: 1px solid #343741;
  border-radius: 0;
  flex-direction: column;
  min-width: 400px;
  max-width: calc(100vw - 16px);
  max-height: 75vh;
  animation: .35s cubic-bezier(.34, 1.61, .7, 1) euiModal;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2.7px 9px #00000053, 0 9.4px 24px #00000039, 0 21.8px 43px #0003;
}

.euiModal:focus {
  outline: none;
}

.euiModal--maxWidth-default {
  max-width: min(768px, 100vw - 16px);
}

.euiModal--confirmation {
  min-width: 400px;
}

.euiModalHeader {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px 16px 24px;
  display: flex;
}

.euiModalHeader__title {
  word-break: break-word;
  color: #dfe5ef;
  font-size: 1.92857rem;
  font-weight: 700;
  line-height: 2.28571rem;
  overflow-wrap: break-word !important;
}

.euiModalBody {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.euiModalBody .euiModalBody__overflow {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  height: 100%;
  padding: 8px 24px;
  overflow: hidden auto;
  -webkit-mask-image: linear-gradient(#ff00001a 0%, red 7.5px calc(100% - 7.5px), #ff00001a 100%);
  mask-image: linear-gradient(#ff00001a 0%, red 7.5px calc(100% - 7.5px), #ff00001a 100%);
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiModalBody .euiModalBody__overflow:focus {
  outline: none;
}

.euiModalBody .euiModalBody__overflow[tabindex="0"]:focus:focus-visible {
  outline-style: auto;
}

.euiModalFooter {
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 16px 24px 24px;
  display: flex;
}

.euiModalFooter > * + * {
  margin-left: 16px;
}

.euiModalHeader + .euiModalFooter {
  padding-top: 8px;
}

.euiModalBody:last-of-type .euiModalBody__overflow {
  padding-bottom: 24px;
}

.euiModal__closeIcon {
  z-index: 3;
  background-color: #1d1e24e6;
  position: absolute;
  top: 4px;
  right: 4px;
}

@keyframes euiModal {
  0% {
    opacity: 0;
    transform: translateY(32px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (width <= 574px) {
  .euiModal {
    border: none;
    border-radius: 0;
    max-height: 100vh;
    position: fixed;
    inset: 0;
    width: 100vw !important;
    min-width: 0 !important;
    max-width: none !important;
  }

  .euiModal.euiModal--confirmation {
    top: auto;
    box-shadow: 0 -2.7px 9px #00000053, 0 -9.4px 24px #00000039, 0 -21.8px 43px #0003;
  }

  .euiModalHeader {
    width: 100%;
  }

  .euiModalFooter {
    background: #25262e;
    justify-content: stretch;
    width: 100%;
    padding: 12px 24px !important;
  }

  .euiModalFooter > * {
    flex: 1;
  }

  .euiModalFooter > * + * {
    margin-left: 0;
  }

  .euiModalBody {
    width: 100%;
  }

  .euiModalBody .euiModalBody__overflow {
    padding-bottom: 24px;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiModal {
    border: none;
    border-radius: 0;
    max-height: 100vh;
    position: fixed;
    inset: 0;
    width: 100vw !important;
    min-width: 0 !important;
    max-width: none !important;
  }

  .euiModal.euiModal--confirmation {
    top: auto;
    box-shadow: 0 -2.7px 9px #00000053, 0 -9.4px 24px #00000039, 0 -21.8px 43px #0003;
  }

  .euiModalHeader {
    width: 100%;
  }

  .euiModalFooter {
    background: #25262e;
    justify-content: stretch;
    width: 100%;
    padding: 12px 24px !important;
  }

  .euiModalFooter > * {
    flex: 1;
  }

  .euiModalFooter > * + * {
    margin-left: 0;
  }

  .euiModalBody {
    width: 100%;
  }

  .euiModalBody .euiModalBody__overflow {
    padding-bottom: 24px;
  }
}

.euiSplitPanel {
  flex-direction: column;
  min-width: 0;
  display: flex;
  overflow: hidden;
}

.euiSplitPanel .euiSplitPanel__inner {
  flex-basis: 0%;
  box-shadow: none !important;
  transform: none !important;
}

.euiSplitPanel--row {
  flex-direction: row;
}

.euiSplitPanel--row.euiSplitPanel-isResponsive {
  flex-direction: column;
}

.euiSearchBar__searchHolder {
  min-width: 200px;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .euiSearchBar__filtersHolder {
    max-width: calc(100% - 16px);
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiSearchBar__filtersHolder {
    max-width: calc(100% - 16px);
  }
}

@media only screen and (width >= 1200px) {
  .euiSearchBar__filtersHolder {
    max-width: calc(100% - 16px);
  }
}

.euiSelectable {
  flex-direction: column;
  display: flex;
}

.euiSelectable-fullHeight {
  height: 100%;
}

.euiSelectableList:focus-within {
  outline: 2px solid;
}

.euiSelectableList:focus-within:focus-visible {
  outline-style: none;
}

.euiSelectableList:focus-within:not(:focus-visible) {
  outline: none;
}

.euiSelectableList-fullHeight {
  flex-grow: 1;
}

.euiSelectableList-bordered {
  border: 1px solid #343741;
  border-radius: 0;
  overflow: hidden;
}

.euiSelectableList__list {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  -webkit-mask-image: linear-gradient(#ff00001a 0%, red 7.5px calc(100% - 7.5px), #ff00001a 100%);
  mask-image: linear-gradient(#ff00001a 0%, red 7.5px calc(100% - 7.5px), #ff00001a 100%);
}

.euiSelectableList__list::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiSelectableList__list::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiSelectableList__list::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiSelectableList__list::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiSelectableList__groupLabel {
  word-break: break-word;
  color: #dfe5ef;
  border-bottom: 1px solid #202128;
  align-items: center;
  padding: 4px 12px;
  font-size: .857143rem;
  font-weight: 700;
  line-height: 1.14286rem;
  display: flex;
  overflow-wrap: break-word !important;
}

.euiSelectableListItem {
  text-align: left;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  line-height: 1.71429rem;
  display: inline-flex;
  overflow: hidden;
}

.euiSelectableListItem:not(:last-of-type) {
  border-bottom: 1px solid #202128;
}

.euiSelectableListItem-isFocused:not([aria-disabled="true"]), .euiSelectableListItem:hover:not([aria-disabled="true"]) {
  color: #19c9c8;
  background-color: #19c9c833;
}

.euiSelectableListItem-isFocused:not([aria-disabled="true"]) .euiSelectableListItem__text, .euiSelectableListItem:hover:not([aria-disabled="true"]) .euiSelectableListItem__text {
  text-decoration: underline;
}

.euiSelectableListItem[aria-disabled="true"] {
  color: #535966;
  cursor: not-allowed;
}

.euiSelectableListItem--paddingSmall .euiSelectableListItem__content {
  padding: 4px 12px;
}

.euiSelectableListItem__content {
  align-items: center;
  width: 100%;
  display: flex;
}

.euiSelectableListItem__icon, .euiSelectableListItem__prepend {
  flex-shrink: 0;
  margin-right: 12px;
}

.euiSelectableListItem__append {
  flex-shrink: 0;
  margin-left: 12px;
}

.euiSelectableListItem__text {
  flex-grow: 1;
}

.euiSelectableListItem__text--truncate {
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiSelectableMessage {
  text-align: center;
  word-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
}

.euiSelectableMessage--bordered {
  border: 1px solid #343741;
  border-radius: 0;
  overflow: hidden;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout {
  background-color: #0000;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout--group, .euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout input {
  box-shadow: inset 0 0 0 1px #ffffff4d;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) {
  color: #fff;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input {
  color: inherit;
  background-color: #0000;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input::-webkit-input-placeholder {
  color: #abaeb5;
  opacity: 1;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input::-moz-placeholder {
  color: #abaeb5;
  opacity: 1;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input:-ms-input-placeholder {
  color: #abaeb5;
  opacity: 1;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input:-moz-placeholder {
  color: #abaeb5;
  opacity: 1;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input::placeholder {
  color: #abaeb5;
  opacity: 1;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) .euiFormControlLayout__append {
  color: inherit;
  background-color: #0000;
}

.euiSelectableTemplateSitewide__listItem .euiSelectableListItem__text {
  text-decoration: none !important;
}

.euiSelectableTemplateSitewide__listItem[class*="-isFocused"]:not([aria-disabled="true"]) .euiSelectableTemplateSitewide__listItemTitle, .euiSelectableTemplateSitewide__listItem:hover:not([aria-disabled="true"]) .euiSelectableTemplateSitewide__listItemTitle {
  text-decoration: underline;
}

.euiSelectableTemplateSitewide__optionMetasList {
  color: #81858f;
  margin-top: 4px;
  font-size: 12px;
  display: block;
}

.euiSelectableTemplateSitewide__optionMeta:not(:last-of-type):after {
  content: "•";
  color: #81858f;
  margin: 0 4px;
}

.euiSelectableTemplateSitewide__optionMeta--application {
  color: #6092c0;
  font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--deployment {
  color: #54b399;
  font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--article {
  color: #9777bc;
  font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--case {
  color: #e7664c;
  font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--platform {
  color: #d6bf57;
  font-weight: 500;
}

.euiTable {
  font-feature-settings: "calt" 1, "kern" 1, "liga" 1, "tnum" 1;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: #1d1e24;
  border: none;
  width: 100%;
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiTable.euiTable--auto {
  table-layout: auto;
}

.euiTableCaption {
  position: relative;
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .euiTable--compressed .euiTableCellContent {
    padding: 4px;
    font-size: .857143rem;
    line-height: 1.14286rem;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiTable--compressed .euiTableCellContent {
    padding: 4px;
    font-size: .857143rem;
    line-height: 1.14286rem;
  }
}

@media only screen and (width >= 1200px) {
  .euiTable--compressed .euiTableCellContent {
    padding: 4px;
    font-size: .857143rem;
    line-height: 1.14286rem;
  }
}

.euiTableFooterCell, .euiTableHeaderCell {
  vertical-align: middle;
  font-weight: inherit;
  text-align: left;
  word-break: break-word;
  color: #dfe5ef;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.71429rem;
  overflow-wrap: break-word !important;
}

.euiTableFooterCell .euiTableHeaderButton, .euiTableHeaderCell .euiTableHeaderButton {
  text-align: left;
  font-weight: 500;
}

.euiTableFooterCell .euiTableCellContent__text, .euiTableHeaderCell .euiTableCellContent__text {
  font-size: .857143rem;
  font-weight: 600;
  line-height: 1.14286rem;
}

.euiTableHeaderButton {
  color: inherit;
  width: 100%;
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiTableHeaderButton:hover .euiTableCellContent__text, .euiTableHeaderButton:focus .euiTableCellContent__text {
  color: #19c9c8;
  text-decoration: underline;
}

.euiTableHeaderButton:hover .euiTableSortIcon, .euiTableHeaderButton:focus .euiTableSortIcon {
  fill: #19c9c8;
}

.euiTableSortIcon {
  flex-shrink: 0;
  margin-left: 4px;
}

.euiTableHeaderButton-isSorted .euiTableSortIcon {
  fill: #dfe5ef;
}

.euiTableHeaderCellCheckbox {
  vertical-align: middle;
  font-weight: inherit;
  text-align: left;
  vertical-align: middle;
  border: none;
  width: 32px;
}

.euiTableRow:hover {
  background-color: #1e1e25;
}

.euiTableRow.euiTableRow-isClickable:hover {
  cursor: pointer;
  background-color: #19c9c80d;
}

.euiTableRow.euiTableRow-isClickable:focus {
  background-color: #19c9c81a;
}

.euiTableRow.euiTableRow-isExpandedRow {
  background-color: #1e1e25;
}

.euiTableRow.euiTableRow-isExpandedRow.euiTableRow-isSelectable .euiTableCellContent {
  padding-left: 40px;
}

.euiTableRow.euiTableRow-isSelected, .euiTableRow.euiTableRow-isSelected + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
  background-color: #19c9c833;
}

.euiTableRow.euiTableRow-isSelected:hover, .euiTableRow.euiTableRow-isSelected:hover + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
  background-color: #0d656447;
}

.euiTableRowCell {
  vertical-align: middle;
  font-weight: inherit;
  text-align: left;
  color: #dfe5ef;
  border-top: 1px solid #343741;
  border-bottom: 1px solid #343741;
}

.euiTableRowCell--top {
  vertical-align: top;
}

.euiTableRowCell--bottom {
  vertical-align: bottom;
}

.euiTableRowCell--baseline {
  vertical-align: baseline;
}

.euiTableRowCell.euiTableRowCell--isMobileHeader {
  display: none;
}

.euiTableRowCellCheckbox {
  vertical-align: middle;
  font-weight: inherit;
  text-align: left;
  vertical-align: middle;
  border-top: 1px solid #343741;
  border-bottom: 1px solid #343741;
  width: 32px;
}

.euiTableFooterCell {
  background-color: #25262e;
}

.euiTableCellContent {
  align-items: center;
  padding: 8px;
  display: flex;
  overflow: hidden;
}

.euiTableCellContent__text {
  word-break: break-word;
  text-overflow: ellipsis;
  min-width: 0;
  overflow-wrap: break-word !important;
}

.euiTableCellContent--alignRight {
  text-align: right;
  justify-content: flex-end;
}

.euiTableCellContent--alignCenter {
  text-align: center;
  justify-content: center;
}

.euiTableHeaderCell, .euiTableFooterCell, .euiTableCellContent--truncateText {
  max-width: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiTableHeaderCell .euiTableCellContent__text, .euiTableFooterCell .euiTableCellContent__text, .euiTableCellContent--truncateText .euiTableCellContent__text {
  overflow: hidden;
}

.euiTableCellContent--overflowingContent {
  white-space: normal;
  word-break: break-word;
  overflow: visible;
}

.euiTableCellContent--showOnHover > :not(:first-child) {
  margin-left: 8px;
}

.euiTableRow-hasActions .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem {
  opacity: .7;
  filter: grayscale();
  flex-shrink: 0;
  transition: opacity .25s cubic-bezier(.694, .0482, .335, 1), filter .25s cubic-bezier(.694, .0482, .335, 1);
}

.euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide, .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled, .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled:hover, .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled:focus, .euiTableRow:hover .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled {
  filter: grayscale(0%);
  opacity: 0;
}

.euiTableRow-hasActions:hover .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem:not(:disabled), .euiTableRow-hasActions:hover .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem:not(:disabled):hover, .euiTableRow-hasActions:hover .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem:not(:disabled):focus {
  opacity: 1;
  filter: grayscale(0%);
}

.euiTableRow-isExpandedRow .euiTableCellContent {
  animation: .25s cubic-bezier(.694, .0482, .335, 1) forwards growExpandedRow;
  overflow: hidden;
}

@keyframes growExpandedRow {
  0% {
    max-height: 0;
  }

  99% {
    max-height: 100vh;
  }

  100% {
    max-height: unset;
  }
}

.euiTableRowCell__mobileHeader {
  display: none;
}

@media only screen and (width <= 574px) {
  .euiTableRowCell--hideForMobile {
    display: none !important;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiTableRowCell--hideForMobile {
    display: none !important;
  }
}

@media only screen and (width >= 768px) and (width <= 991px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important;
  }
}

@media only screen and (width >= 992px) and (width <= 1199px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important;
  }
}

@media only screen and (width >= 1200px) {
  .euiTableRowCell--hideForDesktop {
    display: none !important;
  }
}

@media only screen and (width <= 574px) {
  .euiTable.euiTable--responsive thead, .euiTable.euiTable--responsive tfoot {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
    color: #98a2b3;
    max-width: 100%;
    min-height: 24px;
    margin-bottom: -8px;
    padding: 8px 8px 0;
    font-size: .6875rem;
    display: block;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  .euiTableRowCell:only-child .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
    min-height: 0;
  }

  .euiTable.euiTable--responsive .euiTableRowCell--enlargeForMobile {
    font-size: 1.14286rem;
    line-height: 1.71429rem;
  }

  .euiTable.euiTable--responsive .euiTableRow {
    flex-grow: 1;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--flexGrowZero {
    flex-grow: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--hasShadow {
    box-shadow: 0 .9px 4px -1px #0003, 0 2.6px 8px -1px #00000026, 0 5.7px 12px -1px #00000020, 0 15px 15px -1px #0000001a;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--hasBorder {
    box-shadow: none;
    border: 1px solid #343741;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable {
    transition: all .15s cubic-bezier(.694, .0482, .335, 1);
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:enabled {
    text-align: left;
    width: 100%;
    display: block;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:hover, .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:focus {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0 1px 5px #00000040, 0 3.6px 13px #0000002d, 0 8.4px 23px #00000026, 0 23px 35px #00000020;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusNone, .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusMedium {
    border-radius: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--transparent {
    background-color: #0000;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--plain {
    background-color: #1d1e24;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--subdued {
    background-color: #1a1b20;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--accent {
    background-color: #4b2b3a;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--primary {
    background-color: #083c3c;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--success {
    background-color: #26443f;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--warning {
    background-color: #4d3e25;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--danger {
    background-color: #4d1f1f;
  }

  .euiTable.euiTable--responsive .euiTableRow {
    background-color: #1d1e24;
    border-radius: 0;
    flex-wrap: wrap;
    margin-bottom: 8px;
    padding: 8px;
    display: flex;
    box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
  }

  .euiTable.euiTable--responsive .euiTableRow:hover {
    background-color: #1d1e24;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
    background-image: linear-gradient(to right, #5359661a 0 1px, #0000 1px 100%);
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 40px 100%;
    padding-right: 40px;
    position: relative;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions {
    width: 24px;
    min-width: 0;
    position: absolute;
    top: 16px;
    right: 8px;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander:before, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions:before {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander .euiTableCellContent, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions .euiTableCellContent {
    flex-direction: column;
    padding: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander .euiTableCellContent .euiLink, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions .euiTableCellContent .euiLink {
    padding: 4px;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions.euiTableRow-isExpandable .euiTableRowCell--isExpander {
    top: auto;
    bottom: 16px;
    right: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable {
    padding-left: 36px;
    position: relative;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable .euiTableRowCellCheckbox {
    position: absolute;
    top: 8px;
    left: 4px;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected + .euiTableRow.euiTableRow-isExpandedRow, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
    background-color: #19c9c833;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow {
    z-index: 2;
    background-image: linear-gradient(to right, #5359661a 0 1px, #0000 1px 100%);
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 40px 100%;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -16px;
    padding-left: 8px;
    position: relative;
    box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
    background-color: #1d1e24;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
    width: calc(100% - 40px);
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell:before {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRowCell {
    border: none;
    min-width: 50%;
  }

  .euiTable.euiTable--responsive .euiTableRowCellCheckbox {
    border: none;
  }

  .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
    margin-left: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem {
    opacity: 1;
    filter: none;
    margin-bottom: 8px;
    margin-left: 0;
  }

  .euiTable.euiTable--responsive .euiTableCellContent--alignRight, .euiTable.euiTable--responsive .euiTableCellContent--alignCenter {
    justify-content: flex-start;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiTable.euiTable--responsive thead, .euiTable.euiTable--responsive tfoot {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
    color: #98a2b3;
    max-width: 100%;
    min-height: 24px;
    margin-bottom: -8px;
    padding: 8px 8px 0;
    font-size: .6875rem;
    display: block;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }

  .euiTableRowCell:only-child .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
    min-height: 0;
  }

  .euiTable.euiTable--responsive .euiTableRowCell--enlargeForMobile {
    font-size: 1.14286rem;
    line-height: 1.71429rem;
  }

  .euiTable.euiTable--responsive .euiTableRow {
    flex-grow: 1;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--flexGrowZero {
    flex-grow: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--hasShadow {
    box-shadow: 0 .9px 4px -1px #0003, 0 2.6px 8px -1px #00000026, 0 5.7px 12px -1px #00000020, 0 15px 15px -1px #0000001a;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--hasBorder {
    box-shadow: none;
    border: 1px solid #343741;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable {
    transition: all .15s cubic-bezier(.694, .0482, .335, 1);
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:enabled {
    text-align: left;
    width: 100%;
    display: block;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:hover, .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:focus {
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0 1px 5px #00000040, 0 3.6px 13px #0000002d, 0 8.4px 23px #00000026, 0 23px 35px #00000020;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusNone, .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusMedium {
    border-radius: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--transparent {
    background-color: #0000;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--plain {
    background-color: #1d1e24;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--subdued {
    background-color: #1a1b20;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--accent {
    background-color: #4b2b3a;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--primary {
    background-color: #083c3c;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--success {
    background-color: #26443f;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--warning {
    background-color: #4d3e25;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow--danger {
    background-color: #4d1f1f;
  }

  .euiTable.euiTable--responsive .euiTableRow {
    background-color: #1d1e24;
    border-radius: 0;
    flex-wrap: wrap;
    margin-bottom: 8px;
    padding: 8px;
    display: flex;
    box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
  }

  .euiTable.euiTable--responsive .euiTableRow:hover {
    background-color: #1d1e24;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
    background-image: linear-gradient(to right, #5359661a 0 1px, #0000 1px 100%);
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 40px 100%;
    padding-right: 40px;
    position: relative;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions {
    width: 24px;
    min-width: 0;
    position: absolute;
    top: 16px;
    right: 8px;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander:before, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions:before {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander .euiTableCellContent, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions .euiTableCellContent {
    flex-direction: column;
    padding: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander .euiTableCellContent .euiLink, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions .euiTableCellContent .euiLink {
    padding: 4px;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions.euiTableRow-isExpandable .euiTableRowCell--isExpander {
    top: auto;
    bottom: 16px;
    right: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable {
    padding-left: 36px;
    position: relative;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable .euiTableRowCellCheckbox {
    position: absolute;
    top: 8px;
    left: 4px;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected + .euiTableRow.euiTableRow-isExpandedRow, .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
    background-color: #19c9c833;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow {
    z-index: 2;
    background-image: linear-gradient(to right, #5359661a 0 1px, #0000 1px 100%);
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: 40px 100%;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -16px;
    padding-left: 8px;
    position: relative;
    box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
    background-color: #1d1e24;
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
    width: calc(100% - 40px);
  }

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell:before {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRowCell {
    border: none;
    min-width: 50%;
  }

  .euiTable.euiTable--responsive .euiTableRowCellCheckbox {
    border: none;
  }

  .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
    margin-left: 0;
  }

  .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide {
    display: none;
  }

  .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem {
    opacity: 1;
    filter: none;
    margin-bottom: 8px;
    margin-left: 0;
  }

  .euiTable.euiTable--responsive .euiTableCellContent--alignRight, .euiTable.euiTable--responsive .euiTableCellContent--alignCenter {
    justify-content: flex-start;
  }
}

.euiTableHeaderMobile, .euiTableHeaderCell--hideForDesktop {
  display: none;
}

@media only screen and (width <= 574px) {
  .euiTableHeaderMobile {
    justify-content: flex-end;
    padding: 8px 0;
    display: flex;
  }

  .euiTableSortMobile {
    display: block;
  }
}

@media only screen and (width >= 575px) and (width <= 767px) {
  .euiTableHeaderMobile {
    justify-content: flex-end;
    padding: 8px 0;
    display: flex;
  }

  .euiTableSortMobile {
    display: block;
  }
}

.euiTabs {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  flex-shrink: 0;
  max-width: 100%;
  display: flex;
  position: relative;
  overflow: auto hidden;
}

.euiTabs::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiTabs::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiTabs::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiTabs::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiTabs--bottomBorder {
  box-shadow: inset 0 -1px #343741;
}

.euiTabs::-webkit-scrollbar {
  height: 2px;
}

.euiTab {
  color: #dfe5ef;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.euiTab .euiTab__content {
  color: #dfe5ef;
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiTab:not(.euiTab-isDisabled):hover .euiTab__content, .euiTab:not(.euiTab-isDisabled):focus .euiTab__content {
  text-decoration: underline;
}

.euiTab:focus {
  outline-offset: -2px;
  background-color: #19c9c833;
}

.euiTab.euiTab-isSelected {
  box-shadow: inset 0 -2px #19c9c8;
}

.euiTab.euiTab-isSelected, .euiTab.euiTab-isSelected .euiTab__content {
  color: #19c9c8 !important;
}

.euiTab.euiTab-isDisabled, .euiTab.euiTab-isDisabled .euiTab__content {
  color: #515761 !important;
}

.euiTab.euiTab-isDisabled:hover {
  cursor: not-allowed;
}

.euiTab.euiTab-isDisabled.euiTab-isSelected {
  box-shadow: inset 0 -2px #515761;
}

.euiTabs--small .euiTab {
  padding: 8px;
}

.euiTabs--small .euiTab .euiTab__content {
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiTabs--large .euiTab {
  padding: 12px 16px;
}

.euiTabs--xlarge .euiTab {
  height: 72px;
  padding: 8px 24px;
  line-height: 72px;
}

.euiTabs--xlarge .euiTab .euiTab__content {
  font-size: 1.57143rem;
  line-height: 2.28571rem;
}

.euiTabs--expand .euiTab {
  flex-grow: 1;
  flex-basis: 0%;
  justify-content: center;
}

.euiTab__prepend {
  margin-right: 8px;
}

.euiTab__append {
  margin-left: 8px;
}

.euiCheckbox {
  position: relative;
}

.euiCheckbox .euiCheckbox__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 7px;
}

.euiCheckbox .euiCheckbox__input ~ .euiCheckbox__label {
  z-index: 2;
  cursor: pointer;
  padding-left: 24px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
  background: #1d1e24 center no-repeat;
  border: 1px solid #66676d;
  border-radius: 4px;
  padding: 7px;
  transition: background-color .15s ease-in, border-color .15s ease-in;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  background-color: #19c9c8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%2829, 30, 36%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
  border-color: #19c9c8;
}

.euiCheckbox .euiCheckbox__input:indeterminate + .euiCheckbox__square {
  background-color: #19c9c8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Crect width='6' height='6' fill='rgb%2829, 30, 36%29' fill-rule='evenodd'/%3E%3C/svg%3E");
  border-color: #19c9c8;
}

.euiCheckbox .euiCheckbox__input[disabled] {
  cursor: not-allowed !important;
}

.euiCheckbox .euiCheckbox__input[disabled] ~ .euiCheckbox__label {
  color: #535966;
  cursor: not-allowed !important;
}

.euiCheckbox .euiCheckbox__input[disabled] + .euiCheckbox__square {
  box-shadow: none;
  background-color: #343741;
  border-color: #343741;
}

.euiCheckbox .euiCheckbox__input:checked[disabled] + .euiCheckbox__square {
  box-shadow: none;
  background-color: #343741;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%28166, 170, 176%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
  border-color: #343741;
}

.euiCheckbox .euiCheckbox__input:indeterminate[disabled] + .euiCheckbox__square {
  box-shadow: none;
  background-color: #343741;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Crect width='6' height='6' fill='rgb%28166, 170, 176%29' fill-rule='evenodd'/%3E%3C/svg%3E");
  border-color: #343741;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square {
  outline-offset: 2px;
  border-color: #19c9c8;
  outline: 2px solid;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square:focus-visible {
  outline-style: none;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square:not(:focus-visible) {
  outline: none;
}

.euiCheckbox.euiCheckbox--inList, .euiCheckbox.euiCheckbox--noLabel {
  min-width: 16px;
  min-height: 16px;
}

.euiCheckbox.euiCheckbox--inList .euiCheckbox__input, .euiCheckbox.euiCheckbox--inList .euiCheckbox__square, .euiCheckbox.euiCheckbox--noLabel .euiCheckbox__input, .euiCheckbox.euiCheckbox--noLabel .euiCheckbox__square {
  top: 0;
}

.euiCheckbox.euiCheckbox--inList .euiCheckbox__input, .euiCheckbox.euiCheckbox--noLabel .euiCheckbox__input {
  margin: 0;
}

.euiCheckboxGroup__item + .euiCheckboxGroup__item {
  margin-top: 4px;
}

.euiCheckboxGroup__item + .euiCheckboxGroup__item.euiCheckbox--compressed {
  margin-top: 0;
}

.euiRadio {
  position: relative;
}

.euiRadio .euiRadio__input {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
}

.euiRadio .euiRadio__input ~ .euiRadio__label {
  z-index: 2;
  cursor: pointer;
  padding-left: 24px;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  position: relative;
}

.euiRadio .euiRadio__input + .euiRadio__circle {
  background: #1d1e24 center no-repeat;
  border: 1px solid #66676d;
  border-radius: 14px;
  padding: 7px;
  transition: background-color .15s ease-in, border-color .15s ease-in;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
}

.euiRadio .euiRadio__input:checked + .euiRadio__circle {
  background-color: #19c9c8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%2829, 30, 36%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
  border-color: #19c9c8;
}

.euiRadio .euiRadio__input[disabled] {
  cursor: not-allowed !important;
}

.euiRadio .euiRadio__input[disabled] ~ .euiRadio__label {
  color: #535966;
  cursor: not-allowed !important;
}

.euiRadio .euiRadio__input[disabled] + .euiRadio__circle {
  box-shadow: none;
  background-color: #343741;
  border-color: #343741;
}

.euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
  box-shadow: none;
  background-color: #343741;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%28166, 170, 176%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
  border-color: #343741;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle {
  outline-offset: 2px;
  border-color: #19c9c8;
  outline: 2px solid;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle:focus-visible {
  outline-style: none;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle:not(:focus-visible) {
  outline: none;
}

.euiRadio.euiRadio--inList, .euiRadio.euiRadio--noLabel {
  min-width: 16px;
  min-height: 16px;
}

.euiRadio.euiRadio--inList .euiRadio__input, .euiRadio.euiRadio--inList .euiRadio__circle, .euiRadio.euiRadio--noLabel .euiRadio__input, .euiRadio.euiRadio--noLabel .euiRadio__circle {
  top: 0;
}

.euiRadio.euiRadio--inList .euiRadio__input, .euiRadio.euiRadio--noLabel .euiRadio__input {
  margin: 0;
}

.euiForm__error {
  font-size: 1rem;
  line-height: 1.71429rem;
  list-style: disc;
}

.euiForm__errors {
  margin-bottom: 16px;
}

.euiFormHelpText {
  color: #98a2b3;
  padding-top: 4px;
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiFormLabel {
  word-break: break-word;
  color: #dfe5ef;
  font-size: .857143rem;
  font-weight: 600;
  line-height: 1.14286rem;
  transition: all .15s cubic-bezier(.694, .0482, .335, 1);
  display: inline-block;
  overflow-wrap: break-word !important;
}

.euiFormLabel.euiFormLabel-isInvalid {
  color: #f66;
}

.euiFormLabel.euiFormLabel-isFocused {
  color: #19c9c8;
}

.euiFormLabel[for] {
  cursor: pointer;
}

.euiFormLabel[for].euiFormLabel-isDisabled {
  cursor: default;
}

.euiFormRow {
  flex-direction: column;
  max-width: 400px;
  display: flex;
}

.euiFormRow + .euiFormRow, .euiFormRow + .euiButton {
  margin-top: 16px;
}

.euiFormRow--fullWidth {
  max-width: 100%;
}

.euiFormRow--hasEmptyLabelSpace {
  justify-content: center;
  min-height: 40px;
  margin-top: 20px;
  padding-bottom: 0;
}

.euiFormRow__labelWrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4px;
  display: flex;
}

.euiFormRow--horizontal {
  flex-direction: row;
  align-items: stretch;
}

.euiFormRow--horizontal .euiFormRow__label {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.euiFormRow--horizontal .euiFormRow__labelWrapper {
  width: calc(33% - 8px);
  margin-bottom: 0;
  margin-right: 8px;
  line-height: 31px;
  display: block;
}

.euiFormRow--horizontal .euiFormRow__fieldWrapper {
  width: 67%;
}

.euiFormRow--horizontal + .euiFormRow--horizontal {
  margin-top: 8px;
}

.euiFormRow--horizontal + .euiFormRow--horizontal.euiFormRow--hasSwitch {
  margin-top: 12px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__labelWrapper {
  width: auto;
  min-width: calc(33% - 8px);
  line-height: 19px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper {
  width: auto;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper .euiSwitch--compressed {
  margin-top: 2px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch + .euiFormRow--horizontal {
  margin-top: 12px;
}

.euiFormRow__fieldWrapperDisplayOnly {
  align-items: center;
  min-height: 40px;
  display: flex;
}

.euiFormRow--compressed.euiFormRow--hasEmptyLabelSpace, .euiFormRow--compressed .euiFormRow__fieldWrapperDisplayOnly {
  min-height: 32px;
}

.euiFieldText {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 0;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

.euiFieldText--fullWidth {
  max-width: 100%;
}

.euiFieldText--compressed {
  height: 32px;
}

.euiFieldText--inGroup {
  height: 100%;
}

@supports ((-moz-appearance: none)) {
  .euiFieldText {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFieldText::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldText::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldText:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldText:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldText::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldText:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiFieldText:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldText:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldText:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldText:-webkit-autofill {
  -webkit-text-fill-color: #343741;
}

.euiFieldText:-webkit-autofill ~ .euiFormControlLayoutIcons {
  color: #343741;
}

.euiFieldText--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiFieldText--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFieldText--compressed:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiFieldText--compressed:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldText--compressed:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldText--compressed:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText--compressed:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText--compressed:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldText--compressed[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldText--inGroup {
  border-radius: 0;
  box-shadow: none !important;
}

.euiFieldText--withIcon {
  padding-left: 40px;
}

.euiFieldText.euiFieldText-isInvalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiFieldText--withIcon.euiFieldText--compressed {
  padding-left: 32px;
}

.euiSelect {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 0;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding: 0 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

.euiSelect--fullWidth {
  max-width: 100%;
}

.euiSelect--compressed {
  height: 32px;
}

.euiSelect--inGroup {
  height: 100%;
}

@supports ((-moz-appearance: none)) {
  .euiSelect {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiSelect::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSelect::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSelect:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSelect:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSelect::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSelect:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiSelect:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSelect:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSelect:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSelect:-webkit-autofill {
  -webkit-text-fill-color: #343741;
}

.euiSelect:-webkit-autofill ~ .euiFormControlLayoutIcons {
  color: #343741;
}

.euiSelect--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiSelect--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiSelect--compressed:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiSelect--compressed:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSelect--compressed:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSelect--compressed:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect--compressed:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect--compressed:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSelect--compressed[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSelect--inGroup {
  border-radius: 0;
  box-shadow: none !important;
}

.euiSelect--compressed {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 32px;
}

.euiSelect--inGroup {
  line-height: 38px;
}

.euiSelect--inGroup.euiSelect--compressed {
  line-height: 30px;
}

.euiSelect::-ms-expand {
  display: none;
}

.euiSelect:focus::-ms-value {
  color: #dfe5ef;
  background: none;
}

.euiSelect:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #dfe5ef;
}

.euiSuperSelect__listbox {
  scrollbar-color: #98a2b380 transparent;
  scrollbar-width: thin;
  max-height: 300px;
  overflow: hidden auto;
}

.euiSuperSelect__listbox::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiSuperSelect__listbox::-webkit-scrollbar-thumb {
  background-color: #98a2b380;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 16px;
}

.euiSuperSelect__listbox::-webkit-scrollbar-corner {
  background-color: #0000;
}

.euiSuperSelect__listbox::-webkit-scrollbar-track {
  background-color: #0000;
}

.euiSuperSelect__item {
  padding: 8px;
  font-size: 1rem;
  line-height: 1.71429rem;
}

.euiSuperSelect__item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.euiSuperSelect__item:focus {
  cursor: pointer;
  background-color: #19c9c833;
  text-decoration: underline;
}

.euiSuperSelect__item:disabled {
  cursor: not-allowed;
  color: #515761;
  text-decoration: none;
}

.euiSuperSelect__item--hasDividers:not(:last-of-type) {
  border-bottom: 1px solid #343741;
}

.euiSuperSelectControl {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 0;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding: 0 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl--fullWidth {
  max-width: 100%;
}

.euiSuperSelectControl--compressed {
  height: 32px;
}

.euiSuperSelectControl--inGroup {
  height: 100%;
}

@supports ((-moz-appearance: none)) {
  .euiSuperSelectControl {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiSuperSelectControl::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperSelectControl::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperSelectControl:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperSelectControl:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperSelectControl::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiSuperSelectControl:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiSuperSelectControl:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl:-webkit-autofill {
  -webkit-text-fill-color: #343741;
}

.euiSuperSelectControl:-webkit-autofill ~ .euiFormControlLayoutIcons {
  color: #343741;
}

.euiSuperSelectControl--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiSuperSelectControl--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiSuperSelectControl--compressed:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiSuperSelectControl--compressed:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl--compressed:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl--compressed:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl--compressed:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl--compressed:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiSuperSelectControl--compressed[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSuperSelectControl--inGroup {
  border-radius: 0;
  box-shadow: none !important;
}

.euiSuperSelectControl-isInvalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiSuperSelectControl--compressed {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 32px;
}

.euiSuperSelectControl.euiSuperSelect--isOpen__button {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiSwitch {
  align-items: flex-start;
  min-height: 20px;
  display: inline-flex;
  position: relative;
}

.euiSwitch .euiSwitch__label {
  cursor: pointer;
  vertical-align: middle;
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
}

.euiSwitch .euiSwitch__button {
  flex-shrink: 0;
  line-height: 0;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track {
  outline-offset: 2px;
  border-color: #19c9c8;
  outline: 2px solid;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track:focus-visible {
  outline-style: none;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track:not(:focus-visible) {
  outline: none;
}

.euiSwitch .euiSwitch__button:disabled:hover, .euiSwitch .euiSwitch__button:disabled ~ .euiSwitch__label:hover {
  cursor: not-allowed;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__body {
  background-color: #535966b3;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__thumb {
  box-shadow: none;
  background-color: #535966b3;
  border-color: #343741;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__icon {
  fill: #a6aab0;
}

.euiSwitch .euiSwitch__button:disabled + .euiSwitch__label {
  color: #535966;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__body {
  background-color: #535966b3;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb {
  left: 0;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__icon {
  right: -8px;
}

.euiSwitch .euiSwitch__button[aria-checked="false"] .euiSwitch__icon.euiSwitch__icon--checked {
  left: -34px;
  right: auto;
}

.euiSwitch .euiSwitch__body {
  pointer-events: none;
  vertical-align: middle;
  background-color: #19c9c8;
  border-radius: 20px;
  width: 44px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.euiSwitch .euiSwitch__thumb {
  background: #1d1e24 center no-repeat;
  border: 1px solid #66676d;
  border-radius: 18px;
  padding: 9px;
  transition: border-color .25s cubic-bezier(.34, 1.61, .7, 1), background-color .25s cubic-bezier(.34, 1.61, .7, 1), left .25s cubic-bezier(.34, 1.61, .7, 1), transform .25s cubic-bezier(.34, 1.61, .7, 1);
  display: inline-block;
  position: absolute;
  left: 24px;
}

.euiSwitch .euiSwitch__track {
  border-radius: 20px;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.euiSwitch .euiSwitch__icon {
  fill: #dfe5ef;
  width: 42px;
  height: 16px;
  transition: left .25s cubic-bezier(.34, 1.61, .7, 1), right .25s cubic-bezier(.34, 1.61, .7, 1);
  position: absolute;
  top: 2px;
  bottom: 0;
  right: -34px;
}

.euiSwitch .euiSwitch__icon--checked {
  fill: #1d1e24;
  left: -8px;
  right: auto;
}

.euiSwitch:hover .euiSwitch__button:not(:disabled) .euiSwitch__thumb {
  transform: scale(1.05);
}

.euiSwitch:hover .euiSwitch__button:active .euiSwitch__thumb {
  transform: scale(.95);
}

.euiSwitch.euiSwitch--compressed {
  min-height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__label {
  line-height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__body {
  border-radius: 16px;
  width: 28px;
  height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__thumb {
  background: #1d1e24 center no-repeat;
  border: 1px solid #66676d;
  border-radius: 12px;
  padding: 6px;
  transition: border-color .25s cubic-bezier(.34, 1.61, .7, 1), background-color .25s cubic-bezier(.34, 1.61, .7, 1), left .25s cubic-bezier(.34, 1.61, .7, 1), transform .25s cubic-bezier(.34, 1.61, .7, 1);
  top: 1px;
  left: 13px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__track {
  border-radius: 16px;
}

.euiSwitch.euiSwitch--mini {
  min-height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__label {
  font-size: 12px;
  line-height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__body {
  border-radius: 10px;
  width: 22px;
  height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__thumb {
  background: #1d1e24 center no-repeat;
  border: 1px solid #66676d;
  border-radius: 6px;
  padding: 3px;
  transition: border-color .25s cubic-bezier(.34, 1.61, .7, 1), background-color .25s cubic-bezier(.34, 1.61, .7, 1), left .25s cubic-bezier(.34, 1.61, .7, 1), transform .25s cubic-bezier(.34, 1.61, .7, 1);
  top: 1px;
  left: 13px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__track {
  border-radius: 10px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb {
  left: 1px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb, .euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="true"]:disabled .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="false"] .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="true"]:disabled .euiSwitch__thumb {
  border-color: #66676d;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked="true"] .euiSwitch__thumb, .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked="true"] .euiSwitch__thumb {
  border-color: #19c9c8;
}

.euiTextArea {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 0;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding: 12px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

.euiTextArea--fullWidth {
  max-width: 100%;
}

.euiTextArea--compressed {
  height: 32px;
}

.euiTextArea--inGroup {
  height: 100%;
}

@supports ((-moz-appearance: none)) {
  .euiTextArea {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiTextArea::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiTextArea::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiTextArea:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiTextArea:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiTextArea::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiTextArea:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiTextArea:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiTextArea:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiTextArea:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiTextArea:-webkit-autofill {
  -webkit-text-fill-color: #343741;
}

.euiTextArea:-webkit-autofill ~ .euiFormControlLayoutIcons {
  color: #343741;
}

.euiTextArea--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiTextArea--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiTextArea--compressed:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiTextArea--compressed:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiTextArea--compressed:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiTextArea--compressed:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea--compressed:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea--compressed:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiTextArea--compressed[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiTextArea--inGroup {
  border-radius: 0;
  box-shadow: none !important;
}

.euiTextArea, .euiTextArea--compressed {
  height: auto;
}

.euiTextArea--resizeVertical {
  resize: vertical;
}

.euiTextArea--resizeHorizontal {
  resize: horizontal;
}

.euiTextArea--resizeBoth {
  resize: both;
}

.euiTextArea--resizeNone {
  resize: none;
}

.euiFormErrorText {
  color: #f66;
  padding-top: 4px;
  font-size: .857143rem;
  line-height: 1.14286rem;
}

.euiFormControlLayout {
  width: 100%;
  max-width: 400px;
  height: 40px;
}

.euiFormControlLayout--fullWidth {
  max-width: 100%;
}

.euiFormControlLayout--compressed {
  height: 32px;
}

.euiFormControlLayout--inGroup {
  height: 100%;
}

.euiFormControlLayout--1icons {
  padding-right: 34px;
}

.euiFormControlLayout--1icons[class*="compressed"] {
  padding-right: 26px;
}

.euiFormControlLayout--2icons {
  padding-right: 56px;
}

.euiFormControlLayout--2icons[class*="compressed"] {
  padding-right: 44px;
}

.euiFormControlLayout--3icons {
  padding-right: 78px;
}

.euiFormControlLayout--3icons[class*="compressed"] {
  padding-right: 62px;
}

.euiFormControlLayout--4icons {
  padding-right: 100px;
}

.euiFormControlLayout--4icons[class*="compressed"] {
  padding-right: 80px;
}

.euiFormControlLayout--5icons {
  padding-right: 122px;
}

.euiFormControlLayout--5icons[class*="compressed"] {
  padding-right: 98px;
}

.euiFormControlLayout__childrenWrapper {
  position: relative;
}

.euiFormControlLayout--group {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  align-items: stretch;
  padding: 1px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayout--group {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayout--group > *, .euiFormControlLayout--group .euiPopover__anchor, .euiFormControlLayout--group .euiButtonEmpty, .euiFormControlLayout--group .euiText, .euiFormControlLayout--group .euiFormLabel, .euiFormControlLayout--group .euiButtonIcon {
  height: 100%;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper {
  flex-grow: 1;
  overflow: hidden;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend, .euiFormControlLayout--group .euiFormControlLayout__append {
  border-radius: 0;
  flex-shrink: 0;
  max-width: 100%;
  height: 100%;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend.euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append.euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiIcon {
  background-color: #2c2f37;
  border-radius: 0;
  width: 32px;
  padding: 0 8px;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty {
  transform: none !important;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon .euiIcon, .euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty .euiIcon {
  width: 16px;
  padding: 0;
  background: none !important;
}

.euiFormControlLayout--group .euiButtonIcon {
  border-radius: 0;
  width: 32px;
  padding: 0 8px;
}

.euiFormControlLayout--group .euiButtonIcon:not(:focus) {
  background-color: #2c2f37;
}

.euiFormControlLayout--group .euiButtonIcon:focus-visible {
  outline-offset: -2px;
  outline: 2px solid #19c9c8;
}

.euiFormControlLayout--group .euiToolTipAnchor > .euiIcon {
  background-color: #2c2f37;
  border-radius: 0;
  width: 32px;
  height: 100%;
  padding: 0 8px;
}

.euiFormControlLayout--group > .euiFormControlLayout__prepend, .euiFormControlLayout--group > .euiFormControlLayout__append {
  max-width: 50%;
}

.euiFormControlLayout--group .euiFormLabel, .euiFormControlLayout--group .euiText {
  background-color: #2c2f37;
  padding: 12px;
  cursor: default !important;
  line-height: 16px !important;
}

.euiFormControlLayout--group .euiFormLabel + :not(.euiFormControlLayout__childrenWrapper):not(input), .euiFormControlLayout--group .euiText + :not(.euiFormControlLayout__childrenWrapper):not(input), .euiFormControlLayout--group > :not(.euiFormControlLayout__childrenWrapper) + .euiFormLabel, .euiFormControlLayout--group > :not(.euiFormControlLayout__childrenWrapper) + .euiText {
  margin-left: -12px;
}

.euiFormControlLayout--group .euiButtonEmpty {
  border-right: none;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ .euiButtonEmpty, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ * .euiButtonEmpty {
  border-left: none;
  border-right: none;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 0;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayout--group.euiFormControlLayout--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormLabel, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiText {
  padding: 8px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormLabel + :not(.euiFormControlLayout__childrenWrapper), .euiFormControlLayout--group.euiFormControlLayout--compressed .euiText + :not(.euiFormControlLayout__childrenWrapper), .euiFormControlLayout--group.euiFormControlLayout--compressed > :not(.euiFormControlLayout__childrenWrapper) + .euiFormLabel, .euiFormControlLayout--group.euiFormControlLayout--compressed > :not(.euiFormControlLayout__childrenWrapper) + .euiText {
  margin-left: -8px;
}

.euiFormControlLayout--group.euiFormControlLayout--readOnly {
  cursor: default;
  background: #2c2f37;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #2c2f37;
}

.euiFormControlLayout--group.euiFormControlLayout--readOnly input {
  background-color: #1d1e24;
}

.euiFormControlLayoutDelimited {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  align-items: stretch;
  padding: 1px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  display: flex;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayoutDelimited {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayoutDelimited .euiFormControlLayoutDelimited__delimeter {
  background-color: #16171c;
}

.euiFormControlLayoutDelimited > .euiFormControlLayout__childrenWrapper {
  align-items: center;
  width: 100%;
  display: flex;
}

.euiFormControlLayoutDelimited[class*="--compressed"] {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 0;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiFormControlLayoutDelimited[class*="--compressed"] {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFormControlLayoutDelimited[class*="--compressed"] .euiFormControlLayoutDelimited__input {
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.euiFormControlLayoutDelimited[class*="--compressed"] .euiFormControlLayoutIcons {
  padding-left: 8px;
  padding-right: 8px;
}

.euiFormControlLayoutDelimited[class*="--fullWidth"] .euiFormControlLayout__childrenWrapper, .euiFormControlLayoutDelimited[class*="--fullWidth"] input {
  width: 100%;
  max-width: none;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"] {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"]::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"]::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"]:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"]:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"]::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFormControlLayoutDelimited[class*="-isDisabled"] .euiFormControlLayoutDelimited__delimeter {
  background-color: #202128;
}

.euiFormControlLayoutDelimited[class*="--readOnly"] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFormControlLayoutDelimited[class*="--readOnly"] input, .euiFormControlLayoutDelimited[class*="--readOnly"] .euiFormControlLayoutDelimited__delimeter {
  background-color: #1d1e24;
}

.euiFormControlLayoutDelimited .euiFormControlLayoutIcons {
  padding-left: 12px;
  padding-right: 12px;
  position: static;
}

.euiFormControlLayoutDelimited .euiFormControlLayoutIcons:not(.euiFormControlLayoutIcons--right) {
  order: -1;
}

.euiFormControlLayoutDelimited__input {
  text-align: center;
  min-width: 0;
  height: 100%;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.euiFormControlLayoutDelimited[class*="--compressed"] .euiFormControlLayoutDelimited__input {
  max-width: none;
}

.euiFormControlLayoutDelimited__delimeter {
  flex: none;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 1 !important;
}

.euiFormControlLayoutIcons {
  pointer-events: none;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
}

.euiFormControlLayoutIcons > * + * {
  margin-left: 6px;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons {
  left: 8px;
}

.euiFormControlLayoutIcons--right {
  left: auto;
  right: 12px;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons--right {
  left: auto;
  right: 8px;
}

:disabled + .euiFormControlLayoutIcons {
  cursor: not-allowed;
  color: #535966;
}

.euiFormControlLayoutClearButton {
  pointer-events: all;
  background-color: #98a2b3;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  line-height: 0;
}

.euiFormControlLayoutClearButton:focus {
  outline: 2px solid;
}

.euiFormControlLayoutClearButton:focus:focus-visible {
  outline-style: none;
}

.euiFormControlLayoutClearButton:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayoutClearButton .euiFormControlLayoutClearButton__icon {
  fill: #1d1e24;
  stroke: #1d1e24;
  stroke-width: 2px;
  width: 8px;
  height: 8px;
}

.euiFormControlLayoutClearButton--small {
  pointer-events: all;
  background-color: #98a2b3;
  border-radius: 12px;
  width: 12px;
  height: 12px;
  line-height: 0;
}

.euiFormControlLayoutClearButton--small:focus {
  outline: 2px solid;
}

.euiFormControlLayoutClearButton--small:focus:focus-visible {
  outline-style: none;
}

.euiFormControlLayoutClearButton--small:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayoutClearButton--small .euiFormControlLayoutClearButton__icon {
  fill: #1d1e24;
  stroke: #1d1e24;
  stroke-width: 4px;
  width: 6px;
  height: 6px;
}

.euiFormControlLayoutCustomIcon {
  pointer-events: none;
  font-size: 0;
}

.euiFormControlLayoutCustomIcon--clickable {
  pointer-events: all;
  width: 16px;
  height: 16px;
}

.euiFormControlLayoutCustomIcon--clickable .euiFormControlLayoutCustomIcon__icon {
  vertical-align: baseline;
  transform: none;
}

.euiFormControlLayoutCustomIcon--clickable:focus {
  outline: 2px solid;
}

.euiFormControlLayoutCustomIcon--clickable:focus:focus-visible {
  outline-style: none;
}

.euiFormControlLayoutCustomIcon--clickable:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayoutCustomIcon--clickable:disabled {
  cursor: not-allowed;
  color: #535966;
}

.euiFieldSearch {
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
  color: #dfe5ef;
  -webkit-appearance: textfield;
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border: none;
  border-radius: 0;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding: 12px 12px 12px 40px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 14px;
  font-weight: 400;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch--fullWidth {
  max-width: 100%;
}

.euiFieldSearch--compressed {
  height: 32px;
}

.euiFieldSearch--inGroup {
  height: 100%;
}

@supports ((-moz-appearance: none)) {
  .euiFieldSearch {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFieldSearch::-webkit-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldSearch::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldSearch:-ms-input-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldSearch:-moz-placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldSearch::placeholder {
  color: #81858f;
  opacity: 1;
}

.euiFieldSearch:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiFieldSearch:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch:-webkit-autofill {
  -webkit-text-fill-color: #343741;
}

.euiFieldSearch:-webkit-autofill ~ .euiFormControlLayoutIcons {
  color: #343741;
}

.euiFieldSearch--compressed {
  background-color: #16171c;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  border-radius: 4px;
  padding: 8px;
  transition: box-shadow .15s ease-in, background-image .15s ease-in, background-size .15s ease-in, background-color .15s ease-in;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

@supports ((-moz-appearance: none)) {
  .euiFieldSearch--compressed {
    transition-property: box-shadow, background-image, background-size;
  }
}

.euiFieldSearch--compressed:invalid {
  background-image: linear-gradient(to top, #f66, #f66 2px, #0000 2px 100%);
  background-size: 100%;
}

.euiFieldSearch--compressed:focus {
  background-color: #111216;
  background-image: linear-gradient(to top, #19c9c8, #19c9c8 2px, #0000 2px 100%);
  background-size: 100% 100%;
  outline: none;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch--compressed:disabled {
  color: #535966;
  -webkit-text-fill-color: #535966;
  cursor: not-allowed;
  background: #202128;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch--compressed:disabled::-webkit-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch--compressed:disabled:-ms-input-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch--compressed:disabled:-moz-placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch--compressed:disabled::placeholder {
  color: #535966;
  opacity: 1;
}

.euiFieldSearch--compressed[readOnly] {
  cursor: default;
  color: #dfe5ef;
  -webkit-text-fill-color: #dfe5ef;
  background: #1d1e24;
  border-color: #0000;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFieldSearch--inGroup {
  border-radius: 0;
  box-shadow: none !important;
}

.euiFieldSearch-isLoading {
  padding-right: 40px;
}

.euiFieldSearch-isLoading.euiFieldSearch--compressed {
  padding-right: 32px;
}

.euiFieldSearch::-webkit-search-decoration {
  -webkit-appearance: none;
}

.euiFieldSearch::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.euiFieldSearch--compressed {
  padding-left: 32px;
}

.euiCode {
  color: #a184c2;
  border-radius: 4px;
  font-weight: 700;
}

.euiCodeBlock.euiCodeBlock--fontLarge {
  font-size: 16px;
}

.euiColorStops__addTarget, .euiColorStops__addContainer {
  z-index: 1;
}

.euiColorStops__addTarget {
  box-shadow: none;
  border: 1px solid #98a2b3;
}

.euiColorStopThumb.euiRangeThumb:not(:disabled) {
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiColorStopThumb.euiRangeThumb:not(:disabled):focus {
  outline: none;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiColorStopThumb.euiRangeThumb:not(:disabled):focus:not(:focus-visible) {
  outline: none;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiColorStops:not(.euiColorStops-isDisabled) .euiRangeTrack:after {
  transition-property: box-shadow;
  transition-delay: 90ms;
}

.euiColorStops:not(.euiColorStops-isDisabled):focus {
  outline: none;
}

.euiColorStops:not(.euiColorStops-isDisabled):focus .euiRangeTrack:after {
  box-shadow: 0 0 0 1px #1d1e24cc, 0 0 0 3px #19c9c8;
}

.euiColorStops:not(.euiColorStops-isDisabled):focus:not(:focus-visible) .euiRangeTrack:after {
  box-shadow: none;
}

.euiColorStops__highlight {
  color: #343741;
}

.euiColorStops__highlight .euiRangeHighlight__progress {
  background-color: #343741;
}

.euiComboBox--appended .euiFormControlLayout__childrenWrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.euiComboBox--appended .euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.euiComboBox--appended.euiComboBox--prepended .euiFormControlLayout__childrenWrapper, .euiComboBox--appended.euiComboBox--prepended .euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper {
  border-radius: 0;
}

.euiComboBox--prepended .euiFormControlLayout__childrenWrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.euiComboBox--prepended .euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper, .euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^="top"] {
  border: none;
  border-radius: 0;
}

.euiDatePickerRange {
  border-radius: 0;
}

.euiDatePicker.euiDatePicker--shadow.euiDatePicker--inline .react-datepicker {
  border: none;
}

.euiSuperDatePicker__prettyFormat {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.euiFormControlLayout--compressed.euiSuperDatePicker .euiSuperDatePicker__prettyFormat {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.euiDatePopoverButton--start {
  text-align: center;
}

.euiDatePopoverButton--end {
  text-align: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.euiDatePopoverButton--end.euiDatePopoverButton--compressed {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--compressed .euiDescriptionList__title {
  line-height: 1.5;
}

.euiFilterGroup {
  background-color: #16171c;
  border: none;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.euiFilterGroup--compressed {
  border-radius: 4px;
}

.euiFilterGroup--compressed .euiFilterButton {
  height: 32px;
}

.euiFilterButton {
  background-color: #0000;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px #ffffff1a, -1px 0 #ffffff1a;
}

.euiFilterButton--withNext + .euiFilterButton {
  box-shadow: 0 1px #ffffff1a;
}

.euiFormControlLayout--group {
  background-color: #2c2f37;
  border-radius: 0;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend:first-child, .euiFormControlLayout--group .euiFormControlLayout__prepend:first-child [class*="euiButton"] {
  border-radius: -1px 0 0 -1px;
}

.euiFormControlLayout--group .euiFormControlLayout__append:last-child, .euiFormControlLayout--group .euiFormControlLayout__append:last-child [class*="euiButton"] {
  border-radius: 0 -1px -1px 0;
}

.euiFormControlLayout--group [class*="euiButton"]:focus {
  outline-offset: -2px;
  outline: 2px solid;
}

.euiFormControlLayout--group [class*="euiButton"]:focus:focus-visible {
  outline-style: none;
}

.euiFormControlLayout--group [class*="euiButton"]:focus:not(:focus-visible) {
  outline: none;
}

.euiFormControlLayout--group .euiToolTipAnchor > .euiIcon {
  border-radius: 0 -1px -1px 0;
}

.euiFormControlLayout--group .euiToolTipAnchor:first-child [class*="euiButton"] {
  border-radius: -1px 0 0 -1px;
}

.euiFormControlLayout--group .euiToolTipAnchor:last-child [class*="euiButton"], .euiFormControlLayout--group .euiToolTipAnchor:last-child .euiText {
  border-radius: 0 -1px -1px 0;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:nth-child(2) [class*="euiField"], .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:nth-child(3) [class*="euiField"], .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child .euiSelect, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child .euiSuperSelectControl, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child [class*="euiField"], .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:last-child .euiSelect, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:last-child .euiSuperSelectControl, .euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:last-child [class*="euiField"] {
  border-radius: 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed {
  background-color: #2c2f37;
  border-radius: 4px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed.euiFormControlLayout--readOnly input {
  background-color: #1d1e24;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__prepend:first-child {
  border-radius: 3px 0 0 3px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__prepend:first-child [class*="euiButton"] {
  border-radius: 4px 0 0 4px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__append:last-child, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__append:last-child [class*="euiButton"], .euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor > .euiIcon {
  border-radius: 0 3px 3px 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor:first-child [class*="euiButton"] {
  border-radius: 3px 0 0 3px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor:last-child [class*="euiButton"], .euiFormControlLayout--group.euiFormControlLayout--compressed .euiToolTipAnchor:last-child .euiText {
  border-radius: 0 3px 3px 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:nth-child(2) [class*="euiField"], .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:nth-child(3) [class*="euiField"] {
  border-radius: 0;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:first-child .euiSelect, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:first-child [class*="euiField"] {
  border-radius: 3px 0 0 3px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:last-child .euiSelect, .euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:last-child [class*="euiField"] {
  border-radius: 0 3px 3px 0;
}

.euiFormControlLayoutDelimited, .euiFormControlLayoutDelimited.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child {
  border-radius: 0;
}

.euiFormControlLayoutDelimited .euiFormControlLayout__childrenWrapper:only-child {
  border-radius: 0;
  overflow: hidden;
}

.euiFormControlLayoutDelimited .euiFormControlLayout__prepend + .euiFormControlLayout__childrenWrapper:last-child {
  border-radius: 0;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--compressed.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper:first-child {
  border-radius: 4px 0 0 4px;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--compressed .euiFormControlLayout__childrenWrapper:only-child {
  border-radius: 4px;
  overflow: hidden;
}

.euiFormControlLayoutDelimited.euiFormControlLayout--compressed .euiFormControlLayout__prepend + .euiFormControlLayout__childrenWrapper:last-child {
  border-radius: 0 4px 4px 0;
}

.euiRadio .euiRadio__input:focus + .euiRadio__circle, .euiRadio .euiRadio__input:focus:focus-visible + .euiRadio__circle {
  outline-offset: 2px;
  outline: 2px solid #19c9c8;
}

.euiRadio .euiRadio__input:focus:not(:focus-visible) + .euiRadio__circle {
  outline: none;
}

.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square, .euiCheckbox .euiCheckbox__input:focus:focus-visible + .euiCheckbox__square {
  outline-offset: 2px;
  outline: 2px solid #19c9c8;
}

.euiCheckbox .euiCheckbox__input:focus:not(:focus-visible) + .euiCheckbox__square {
  outline: none;
}

.euiSwitch .euiSwitch__button:focus {
  outline-offset: 2px;
  outline: 2px solid;
}

.euiSwitch .euiSwitch__button:focus:focus-visible {
  outline-style: none;
}

.euiSwitch .euiSwitch__button:focus:not(:focus-visible), .euiSwitch .euiSwitch__button:focus .euiSwitch__track {
  outline: none;
}

.euiHeader {
  height: 48px;
  padding-left: 8px;
  padding-right: 8px;
}

.euiHeaderSectionItem:after {
  display: none !important;
}

.euiHeaderLogo {
  min-width: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

@media only screen and (width <= 574px) {
  .euiHeaderLogo {
    padding-left: 4px;
  }
}

.euiHeaderLogo__text {
  word-break: break-word;
  color: #dfe5ef;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.71429rem;
  overflow-wrap: break-word !important;
}

.euiHeader--default + .euiHeader--default {
  border-top: 1px solid #343741;
}

.euiHue {
  border-radius: 12px;
  height: 12px;
  margin: 8px 0;
  position: relative;
}

.euiHue:before, .euiHue:after {
  display: none;
}

.euiHue__range {
  top: -6px;
}

.euiHue__range::-webkit-slider-thumb {
  background-color: inherit;
  border: 3px solid #1d1e24;
  box-shadow: 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiHue__range::-moz-range-thumb {
  background-color: inherit;
  border: 3px solid #1d1e24;
  box-shadow: 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiHue__range::-ms-thumb {
  background-color: inherit;
  border: 3px solid #1d1e24;
  box-shadow: 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiHue__range:focus {
  outline: none;
}

.euiHue__range:focus::-webkit-slider-thumb {
  border: 3px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiHue__range:focus::-moz-range-thumb {
  border: 3px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiHue__range:focus::-ms-thumb {
  border: 3px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiHue__range:focus:not(:focus-visible)::-webkit-slider-thumb {
  box-shadow: 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiHue__range:focus:not(:focus-visible)::-moz-range-thumb {
  box-shadow: 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiHue__range:focus:not(:focus-visible)::-ms-thumb {
  box-shadow: 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiHue__range:focus:focus-visible {
  outline: none;
}

.euiKeyPadMenuItem {
  box-shadow: none;
  border: none !important;
}

.euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):hover, .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus:hover, .euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus-within {
  box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
}

.euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus, .euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled) {
  box-shadow: none;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):hover {
  box-shadow: 0 .7px 1.4px #0000002d, 0 1.9px 4px #00000020, 0 4.5px 10px #00000020;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled), .euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):hover, .euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):focus, .euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected:not(.euiKeyPadMenuItem-isDisabled):focus-within {
  color: #19c9c8;
  background-color: #19c9c833;
}

.euiKeyPadMenuItem.euiKeyPadMenuItem-isSelected.euiKeyPadMenuItem-isDisabled {
  color: #5a5f69;
  background-color: #51576133;
}

.euiKeyPadMenuItem__label {
  font-weight: 600;
}

.euiMarkdownEditorToolbar {
  border-radius: 0;
}

.euiMarkdownEditorTextArea:focus {
  outline: none;
}

.euiMarkdownEditorTextArea:focus:focus-visible {
  outline-style: none;
}

.euiMarkdownEditorPreview, .euiMarkdownEditorFooter {
  border-radius: 0;
}

.euiRangeTooltip__value {
  border-radius: 4px;
}

.euiRangeDraggable:focus {
  outline: none;
}

.euiRangeDraggable:focus ~ .euiRangeThumb {
  background-color: #19c9c8;
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiRangeDraggable:focus:not(:focus-visible) ~ .euiRangeThumb {
  outline: none;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiRangeDraggable:focus-visible {
  outline: none;
}

.euiRangeDraggable:focus-visible ~ .euiRangeThumb {
  background-color: #19c9c8;
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiRangeHighlight {
  z-index: 1;
  pointer-events: none;
}

.euiRangeHighlight__progress {
  background-color: #98a2b3;
  border-color: #98a2b3;
}

.euiRangeHighlight__progress--hasFocus {
  background-color: #19c9c8;
}

.euiRangeHighlight--compressed {
  top: calc(50% - 2px);
}

.euiRangeHighlight--compressed .euiRangeHighlight__progress {
  height: 4px;
}

.euiRangeHighlight--compressed.euiRangeHighlight--hasTicks {
  top: 6px;
}

.euiRangeHighlight:not(.euiRangeHighlight--compressed).euiRangeHighlight--hasTicks {
  top: 5px;
}

.euiRangeLevels .euiRangeLevel {
  margin-top: 0;
  margin-bottom: 0;
}

.euiRangeLevels .euiRangeLevel:first-child {
  margin-left: 0;
}

.euiRangeLevels .euiRangeLevel:last-child {
  margin-right: 0;
}

.euiRangeLevels--compressed .euiRangeLevel {
  height: 4px;
}

.euiRangeLevels--compressed .euiRangeLevel:first-child {
  margin-left: 0;
}

.euiRangeLevels--compressed .euiRangeLevel:last-child {
  margin-right: 0;
}

.euiRangeLevels--compressed .euiRangeThumb--hasTicks {
  top: 0;
}

.euiRangeSlider {
  z-index: 2;
}

.euiRangeSlider::-webkit-slider-runnable-track {
  background-color: #0000;
}

.euiRangeSlider::-moz-range-track {
  background-color: #0000;
}

.euiRangeSlider::-ms-fill-lower {
  background-color: #0000;
}

.euiRangeSlider::-ms-fill-upper {
  background-color: #0000;
}

.euiRangeSlider--hasTicks {
  height: 16px;
}

.euiRangeSlider:focus {
  outline: none;
}

.euiRangeSlider:focus::-webkit-slider-thumb {
  background-color: #19c9c8;
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiRangeSlider:focus::-moz-range-thumb {
  background-color: #19c9c8;
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiRangeSlider:focus::-ms-thumb {
  background-color: #19c9c8;
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiRangeSlider:focus::-webkit-slider-runnable-track {
  background-color: #0000;
}

.euiRangeSlider:focus::-moz-range-track {
  background-color: #0000;
}

.euiRangeSlider:focus::-ms-fill-lower {
  background-color: #0000;
}

.euiRangeSlider:focus::-ms-fill-upper {
  background-color: #0000;
}

.euiRangeSlider:focus ~ .euiRangeHighlight .euiRangeHighlight__progress {
  background-color: #19c9c8;
}

.euiRangeSlider:focus:not(:focus-visible)::-webkit-slider-thumb {
  background-color: #98a2b3;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiRangeSlider:focus:not(:focus-visible)::-moz-range-thumb {
  background-color: #98a2b3;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiRangeSlider:focus:not(:focus-visible)::-ms-thumb {
  background-color: #98a2b3;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiRangeSlider:focus:not(:focus-visible) ~ .euiRangeHighlight .euiRangeHighlight__progress {
  background-color: #98a2b3;
}

.euiRangeSlider:disabled::-webkit-slider-thumb {
  background-color: #98a2b3;
}

.euiRangeSlider:disabled::-moz-range-thumb {
  background-color: #98a2b3;
}

.euiRangeSlider:disabled::-ms-thumb {
  background-color: #98a2b3;
}

.euiRangeSlider:disabled ~ .euiRangeThumb {
  background-color: #98a2b3;
}

.euiRangeThumb {
  z-index: 2;
  pointer-events: none;
  background-color: #98a2b3;
  border: 2px solid #1d1e24;
  box-shadow: 0 0 0 1px #1d1e24, 0 2px 2px -1px #0003, 0 1px 5px -2px #0003;
}

.euiRangeThumb--hasTicks {
  margin-top: 0;
  top: 0;
}

.euiRangeThumb:focus {
  background-color: #19c9c8;
  border: 2px solid #1d1e24;
  outline: none;
  box-shadow: 0 0 0 2px #19c9c8;
}

.euiRangeThumb:focus:focus-visible {
  outline: none;
}

.euiRangeTicks:not(.euiRangeTicks--compressed) .euiRangeTick {
  padding-top: 0;
}

.euiRangeTicks:not(.euiRangeTicks--compressed) .euiRangeTick:not(.euiRangeTick--hasTickMark):before, .euiRangeTicks:not(.euiRangeTicks--compressed) .euiRangeTick__pseudo {
  background-color: #343741;
  border-radius: 4px;
  width: 4px;
  height: 6px;
}

.euiRangeTicks--compressed .euiRangeTick {
  padding-top: 14px;
}

.euiRangeTicks--compressed .euiRangeTick:before, .euiRangeTicks--compressed .euiRangeTick .euiRangeTick__pseudo, .euiRangeTick:before {
  background-color: #343741;
  border-radius: 4px;
}

.euiRangeTick:enabled:hover, .euiRangeTick:focus, .euiRangeTick--selected {
  color: #19c9c8;
}

.euiRangeTick--selected {
  font-weight: 500;
}

.euiRangeTrack:after {
  content: "";
  background: #343741;
  border: 0 solid #343741;
  border-radius: 0;
  display: block;
  position: absolute;
  left: 0;
}

.euiRangeTrack:not(.euiRangeTrack--compressed):after {
  width: 100%;
  height: 6px;
}

.euiRangeTrack--compressed:after {
  width: 100%;
  height: 4px;
}

.euiRangeTrack--compressed.euiRangeTrack--hasLevels .euiRangeTicks {
  height: 18px;
  top: 16px;
}

.euiRangeTrack--compressed.euiRangeTrack--hasLevels .euiRangeTick {
  padding-top: 4px;
}

.euiRangeTrack--compressed:not(.euiRangeTrack--hasLevels) .euiRangeTicks {
  height: 20px;
  top: 12px;
}

.euiRangeTrack--compressed:not(.euiRangeTrack--hasLevels) .euiRangeTick {
  padding-top: 6px;
}

.euiRangeTrack--compressed.euiRangeTrack--hasTicks:after {
  top: 6px;
}

.euiRangeTrack--compressed:not(.euiRangeTrack--hasTicks):after {
  top: calc(50% - 2px);
}

.euiRangeTrack--compressed .euiRangeThumb--hasTicks {
  top: 0;
}

.euiRangeTrack--compressed .euiRangeLevels:not(.euiRangeLevels--hasTicks) {
  top: 19px;
}

.euiRangeTrack--compressed .euiRangeLevels--hasTicks {
  top: 11px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed).euiRangeTrack--hasLevels .euiRangeTicks {
  height: 20px;
  top: 20px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed).euiRangeTrack--hasLevels .euiRangeTick {
  padding-top: 6px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed):not(.euiRangeTrack--hasLevels) .euiRangeTicks {
  height: 24px;
  top: 16px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed):not(.euiRangeTrack--hasLevels) .euiRangeTick {
  padding-top: 11px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed).euiRangeTrack--hasTicks .euiRangeTooltip {
  top: -2px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed).euiRangeTrack--hasTicks:after {
  top: 5px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed):not(.euiRangeTrack--hasTicks):after {
  top: calc(50% - 3px);
}

.euiRangeTrack:not(.euiRangeTrack--compressed) .euiRangeLevels:not(.euiRangeLevels--hasTicks) {
  top: 24px;
}

.euiRangeTrack:not(.euiRangeTrack--compressed) .euiRangeLevels--hasTicks {
  top: 12px;
}

.euiRangeTooltip {
  z-index: 3;
}

.euiSideNavItem--root {
  padding-bottom: 8px;
}

.euiSideNavItem--root + .euiSideNavItem--root {
  margin-top: 8px;
  padding-top: 8px;
}

.euiSideNavItem--root > .euiSideNavItemButton {
  margin-bottom: 4px;
}

.euiSideNavItem--root > .euiSideNavItemButton .euiSideNavItemButton__label {
  word-break: break-word;
  color: inherit;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.71429rem;
  overflow-wrap: break-word !important;
}

.euiTab, .euiTabs--condensed .euiTab {
  padding: 0 4px;
}

.euiTab .euiTab__content, .euiTabs--condensed .euiTab .euiTab__content {
  word-break: break-word;
  color: #dfe5ef;
  font-size: 1rem;
  font-weight: 700;
  line-height: 40px;
  overflow-wrap: break-word !important;
}

.euiTab:focus, .euiTabs--condensed .euiTab:focus {
  background-color: #0000;
}

.euiTabs--small .euiTab, .euiTabs--condensed.euiTabs--small .euiTab {
  padding: 0 4px;
}

.euiTabs--small .euiTab .euiTab__content, .euiTabs--condensed.euiTabs--small .euiTab .euiTab__content {
  word-break: break-word;
  color: #dfe5ef;
  font-size: .857143rem;
  font-weight: 700;
  line-height: 32px;
  overflow-wrap: break-word !important;
}

.euiTabs--large .euiTab, .euiTabs--condensed.euiTabs--large .euiTab {
  padding: 0 4px;
}

.euiTabs--large .euiTab .euiTab__content, .euiTabs--condensed.euiTabs--large .euiTab .euiTab__content {
  word-break: break-word;
  color: #dfe5ef;
  font-size: 1.14286rem;
  font-weight: 700;
  line-height: 48px;
  overflow-wrap: break-word !important;
}

.euiTabs--large .euiTab + .euiTab, .euiTabs--condensed.euiTabs--large .euiTab + .euiTab {
  margin-left: 24px;
}

.euiTabs--xlarge .euiTab, .euiTabs--condensed.euiTabs--xlarge .euiTab {
  padding: 0 4px;
}

.euiTabs--xlarge .euiTab .euiTab__content, .euiTabs--condensed.euiTabs--xlarge .euiTab .euiTab__content {
  font-size: 1.42857rem;
  line-height: 48px;
}

.euiTabs--xlarge .euiTab + .euiTab, .euiTabs--condensed.euiTabs--xlarge .euiTab + .euiTab {
  margin-left: 32px;
}

.euiTabs .euiTab-isSelected, .euiTabs--condensed.euiTabs .euiTab-isSelected {
  color: #19c9c8;
}

.euiTabs .euiTab-isSelected:hover, .euiTabs .euiTab-isSelected:focus, .euiTabs--condensed.euiTabs .euiTab-isSelected:hover, .euiTabs--condensed.euiTabs .euiTab-isSelected:focus {
  cursor: pointer;
  text-decoration: underline;
}

.euiTabs .euiTab-isSelected:focus-visible, .euiTabs--condensed.euiTabs .euiTab-isSelected:focus-visible {
  box-shadow: none;
}

body {
  padding-bottom: 0 !important;
}

.euiEmptyPrompt {
  display: flex;
}

@media (width >= 768px) {
  .euiEmptyPrompt {
    min-height: 610px;
  }
}

.euiBadge {
  min-width: 16px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@media (width >= 768px) {
  .euiBadge {
    padding: 0 8px;
  }
}

.euiBadge--outline.euiBadge {
  margin-top: -1px;
  font-size: 12px;
}

.euiBadge--outline.euiBadge--secondary {
  color: #7de2d1 !important;
  background: none !important;
  border: 1px solid #7de2d1 !important;
}

.euiBadge:not(.euiBadge--outline).euiBadge--secondary {
  color: #000 !important;
  background: #7de2d1 !important;
}

.euiBottomBar {
  z-index: 999;
  background: #010207;
}

.euiButtonGroup--medium .euiButtonGroupButton, .euiButtonGroup--small .euiButtonGroupButton {
  border-color: #ffffff1a;
}

.euiButtonGroupButton:not([class*="isDisabled"]).euiButtonGroupButton-isSelected {
  background-color: #052a2a;
  border-color: #ffffff1a;
}

.euiButtonGroupButton:not([class*="isDisabled"]).euiButtonGroupButton-isSelected .euiIcon path {
  fill: #fff;
}

.euiButtonGroupButton:not([class*="isDisabled"]).euiButtonGroupButton-isSelected:hover, .euiButtonGroupButton:not([class*="isDisabled"]).euiButtonGroupButton-isSelected:focus {
  background-color: #004a49;
}

.euiButton {
  font-size: 14px !important;
}

.euiButton--outline {
  background: none !important;
  border: 1px solid !important;
}

.euiButton--small {
  height: 36px !important;
  line-height: 36px !important;
}

.euiButton--extraSmall {
  height: 28px !important;
  line-height: 28px !important;
}

.euiButton--tiny {
  min-width: auto !important;
  height: 22px !important;
  font-size: 12px !important;
  line-height: 22px !important;
}

.euiButton--action {
  min-width: 220px !important;
  font-size: 14px !important;
}

.euiButtonEmpty {
  height: 40px;
  font-family: Axiforma, Verdana, sans-serif;
  font-size: 14px;
}

.euiButtonEmpty--xSmall {
  font-size: 14px !important;
}

.euiFilterGroup .euiButtonEmpty__content {
  padding: 0 12px;
}

.euiFilterButton {
  height: 40px;
  font-size: 14px !important;
}

.euiFilterButton-hasActiveFilters {
  background: #052a2a;
  border: 1px solid #ffffff1a;
}

.euiGlobalToastList {
  width: 500px !important;
}

.euiToast {
  border-radius: 0;
  min-width: 400px;
}

.euiToast .euiText {
  font-size: 14px;
}

.euiCheckableCard, .euiCheckableCard .euiCheckableCard__label {
  align-items: center;
}

.euiCheckableCard--compact .euiCheckableCard__label {
  justify-content: space-between;
  font-size: 14px;
  display: flex;
}

.euiCheckableCard--compact .euiCheckableCard__label .euiBadge {
  align-content: center;
  display: flex;
}

.euiContextMenuPanel {
  font-size: 14px;
}

.euiContextMenuItem__text--separator-bottom {
  border-bottom: 1px solid #343741;
}

.euiContextMenuItem--danger {
  color: #f66 !important;
}

.euiContextMenuItem--danger:hover, .euiContextMenuItem--danger:focus {
  text-decoration: underline;
  color: #f33 !important;
}

.euiContextMenuItem--danger:focus {
  outline-offset: 0px;
  background-color: #f663;
  outline: 2px solid;
}

.euiContextMenuItem--danger:focus:focus-visible {
  outline-style: none;
}

.euiContextMenuItem--danger:focus:not(:focus-visible) {
  outline: none;
}

.euiContextMenuItem--align-right {
  text-align: right;
}

.euiContextMenuItem--separator-top {
  border-top: 1px solid #343741;
}

.euiContextMenuItem--separator-bottom {
  border-bottom: 1px solid #343741;
}

.euiContextMenuItem--small .euiContextMenuItem__text {
  font-size: 14px;
}

.euiFlexItem {
  position: relative;
}

.euiFlexItem--custom:last-child {
  align-items: flex-end;
  min-width: 215px;
}

.euiFlyout {
  border-left: 1px solid #343741;
  box-shadow: 0 40px 64px #0000001a, 0 24px 32px #0000001a, 0 16px 16px #0000001a, 0 8px 8px #0000001a, 0 4px 4px #0000001a, 0 2px 2px #0000001a !important;
}

.euiFlyout .euiFlyoutHeader {
  background: #1a1b20;
  border-bottom: 1px solid #343741;
  justify-content: space-between;
  align-items: center;
  min-height: 92px;
  display: flex;
  padding-block-start: 0 !important;
}

.euiFlyout .euiFlyoutHeader .euiTitle {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.euiFlyout .euiFlyoutHeader h2 {
  white-space: nowrap;
  padding-right: 22px;
  font-size: 16px;
  font-weight: 700;
}

.euiFlyout .euiFlyoutHeader h3 {
  text-align: right;
  font-weight: 400;
}

.euiFlyout .euiFlyoutHeader h3 div {
  flex-direction: column;
  display: flex;
}

.euiFlyout .euiFlyoutHeader h3 div a, .euiFlyout .euiFlyoutHeader h3 div span {
  font-size: 13px;
  line-height: 1.8;
}

.euiFlyout .euiFlyoutHeader h3 div a:hover, .euiFlyout .euiFlyoutHeader h3 div a:focus {
  text-decoration: underline;
}

.euiFlyout .euiFlyoutBody__overflowContent {
  height: 100%;
}

.euiFlyout .euiFlyoutFooter {
  background: #1a1b20;
  border-top: 1px solid #343741;
  padding-top: 12px;
  padding-bottom: 12px;
}

.euiFlyout--custom {
  width: 50vw !important;
  max-width: 440px !important;
}

.euiForm {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.euiForm .euiForm__errors {
  margin-bottom: 40px;
  padding: 12px 16px;
}

.euiForm .euiForm__errors .euiCallOutHeader__title {
  font-size: 14px;
}

.euiFormFieldset--flushHorizontal {
  padding-left: 0;
  padding-right: 0;
}

.euiFormRow__labelWrapper {
  margin-bottom: 6px;
}

.euiFormRow__labelWrapper-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.euiFormRow__labelWrapper .euiFormHelpText {
  padding-top: 7px;
  font-weight: 400;
}

.euiFormRow__fieldWrapper-inner {
  position: relative;
}

.euiFormRow__fieldWrapper-aside {
  float: right;
  align-items: center;
  margin: -11px 0 0;
  display: flex;
}

.euiFormRow .euiLink {
  font-size: 13px;
}

.euiFormRow--label-full .euiFormRow__label {
  width: 100%;
}

.euiFormRow--datepicker-aside {
  min-height: 54px;
}

.euiFormRow--datepicker-aside .react-datepicker-popper {
  transform: translate3d(0, 28px, 0) !important;
}

.euiFormRow--datepicker-aside .euiFormControlLayout__childrenWrapper {
  position: static;
}

.euiFormRow--fill-vertical {
  flex: 1;
}

.euiFormRow--fill-vertical .euiFormRow__fieldWrapper {
  height: 100%;
}

.euiFieldText::placeholder, [type="search"][placeholder]::placeholder {
  color: #535966;
  transition: color .25s cubic-bezier(.22, .61, .35, 1);
}

.euiOverlayMask {
  padding-block-end: 0 !important;
  top: 0 !important;
}

.euiOverlayMask--centered-vertically {
  padding-bottom: 0;
}

.euiModal {
  border: 1px solid #ffffff1a;
}

.euiModal:not(.euiModal--confirmation) {
  min-height: 708px;
}

.euiModal > div {
  height: 100%;
}

.euiModal__closeIcon {
  top: 7px;
  right: 7px;
}

.euiModal__closeIcon svg {
  width: 22px;
  height: 22px;
}

@media (width >= 768px) {
  .euiModal .euiEmptyPrompt {
    min-height: 440px;
  }
}

.euiModal--prominent {
  background: repeating-linear-gradient(45deg, #ff666640, #ff666640 1px, #ff66660d 1px 20px);
  border: 1px solid #343741;
}

.euiModal--prominent > div {
  background: #1d1e24;
  border-radius: 4px;
  margin: 30px;
}

@media (width >= 768px) {
  .euiModal--prominent .euiModalHeader, .euiModal--prominent .euiModalBody, .euiModal--prominent .euiModalFooter {
    width: 100%;
  }
}

.euiPopover__panel {
  border: 1px solid #ffffff1a;
  box-shadow: 0 6px 12px -1px #0003, 0 4px 4px -1px #0003, 0 2px 2px #0003;
}

.euiPopover__panel .euiPopover__panelArrow {
  width: 0;
  height: 0;
  position: absolute;
}

.euiPopover__panel .euiPopover__panelArrow:before, .euiPopover__panel .euiPopover__panelArrow:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--left:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-left: 12px solid #343741;
  top: 50%;
  right: -11px;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--left:after {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-left: 12px solid #1d1e24;
  top: 50%;
  right: -10px;
}

.euiPopover--preview .euiPopover__panel-inner {
  font-size: 0;
}

.euiPopover--preview .euiPopover__panel-inner, .euiPopover--preview .euiPopover__panel-inner img {
  max-height: 180px;
}

.euiPopover--preview .euiPopover__panelArrow--left {
  top: calc(50% - 12px);
  right: -2px;
}

.euiPopover--panel .euiPopover__panel-inner {
  min-width: 300px;
  padding: 16px;
}

.euiPopover--panel .euiPopover__panel-inner-list-item {
  margin-bottom: 14px;
}

.euiPopover--panel .euiPopover__panel-inner-list-item:last-child {
  margin-bottom: 0;
}

.euiPopover--panel .euiPopover__panelArrow--left {
  top: 58px;
  right: -2px;
}

.euiPopover--panel .euiPopoverTitle {
  text-transform: none;
}

.euiPopoverTitle {
  border-bottom: 1px solid #343741;
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.euiModalHeader__title {
  letter-spacing: 0;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
}

.euiModalHeader__title strong {
  font-weight: 700;
}

.euiModalBody__inner--presentation-views, .euiModalBody__inner--presentation-links, .euiModalBody__inner--presentation-sessions, .euiModalBody__inner--media-issues, .euiModalBody__inner--media-presentations {
  width: 100%;
  height: 43.5vw;
}

.euiModalBody__inner--presentation-views .c-table, .euiModalBody__inner--presentation-links .c-table, .euiModalBody__inner--presentation-sessions .c-table, .euiModalBody__inner--media-issues .c-table, .euiModalBody__inner--media-presentations .c-table {
  padding-bottom: 28px;
}

.euiModalBody__inner--presentation-views .c-table .euiTableCellContent, .euiModalBody__inner--presentation-links .c-table .euiTableCellContent, .euiModalBody__inner--presentation-sessions .c-table .euiTableCellContent, .euiModalBody__inner--media-issues .c-table .euiTableCellContent, .euiModalBody__inner--media-presentations .c-table .euiTableCellContent {
  padding: 14px 8px;
}

@media (width >= 1150px) {
  .euiModalBody__inner--presentation-views, .euiModalBody__inner--presentation-links, .euiModalBody__inner--presentation-sessions, .euiModalBody__inner--media-issues, .euiModalBody__inner--media-presentations {
    width: 1150px;
    height: 604px;
  }
}

.euiModalBody__inner--presentation-views {
  justify-content: center;
  align-items: center;
  display: flex;
}

.euiModalBody__inner--presentation-links {
  padding: 16px 0;
}

.euiModalBody__inner--presentation-links .c-table .euiTableCellContent {
  padding: 8px;
}

.euiModalBody__inner--presentation-sessions {
  padding: 16px 0;
}

.euiModalBody__inner--presentation-sessions .center {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.euiModalBody__inner--presentation-sessions .session:last-child {
  padding-bottom: 20px;
}

.euiModalBody__inner--presentation-sessions .euiLoadingChart {
  transform: rotate(90deg);
}

.euiModalBody__inner--presentation-sessions .euiAccordion__triggerWrapper {
  margin-left: -10px;
  padding: 2px 0;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__button {
  padding: 6px 0;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__button:hover, .euiModalBody__inner--presentation-sessions .euiAccordion__button:focus {
  text-decoration: none;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__button:hover .datetime, .euiModalBody__inner--presentation-sessions .euiAccordion__button:focus .datetime {
  text-decoration: underline;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__buttonContent {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__buttonContent .left {
  align-items: center;
  display: flex;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__buttonContent .left .column {
  min-width: 74px;
  display: inline-flex;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__buttonContent span.client {
  opacity: .5;
}

.euiModalBody__inner--presentation-sessions .euiAccordion__buttonContent .euiNotificationBadge {
  cursor: pointer;
  margin-right: 16px;
}

.euiModalBody__inner--presentation-sessions .euiAccordion-isOpen .datetime {
  font-weight: 700;
}

.euiModalBody__inner--presentation-sessions .euiAccordion-isOpen .euiAccordion__childWrapper > div > div {
  padding-bottom: 20px;
}

.euiModalBody__inner--presentation-sessions .dots {
  align-items: center;
  margin: 0 0 0 22px;
  padding: 10px 0;
  list-style: none;
  display: flex;
  overflow: auto hidden;
}

.euiModalBody__inner--presentation-sessions .dots-dot {
  flex-direction: column;
  justify-content: center;
  width: auto;
  min-width: 140px;
  padding: 0 10px;
  display: flex;
}

.euiModalBody__inner--presentation-sessions .dots-dot:first-child {
  padding-left: 5px;
}

.euiModalBody__inner--presentation-sessions .dots-dot:last-child {
  padding-right: 5px;
}

.euiModalBody__inner--presentation-sessions .dots-dot-button {
  cursor: pointer;
  border: none;
  height: 96px;
  padding: 10px;
}

.euiModalBody__inner--presentation-sessions .dots-dot-button-img {
  object-fit: contain;
  height: 100%;
}

.euiModalBody__inner--presentation-sessions .dots-dot-text {
  justify-content: center;
  align-items: center;
  margin: 16px 0 0;
  display: flex;
}

.euiModalBody__inner--presentation-sessions .dots-dot-text-icon {
  margin-top: -1px;
  margin-right: 6px;
}

.euiModalBody__inner--presentation-sessions .dots-dot .c-tooltip-button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.euiModalBody__inner--presentation-sessions .dots-dot .c-tooltip-button__tooltip {
  margin-top: 1px;
}

.euiModalBody__inner--presentation-sessions .dots-dot .c-tooltip-button__tooltip-content-inner {
  white-space: normal;
  max-width: 140px;
}

.euiModalBody__inner--presentation-sessions .dots-dot[data-num-events]:not([data-num-events="0"]) .dots-dot-button {
  outline: 2px solid #f990c0;
}

.euiNotificationBadge {
  vertical-align: middle;
  text-align: center;
  cursor: default;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 500;
  line-height: 16px;
  min-width: 16px !important;
  height: 16px !important;
  font-size: 12px !important;
}

.euiNotificationBadge--clickable {
  cursor: pointer;
}

.euiNotificationBadge--subdued {
  color: #fff;
  background: #71737a;
}

.euiNotificationBadge--secondary {
  background: #19c9c8;
}

.euiProgress {
  border-radius: 0 !important;
}

.euiProgress__valueText-value {
  font-weight: 700;
}

.euiProgress__valueText-value--unique {
  color: #f990c0;
}

.euiProgress__valueText-value--total {
  color: #19c9c8;
}

.euiProgress--unique::-webkit-progress-value {
  background-color: #f990c0;
}

.euiProgress--unique::-moz-progress-bar {
  background-color: #f990c0;
}

.euiProgress--total::-webkit-progress-value {
  background-color: #19c9c8;
}

.euiProgress--total::-moz-progress-bar {
  background-color: #19c9c8;
}

.euiSelectableMessage--loading, .euiSelectableMessage--error {
  justify-content: center;
  align-items: center;
  height: 110px;
  padding-top: 2px;
  display: flex;
}

.euiSelectableMessage--info {
  color: #ffffff80;
}

.euiStat .euiText {
  font-size: 14px;
  line-height: 21px;
}

.euiStat .euiTitle {
  letter-spacing: -.5px;
  font-size: 36px;
  font-weight: 300;
  line-height: 48px;
}

.euiStat--custom-color-1 .euiTitle, .euiStat--custom-color-1 .euiIcon {
  color: #a987d1;
}

.euiPaginationButton {
  font-size: 14px !important;
  text-decoration: none !important;
}

.euiSelectableListItem {
  font-size: 14px !important;
}

.euiFormLabel, .euiFormHelpText {
  font-size: 13px !important;
}

.euiPanel {
  background-color: #1d1e24;
  border-radius: 4px;
  flex-grow: 1;
}

.euiPanel--plain.euiPanel--isClickable {
  transition: background .25s cubic-bezier(.22, .61, .35, 1);
}

.euiPanel--plain.euiPanel--isClickable:hover, .euiPanel--plain.euiPanel--isClickable:focus {
  background: #282830;
}

.euiSuperSelectControl {
  align-items: center;
  display: flex;
}

body .euiTab {
  padding: 4px 16px;
}

body .euiTab .euiTab__content {
  font-weight: 400 !important;
}

body .euiTab.euiTab-isSelected {
  box-shadow: inset 0 -2px #19c9c8 !important;
}

body .euiTab.euiTab-isSelected, body .euiTab.euiTab-isSelected .euiTab__content {
  color: #19c9c8 !important;
}

.euiTabs--small .euiTab + .euiTab, .euiTabs--condensed.euiTabs--small .euiTab + .euiTab {
  margin-left: 16px !important;
}

.euiTabs--small .euiTab .euiTab__content, .euiTabs--condensed.euiTabs--small .euiTab .euiTab__content {
  line-height: 36px;
  font-weight: 600 !important;
}

.euiTab + .euiTab, .euiTabs--condensed .euiTab + .euiTab {
  margin-left: 0;
}

.euiToolTip {
  font-size: 14px !important;
}

.euiTable {
  font-size: 13px !important;
  line-height: 1 !important;
}

@media only screen and (width <= 574px) {
  .euiTable--responsive .euiTableRow {
    margin-bottom: 16px;
  }

  .euiTable--responsive .euiTableRow:last-child {
    margin-bottom: 0;
  }
}

.euiTable--responsive .euiTableRow:focus, .euiTable--responsive .euiTableRow:hover {
  background-color: #282830;
}

[data-loading="true"] .euiTable--responsive .euiTableRow:focus, [data-loading="true"] .euiTable--responsive .euiTableRow:hover {
  background-color: unset;
}

.euiTableFooterCell .euiTableCellContent__text, .euiTableHeaderCell .euiTableCellContent__text {
  font-size: 12px !important;
}

.euiButtonIcon {
  border-radius: 4px;
}

.euiTableRowCell .euiButtonIcon {
  padding: 4px;
  width: auto !important;
  height: auto !important;
}

.euiTableRowCell .euiHealth {
  font-size: 14px !important;
}

.euiTableRowCell .euiHealth .euiFlexItem:first-child {
  margin: 2px 2px 2px -3px;
}

@media (width >= 768px) {
  .euiTableRowCell .euiHealth .euiFlexItem:first-child {
    margin: 2px 7px 2px -3px;
  }
}

.euiTableCellContent--center {
  justify-content: center;
}

::-webkit-resizer {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

main {
  display: block;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

input {
  overflow: visible;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

details {
  display: block;
}

summary {
  display: list-item;
}

menu {
  display: block;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

:-moz-focusring {
  outline-offset: 2px !important;
  outline: 2px solid #343741 !important;
}

html {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  position: relative;
}

main {
  flex-grow: 1;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font-family: inherit;
}

abbr[title] {
  cursor: help;
}

::selection {
  color: #fff;
  background: #19c9c8;
}

html {
  background: #fff;
  font-size: 16px !important;
}

html ::-webkit-scrollbar {
  background-color: #1d1e24;
  width: 16px;
}

html ::-webkit-scrollbar-track {
  background-color: #1d1e24;
}

html ::-webkit-scrollbar-thumb {
  background-color: #19c9c8;
  border: 5px solid #1d1e24;
  border-radius: 16px;
}

html ::-webkit-scrollbar-thumb:hover {
  background-color: #19c9c8;
}

html ::-webkit-scrollbar-button {
  display: none;
}

html.p-presentation-viewer ::-webkit-scrollbar {
  background-color: #fff;
}

html.p-presentation-viewer ::-webkit-scrollbar-track {
  background-color: #fff;
}

html.p-presentation-viewer ::-webkit-scrollbar-thumb {
  background-color: #1d1e24;
  border: 5px solid #fff;
}

html.p-presentation-viewer ::-webkit-scrollbar-thumb:hover {
  background-color: #1d1e24;
}

html[data-scrollbar="footer"] ::-webkit-scrollbar {
  background-color: #191a1a;
}

html[data-scrollbar="footer"] ::-webkit-scrollbar-thumb {
  background-color: #191a1a;
}

html[data-scrollbar="footer"] ::-webkit-scrollbar-thumb:hover {
  background-color: #191a1a;
}

body {
  color: #e6e6e6;
  background-color: #1d1e24;
  flex-direction: column;
  font-family: Axiforma, Verdana, sans-serif;
  font-weight: 400;
  display: flex;
  overflow-x: hidden;
}

.app {
  min-height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
}

.app-enter .c-layout {
  opacity: 0;
}

.app-enter-active .c-layout {
  opacity: 1;
  transition: opacity .25s cubic-bezier(.22, .61, .35, 1), transform .25s cubic-bezier(.22, .61, .35, 1);
}

.app-exit .c-layout {
  opacity: 1;
}

.app-exit-active .c-layout {
  opacity: 0;
  transition: opacity .25s cubic-bezier(.22, .61, .35, 1), transform .25s cubic-bezier(.22, .61, .35, 1);
}

html.p-presentation-viewer body {
  background-color: #fff;
}

.u-display-none {
  display: none;
}

.u-display-block {
  display: block;
}

@media (width <= 639.9px) {
  br.u-hide--tablet-down {
    display: none !important;
  }
}

@media (width <= 1023.9px) {
  br.u-hide--desktop-down {
    display: none !important;
  }
}

@media (width <= 1349.9px) {
  br.u-hide--large-desktop-down {
    display: none !important;
  }
}

.u-show-for-sr {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  position: absolute !important;
}

.o-page {
  flex-direction: column;
  min-height: 99.9vh;
  padding: 0;
  display: flex;
}

.o-page__content {
  flex: 1;
  position: relative;
}

.o-page__content-tab {
  padding: 30px 0;
}

.c-changelog__list-item {
  margin: 0 0 48px;
  display: block;
}

.c-changelog__list-item:last-child {
  margin-bottom: 0;
}

.c-changelog__list-item-list {
  margin: 20px 0 0 40px;
  list-style: circle;
}

.c-changelog__list-item-list-item {
  margin: 10px 0;
  display: list-item;
}

.c-changelog__list-item-list-item code {
  border: 1px solid #858585;
  border-radius: 3px;
  margin: 0 2px;
  padding: 2px 4px;
}

@keyframes wonderscore-loading-spinner {
  12.5% {
    stroke-dasharray: 33.9887 242.777;
    stroke-dashoffset: -26.7054px;
  }

  43.75% {
    stroke-dasharray: 84.9718 242.777;
    stroke-dashoffset: -84.9718px;
  }

  100% {
    stroke-dasharray: 2.42777 242.777;
    stroke-dashoffset: -240.349px;
  }
}

.c-loading-spinner {
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  width: 300px;
  height: 200px;
  transition: opacity .5s cubic-bezier(.22, .61, .35, 1);
  display: inline-block;
  position: fixed;
  top: calc(50vh - 100px);
  left: calc(50% - 150px);
  overflow: hidden;
}

.c-loading-spinner__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)matrix(1, 0, 0, 1, 0, 0);
}

.c-loading-spinner__image-outline {
  stroke-dasharray: 2.42777 242.777;
  stroke-dashoffset: 0;
  fill: none;
  stroke: #19c9c8;
  stroke-width: 4px;
  animation: 1.6s linear infinite wonderscore-loading-spinner;
}

.c-loading-spinner__image-outline-bg {
  opacity: .05;
  fill: none;
  stroke: #858585;
  stroke-width: 4px;
}

.c-loading-spinner--obscure {
  background: none;
}

.c-loading-spinner--obscure:before {
  content: "";
  background: #1d1e24b3;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
}

html.p-presentation-viewer .c-loading-spinner--obscure:before {
  background: #ffffffb3;
}

.c-loading-spinner--size-small {
  width: 150px;
  height: 100px;
  top: calc(50vh - 50px);
  left: calc(50% - 75px);
}

.c-loading-spinner.is-visible {
  pointer-events: all;
  opacity: 1;
}
/*# sourceMappingURL=index.beb6541c.css.map */
