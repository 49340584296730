@font-face {
  font-family: 'Axiforma';
  src: url('../../assets/fonts/A-R.eot');
  src: url('../../assets/fonts/A-R.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/A-R.woff2') format('woff2'),
    url('../../assets/fonts/A-R.woff') format('woff'),
    url('../../assets/fonts/A-R.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Axiforma';
  src: url('../../assets/fonts/A-B.eot');
  src: url('../../assets/fonts/A-B.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/A-B.woff2') format('woff2'),
    url('../../assets/fonts/A-B.woff') format('woff'),
    url('../../assets/fonts/A-B.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
