@use 'sass:math';


.euiSearchBar__searchHolder {
  min-width: math.div($euiFormMaxWidth, 2);
}

@include euiBreakpoint('m', 'l', 'xl') {
  .euiSearchBar__filtersHolder {
    // Helps with flex-wrapping
    max-width: calc(100% - #{$euiSize});
  }
}
