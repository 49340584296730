.euiSplitPanel {
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;

  .euiSplitPanel__inner {
    flex-basis: 0%; // Make sure they're evenly split
    // Ensure no movement if they have click handlers
    transform: none !important; // sass-lint:disable-line no-important
    box-shadow: none !important; // sass-lint:disable-line no-important
  }
}

.euiSplitPanel--row {
  flex-direction: row;

  &.euiSplitPanel-isResponsive {
    flex-direction: column;
  }
}
