@use 'sass:math';


.euiTableRowCell__mobileHeader {
  // Don't display by default unless table is responsive
  display: none;
}

@include euiBreakpoint('xs', 's') {
  .euiTableRowCell--hideForMobile { // must come last to override any special cases
    // sass-lint:disable-block no-important
    display: none !important;
  }
}

@include euiBreakpoint('m', 'l', 'xl') {
  .euiTableRowCell--hideForDesktop { // must come last to override any special cases
    // sass-lint:disable-block no-important
    display: none !important;
  }
}

@include euiBreakpoint('xs', 's') {

  .euiTable.euiTable--responsive {
    // Not allowing compressed styles in mobile view (for now)

    thead {
      display: none; // Use mobile versions of selecting and filtering instead
    }

    tfoot {
      display: none; // Not supporting responsive footer content
    }

    .euiTableRowCell__mobileHeader {
      // Always truncate
      @include euiTextTruncate;
      @include fontSize($euiFontSize * .6875);

      display: block;
      color: $euiColorDarkShade;
      padding: $euiSizeS;
      padding-bottom: 0;
      margin-bottom: -$euiSizeS; // pull up cell content closer
      min-height: $euiSizeL; // aligns contents of cells if header doesn't exist

      // Remove min-height of cell header if it's the only cell
      .euiTableRowCell:only-child & {
        min-height: 0;
      }
    }

    .euiTableRowCell--enlargeForMobile {
      @include euiFontSizeM;
    }

    // Make each row a Panel
    @include euiPanel($selector: '.euiTableRow');

    .euiTableRow {
      @include euiBottomShadowSmall;
      background-color: map-get($euiPanelBackgroundColorModifiers, 'plain');
      border-radius: $euiBorderRadius;
      display: flex;
      flex-wrap: wrap;
      padding: $euiTableCellContentPadding;
      margin-bottom: $euiTableCellContentPadding;

      &:hover {
        background-color: map-get($euiPanelBackgroundColorModifiers, 'plain');
      }

      &.euiTableRow-isExpandable,
      &.euiTableRow-hasActions {
        @include euiTableActionsBackgroundMobile;
        padding-right: $euiSizeXXL;
        position: relative;
      }

      &.euiTableRow-isExpandable .euiTableRowCell--isExpander,
      &.euiTableRow-hasActions .euiTableRowCell--hasActions {
        min-width: 0;
        width: $euiSizeL;
        position: absolute;
        top: $euiTableCellContentPadding + (($euiTableCellContentPadding * $euiLineHeight) - $euiTableCellContentPadding) + $euiSizeXS; // same as row padding-top + cell padding + 1/2 line height
        right: $euiTableCellContentPadding;

        &::before {
          display: none; // Don't display table headers
        }

        .euiTableCellContent {
          flex-direction: column;
          padding: 0;

          .euiLink {
            padding: $euiSizeXS;
          }
        }
      }

      &.euiTableRow-hasActions.euiTableRow-isExpandable {
        .euiTableRowCell--isExpander {
          top: auto;
          bottom: $euiSize; // same as row padding-bottom
          right: 0;
        }
      }

      &.euiTableRow-isSelectable {
        padding-left: $euiTableCellCheckboxWidth + math.div($euiTableCellContentPadding, 2);
        position: relative;

        .euiTableRowCellCheckbox {
          position: absolute;
          left: math.div($euiTableCellContentPadding, 2);
          top: $euiSizeS;
        }
      }

      // override hover & selected colors
      &.euiTableRow-isSelected {
        &,
        &:hover,
        + .euiTableRow.euiTableRow-isExpandedRow,
        &:hover + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
          background-color: $euiTableSelectedColor;
        }
      }

      &.euiTableRow-isExpandedRow {
        @include euiTableActionsBackgroundMobile;
        @include euiBottomShadowSmall;
        margin-top: -$euiTableCellContentPadding * 2;
        position: relative;
        z-index: 2; // on top of its parent/previous row
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-left: $euiSizeS; // override selectable as the padding is already applied via the contents

        &:hover {
          background-color: $euiColorEmptyShade; // keep white background to cover triggering row's border
        }

        .euiTableRowCell {
          width: calc(100% - #{$euiSizeXXL});

          &::before {
            display: none;
          }
        }
      }
    }

    .euiTableRowCell {
      min-width: 50%;
      border: none;
    }

    .euiTableRowCellCheckbox {
      border: none;
    }

    // never show hidden items and always show hover items on mobile,
    .euiTableRow-hasActions .euiTableCellContent--showOnHover {
      > * {
        margin-left: 0;
      }

      .expandedItemActions__completelyHide {
        display: none;
      }

      .euiTableCellContent__hoverItem {
        opacity: 1;
        filter: none;
        margin-left: 0;
        margin-bottom: $euiSizeS;
      }
    }

    // force all content back to left side
    .euiTableCellContent--alignRight {
      justify-content: flex-start;
    }

    .euiTableCellContent--alignCenter {
      justify-content: flex-start;
    }
  }
}
