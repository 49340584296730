.euiTabs {
  @include euiScrollBar;
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden; // don't scroll vertically when scrolling horizontally
  position: relative;
  flex-shrink: 0; // don't ever let this shrink in height if direct descendent of flex

  &--bottomBorder {
    box-shadow: inset 0 ($euiBorderWidthThin * -1) 0 $euiBorderColor;
  }

  // Changing height of scrollbar so it sits flush with border
  // sass-lint:disable no-vendor-prefixes
  &::-webkit-scrollbar {
    height: 2px;
  }
}

.euiTab {
  color: $euiTextColor;
  padding: $euiSizeM $euiSize;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  .euiTab__content {
    @include euiTextTruncate;
    @include euiFontSize;
    color: $euiTextColor;
    line-height: $euiLineHeight;
    display: block;
  }

  &:not(.euiTab-isDisabled):hover .euiTab__content,
  &:not(.euiTab-isDisabled):focus .euiTab__content {
    text-decoration: underline;
  }

  &:focus {
    background-color: $euiFocusBackgroundColor;
    outline-offset: -$euiFocusRingSize;
  }

  &.euiTab-isSelected {
    box-shadow: inset 0 ($euiBorderWidthThick * -1) 0 $euiColorPrimaryText;

    &,
    .euiTab__content {
      color: $euiColorPrimaryText !important; // sass-lint:disable-line no-important
    }
  }

  &.euiTab-isDisabled {
    &,
    .euiTab__content {
      color: $euiColorDisabledText !important; // sass-lint:disable-line no-important
    }

    &:hover {
      cursor: not-allowed;
    }

    &.euiTab-isSelected {
      box-shadow: inset 0 ($euiBorderWidthThick * -1) 0 $euiColorDisabledText;
    }
  }

  // Sizes

  .euiTabs--small & {
    padding: $euiSizeS;

    .euiTab__content {
      @include euiFontSizeS;
    }
  }

  .euiTabs--large & {
    padding: $euiSizeM $euiSize;
  }

  .euiTabs--xlarge & {
    padding: $euiSizeS $euiSizeL;
    line-height: $euiSize * 4.5;
    height: $euiSize * 4.5;

    .euiTab__content {
      @include euiFontSizeL;
    }
  }

  // Expand Tabs Group Modifier

  .euiTabs--expand & {
    flex-basis: 0%;
    flex-grow: 1;
    justify-content: center;
  }
}

.euiTab__prepend {
  margin-right: $euiSizeS;
}

.euiTab__append {
  margin-left: $euiSizeS;
}
