/**
 * 1. Default to grid of 3
 */
.euiKeyPadMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ($euiKeyPadMenuSize * 3) + ($euiKeyPadMenuMarginSize * 3);
  max-width: 100%;
  // Using negative margins on the whole menu negates the ones on the (last) items no matter how many exist per row
  margin-bottom: -$euiKeyPadMenuMarginSize;
  margin-right: -$euiKeyPadMenuMarginSize;

  legend {
    margin-bottom: $euiSizeS;
  }

  > *:not(legend) {
    margin-bottom: $euiKeyPadMenuMarginSize;
    margin-right: $euiKeyPadMenuMarginSize;
  }
}
