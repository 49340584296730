// Fixed heights ensure proper alignment with pixel grid

// MEDIUM
.euiTab,
.euiTabs--condensed .euiTab {
  padding: 0 $euiSizeXS;

  .euiTab__content {
    @include euiTitle('xxs');
    line-height: $euiSizeXXL;
  }

  & + .euiTab {
    margin-left: $euiSize;
  }

  &:focus {
    background-color: transparent;
  }
}

.euiTabs,
.euiTabs--condensed.euiTabs {
  // SMALL
  &--small .euiTab {
    padding: 0 $euiSizeXS;

    .euiTab__content {
      @include euiTitle('xxxs');
      line-height: $euiSizeXL;
    }

    & + .euiTab {
      margin-left: $euiSizeM;
    }
  }

  // LARGE
  &--large .euiTab {
    padding: 0 $euiSizeXS;

    .euiTab__content {
      @include euiTitle('xs');
      line-height: $euiSizeXXL + $euiSizeS;
    }

    & + .euiTab {
      margin-left: $euiSizeL;
    }
  }

  // X-LARGE
  &--xlarge .euiTab {
    padding: 0 $euiSizeXS;

    .euiTab__content {
      @include fontSize($euiSize + $euiSizeXS);
      line-height: $euiSizeXXL + $euiSizeS;
    }

    & + .euiTab {
      margin-left: $euiSizeXL;
    }
  }

  // SELECTED
  .euiTab-isSelected {
    color: $euiColorPrimaryText;

    &:hover,
    &:focus {
      text-decoration: underline;
      cursor: pointer;
    }

    &:focus-visible {
      box-shadow: none;
    }
  }
}
