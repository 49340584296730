.euiComboBox {
  // Round the left border when we append a label
  &--appended {
    .euiFormControlLayout__childrenWrapper {
      border-top-left-radius: $euiFormControlBorderRadius;
      border-bottom-left-radius: $euiFormControlBorderRadius;
    }

    // Half size rounded left corners when compressed
    .euiFormControlLayout--compressed {
      .euiFormControlLayout__childrenWrapper {
        border-top-left-radius: $euiFormControlCompressedBorderRadius;
        border-bottom-left-radius: $euiFormControlCompressedBorderRadius;
      }
    }

    // No rounded corners when we have both appended and prepended labels.
    // This includes full height and compressed height comboboxes.
    &.euiComboBox--prepended {
      .euiFormControlLayout__childrenWrapper {
        border-radius: 0;
      }

      .euiFormControlLayout--compressed {
        .euiFormControlLayout__childrenWrapper {
          border-radius: 0;
        }
      }
    }
  }

  // Round the right border when we prepend a label
  &--prepended {
    .euiFormControlLayout__childrenWrapper {
      border-top-right-radius: $euiFormControlBorderRadius;
      border-bottom-right-radius: $euiFormControlBorderRadius;
    }

    // Half size rounded right corners when compressed
    .euiFormControlLayout--compressed {
      .euiFormControlLayout__childrenWrapper {
        border-top-right-radius: $euiFormControlCompressedBorderRadius;
        border-bottom-right-radius: $euiFormControlCompressedBorderRadius;
      }
    }
  }
}
