.euiRadio .euiRadio__input {
  &:focus {
    @include euiCustomControlFocused('.euiRadio__circle');
  }
}

.euiCheckbox .euiCheckbox__input {
  &:focus {
    @include euiCustomControlFocused('.euiCheckbox__square');
  }
}

.euiSwitch .euiSwitch__button:focus {
  @include euiFocusRing(null, 'outer');

  .euiSwitch__track {
    outline: none;
  }
}
