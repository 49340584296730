////
/// wonderscore Page Object
/// @group Page
////

@import '../core/settings';
@import '../core/branding';


/// Default object name
/// @type String
$wonderscore-page-name: 'o-page';


@mixin wonderscore-page {
  $name: $wonderscore-page-name;

  .#{$name} {
    display: flex;
    flex-direction: column;
    min-height: 99.9vh;
    padding: 0;

    &__content {
      position: relative;
      flex: 1;

      &-tab {
        padding: 30px 0;
      }
    }
  }
}
