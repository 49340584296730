/**
 * 1. Focused state overrides invalid state.
 * 2. Disabled state overrides pointer.
 */
.euiFormLabel {
  @include euiFormLabel;
  display: inline-block;
  transition: all $euiAnimSpeedFast $euiAnimSlightResistance;

  &.euiFormLabel-isInvalid {
    color: $euiColorDanger; /* 1 */
  }

  &.euiFormLabel-isFocused {
    color: $euiColorPrimary; /* 1 */
  }

  &[for] {
    cursor: pointer; /* 2 */
  }

  &[for].euiFormLabel-isDisabled {
    cursor: default; /* 2 */
  }
}
