.euiSelectableListItem {
  @include euiFontSizeS;
  display: inline-flex; // Necessary to make sure it doesn't force the whole popover to be too wide
  width: 100%;
  text-align: left;
  cursor: pointer;
  overflow: hidden;

  &:not(:last-of-type) {
    border-bottom: $euiSelectableListItemBorder;
  }

  &-isFocused:not([aria-disabled='true']),
  &:hover:not([aria-disabled='true']) {
    color: $euiColorPrimaryText;
    background-color: $euiFocusBackgroundColor;

    .euiSelectableListItem__text {
      text-decoration: underline;
    }
  }

  &[aria-disabled='true'] {
    color: $euiColorMediumShade;
    cursor: not-allowed;
  }

  &--paddingSmall {
    .euiSelectableListItem__content {
      padding: $euiSelectableListItemPadding;
    }
  }
}

.euiSelectableListItem__content {
  width: 100%;
  display: flex;
  align-items: center;
}

.euiSelectableListItem__icon,
.euiSelectableListItem__prepend {
  margin-right: $euiSizeM;
  flex-shrink: 0;
}

.euiSelectableListItem__append {
  margin-left: $euiSizeM;
  flex-shrink: 0;
}

.euiSelectableListItem__text {
  flex-grow: 1; // Pushes appended content to the far right

  &--truncate {
    @include euiTextTruncate;
  }
}
