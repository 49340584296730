/*
 * 1. Fix for Safari to ensure that it renders like a normal text input
 *    and doesn't add extra spacing around text
*/

.euiFieldSearch {
  @include euiFormControlStyle;
  @include euiFormControlWithIcon($isIconOptional: false);
  @include euiFormControlIsLoading;

  // sass-lint:disable-block no-vendor-prefixes
  -webkit-appearance: textfield; /* 1 */

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;  /* 1, 2 */
  }
}

.euiFieldSearch--compressed {
  @include euiFormControlWithIcon($isIconOptional: false, $side: 'left', $compressed: true);
}
