/////
/// wonderscore Loading Spinner Component
/// @group LoadingSpinner
////

@import '../../styles/core/branding';


/// Default component name
/// @type String
$wonderscore-loading-spinner-name: 'c-loading-spinner';

/// Default size (width and height)
/// @type Number
$wonderscore-loading-spinner-width: 300px;
$wonderscore-loading-spinner-height: 200px;

/// Default spinner length
/// @type Number
$wonderscore-loading-spinner-length: 242.776657104492px;

/// Default spinner loop duration
/// @type Number
$wonderscore-loading-spinner-duration: 1.6s;


@keyframes wonderscore-loading-spinner {
  12.5% {
    stroke-dasharray: $wonderscore-loading-spinner-length*0.14, $wonderscore-loading-spinner-length;
    stroke-dashoffset: -$wonderscore-loading-spinner-length*0.11;
  }
  43.75% {
    stroke-dasharray: $wonderscore-loading-spinner-length*0.35, $wonderscore-loading-spinner-length;
    stroke-dashoffset: -$wonderscore-loading-spinner-length*0.35;
  }
  100% {
    stroke-dasharray: $wonderscore-loading-spinner-length*0.01, $wonderscore-loading-spinner-length;
    stroke-dashoffset: -$wonderscore-loading-spinner-length*0.99;
  }
}


.#{$wonderscore-loading-spinner-name} {
  display: inline-block;
  position: fixed;
  left: calc(50% - #{($wonderscore-loading-spinner-width * 0.5)});
  top: calc(50vh - #{($wonderscore-loading-spinner-height * 0.5)});
  width: $wonderscore-loading-spinner-width;
  height: $wonderscore-loading-spinner-height;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;

  transition: opacity $wonderscore-animation-duration--medium $wonderscore-animation-curve;
  will-change: opacity;

  &__image {
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0);

    &-outline {
      stroke-dasharray: ($wonderscore-loading-spinner-length * 0.01), $wonderscore-loading-spinner-length;
      stroke-dashoffset: 0;
      animation: wonderscore-loading-spinner $wonderscore-loading-spinner-duration linear infinite;

      fill: none;
      stroke: $wonderscore-color-blue-martina;
      stroke-width: 4;

      &-bg {
        opacity: 0.05;
        fill: none;
        stroke: $wonderscore-color-namara-grey;
        stroke-width: 4;
      }
    }
  }


  // variants
  &--obscure {
    background: transparent;

    &::before {
      content: '';
      display: inline-block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $euiColorEmptyShade--70;

      // TODO: implement better decoupled way of doing this?
      @at-root {
        html.p-presentation-viewer & {
          background: $wonderscore-color-white-70;
        }
      }
    }
  }


  // sizes
  &--size-small {
    left: calc(50% - #{(($wonderscore-loading-spinner-width * 0.5) * 0.5)});
    top: calc(50vh - #{(($wonderscore-loading-spinner-height * 0.5) * 0.5)});
    width: ($wonderscore-loading-spinner-width * 0.5);
    height: ($wonderscore-loading-spinner-height * 0.5);

    //.#{$wonderscore-loading-spinner-name} {
    //  &__image {
    //    width: 300px;
    //    height: 200px;
    //  }
    //}
  }


  // states
  &.is-visible {
    pointer-events: all;
    opacity: 1;
  }
}
