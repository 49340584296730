/**
 * 1. Account for inner box-shadow style border
 */

.euiDatePickerRange {
  @include euiFormControlSize(auto, $includeAlternates: true);
  // Match just the regular drop shadow of inputs
  @include euiFormControlDefaultShadow;
  display: flex;
  align-items: center;
  padding: 1px; /* 1 */

  // Allow any child to fill entire range container
  > * {
    flex-grow: 1;
  }

  .euiFieldText.euiDatePicker {
    height: $euiFormControlLayoutGroupInputHeight;
    // including all states
    box-shadow: none !important; // sass-lint:disable-line no-important
    text-align: center;
  }

  .euiDatePickerRange__start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .euiDatePickerRange__end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .react-datepicker-popper .euiFieldText.euiDatePicker {
    // set any subsequent children in popper back to left align
    text-align: left;
  }

  // Necessary for EuiSuperDatePicker
  &--inGroup {
    box-shadow: none;
    padding: 0;
  }
}

.euiDatePickerRange--isDisabled {
  background: $euiFormBackgroundDisabledColor;
}

.euiDatePickerRange--readOnly {
  background: $euiFormBackgroundReadOnlyColor;
}

.euiDatePickerRange__delimeter {
  align-self: stretch;
  height: auto;
  flex-grow: 0;
  display: flex;
  align-items: center;
}

.euiDatePickerRange--isInvalid .euiDatePickerRange__delimeter {
  @include euiFormControlInvalidStyle;
}
