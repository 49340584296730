/**
 * 1. Ensure the descenders don't get cut off
 */

.euiSelect {
  @include euiFormControlStyle;

  appearance: none;
  line-height: $euiFormControlHeight; /* 1 */
  padding-top: 0; /* 1 */
  padding-bottom: 0; /* 1 */

  &--compressed {
    line-height: $euiFormControlCompressedHeight; /* 1 */
    padding-top: 0; /* 1 */
    padding-bottom: 0; /* 1 */
  }

  &--inGroup {
    line-height: $euiFormControlLayoutGroupInputHeight; /* 1 */
  }

  &--inGroup#{&}--compressed {
    line-height: $euiFormControlLayoutGroupInputCompressedHeight; /* 1 */
  }

  // Turn off linter for some MS specific bits.
  // sass-lint:disable no-vendor-prefixes
  &::-ms-expand {
    display: none;
  }

  &:focus::-ms-value {
    color: $euiTextColor;
    background: transparent;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $euiTextColor;
  }
}
