.euiDatePicker {
  &.euiDatePicker--shadow {
    .react-datepicker-popper,
    .react-datepicker-popper[data-placement^='top'] {
      border: none;
      border-radius: $euiBorderRadius;
    }
  }
}

.euiDatePickerRange {
  border-radius: $euiFormControlBorderRadius;
}

.euiDatePicker.euiDatePicker--shadow.euiDatePicker--inline .react-datepicker {
  border: none;
}

.euiSuperDatePicker__prettyFormat {
  border-top-right-radius: $euiFormControlBorderRadius;
  border-bottom-right-radius: $euiFormControlBorderRadius;
}

.euiFormControlLayout--compressed.euiSuperDatePicker .euiSuperDatePicker__prettyFormat {
  border-top-right-radius: $euiFormControlCompressedBorderRadius;
  border-bottom-right-radius: $euiFormControlCompressedBorderRadius;
}
