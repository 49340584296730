@use 'sass:math';


/**
  * 1. Float above the visual radio and match its dimension, so that when users try to click it
  *    they actually click this input.
  */

.euiCheckbox {
  position: relative;

  // Set a top offset for the real input and faux input to align better with the text
  $topOffset: 7px;

  .euiCheckbox__input {
    @include size($euiCheckBoxSize);
    top: $topOffset;
    cursor: pointer;
    position: absolute; /* 1 */
    opacity: 0; /* 1 */
    z-index: 1; /* 1 */

    ~ .euiCheckbox__label {
      display: inline-block;
      padding-left: ($euiCheckBoxSize * 1.5);
      line-height: $euiSizeL;
      font-size: $euiFontSizeS;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }

    + .euiCheckbox__square {
      @include euiCustomControl($type: 'square', $size: $euiCheckBoxSize);
      display: inline-block;
      position: absolute;
      left: 0;
      top: $topOffset;
    }

    &:checked {
      + .euiCheckbox__square {
        @include euiCustomControlSelected($type: 'check');
      }
    }

    &:indeterminate {
      + .euiCheckbox__square {
        @include euiCustomControlSelected($type: 'square');
      }
    }

    &[disabled] {
      // sass-lint:disable-block no-important
      cursor: not-allowed !important;

      ~ .euiCheckbox__label {
        color: $euiFormControlDisabledColor;
        cursor: not-allowed !important;
      }

      + .euiCheckbox__square {
        @include euiCustomControlDisabled;
      }
    }

    &:checked[disabled] {
      + .euiCheckbox__square {
        @include euiCustomControlDisabled($type: 'check');
      }
    }

    &:indeterminate[disabled] {
      + .euiCheckbox__square {
        @include euiCustomControlDisabled($type: 'square');
      }
    }

    &:focus {
      + .euiCheckbox__square {
        @include euiCustomControlFocused;
      }
    }
  }

  &.euiCheckbox--inList,
  &.euiCheckbox--noLabel {
    min-height: $euiCheckBoxSize;
    min-width: $euiCheckBoxSize;

    .euiCheckbox__input,
    .euiCheckbox__square {
      top: 0;
    }

    .euiCheckbox__input {
      margin: 0;
    }
  }
}
